import { useRef, useEffect, useState } from 'react'

export default function useIsSticky() {
  const [isSticky, setIsSticky] = useState(false)
  const sentinelRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!sentinelRef.current) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (typeof window === 'undefined') {
          return
        }

        // Check if the top of the sentinel is at the top of the viewport
        const atTop = entry.boundingClientRect.top <= 0

        // Set isSticky based on whether the sentinel is intersecting and its position
        if (!entry.isIntersecting && atTop) {
          setIsSticky(true)
        } else {
          setIsSticky(false)
        }
      },
      {
        threshold: [0]
      }
    )

    observer.observe(sentinelRef.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return { isSticky, sentinelRef }
}
