import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { CgDetailsMore } from 'react-icons/cg'
import { HiArrowUpRight, HiEllipsisVertical } from 'react-icons/hi2'
import { SiIpfs } from 'react-icons/si'
import ExplorerIcon from 'components/ExplorerIcon'
import { getIPFSUrl, getIsIPFS } from 'helpers/ipfs'
import { useExplorerStore } from 'store/index'
import { IconPeraExplorer } from '../Assets.icons'
import type { Asset } from 'types/assets'

interface AssetMenuProps {
  asset: Asset
  isNft: boolean
  isNfd: boolean
  handleClickViewDetails: (open: boolean) => void
  handleClickSendAsset: () => void
  handleClickSetField: (field: 'avatar' | 'banner') => void
  handleClickMoveToVault: () => void
  handleClickMoveToDepositAccount: () => void
  handleClickMoveToOwnerAccount: () => void
  canSetField: boolean
  canSendAsset: boolean
  canMoveToVault: boolean
  canMoveToDepositAccount: boolean
  canMoveToOwnerAccount: boolean
}

export default function AssetMenu({
  asset,
  isNft,
  isNfd,
  handleClickViewDetails,
  handleClickSendAsset,
  handleClickSetField,
  handleClickMoveToVault,
  handleClickMoveToDepositAccount,
  handleClickMoveToOwnerAccount,
  canSetField,
  canSendAsset,
  canMoveToVault,
  canMoveToDepositAccount,
  canMoveToOwnerAccount
}: AssetMenuProps) {
  const selectedExplorer = useExplorerStore((state) => state.selectedExplorer)
  const lookupByAssetId = useExplorerStore((state) => state.lookupByAssetId)

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div className="flex items-center">
          <Menu.Button className="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-brand-500 dark:bg-transparent dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-offset-gray-900">
            <span className="sr-only">Open menu</span>
            <HiEllipsisVertical className="h-6 w-6" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-20 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:ring-gray-800/40 dark:divide-gray-750">
            <div className="py-1">
              <Menu.Item>
                <button
                  type="button"
                  className="group inline-flex items-center w-full px-4 py-2 text-left text-sm font-normal text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-900/75 dark:hover:text-gray-200"
                  onClick={() => handleClickViewDetails(true)}
                >
                  <CgDetailsMore
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                    aria-hidden="true"
                  />
                  Details
                </button>
              </Menu.Item>
            </div>
            {asset.id !== 0 && (
              <div className="py-1">
                <Menu.Item>
                  <a
                    href={lookupByAssetId(asset.id)}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="group inline-flex items-center w-full px-4 py-2 text-left text-sm font-normal text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-900/75 dark:hover:text-gray-200"
                  >
                    <ExplorerIcon
                      className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-300"
                      explorer={selectedExplorer}
                    />{' '}
                    {selectedExplorer}
                  </a>
                </Menu.Item>

                {isNft && (
                  <>
                    <Menu.Item>
                      <a
                        href={`https://explorer.perawallet.app/asset/${asset.id}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="group inline-flex items-center w-full px-4 py-2 text-left text-sm font-normal text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-900/75 dark:hover:text-gray-200"
                      >
                        <IconPeraExplorer className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-300" />{' '}
                        Pera Explorer
                      </a>
                    </Menu.Item>
                    {getIsIPFS(asset.url) && (
                      <Menu.Item>
                        <a
                          href={getIPFSUrl(asset.url)}
                          target="_blank"
                          rel="noreferrer noopener"
                          className="group inline-flex items-center w-full px-4 py-2 text-left text-sm font-normal text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-900/75 dark:hover:text-gray-200"
                        >
                          <SiIpfs
                            className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-300"
                            aria-hidden="true"
                          />{' '}
                          IPFS
                        </a>
                      </Menu.Item>
                    )}
                  </>
                )}
              </div>
            )}

            {canSetField && (
              <div className="py-1">
                <Menu.Item>
                  <button
                    onClick={() => handleClickSetField('avatar')}
                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                  >
                    Set as Avatar
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => handleClickSetField('banner')}
                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                  >
                    Set as Banner
                  </button>
                </Menu.Item>
              </div>
            )}

            {canMoveToVault && (
              <div className="py-1">
                <Menu.Item>
                  <button
                    onClick={handleClickMoveToVault}
                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                  >
                    Move to Vault
                  </button>
                </Menu.Item>
              </div>
            )}

            {canMoveToDepositAccount && (
              <div className="py-1">
                <Menu.Item>
                  <button
                    onClick={handleClickMoveToDepositAccount}
                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                  >
                    Move to Deposit Account
                  </button>
                </Menu.Item>
              </div>
            )}

            {canMoveToOwnerAccount && (
              <div className="py-1">
                <Menu.Item>
                  <button
                    onClick={handleClickMoveToOwnerAccount}
                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                  >
                    Move to Owner Account
                  </button>
                </Menu.Item>
              </div>
            )}

            {canSendAsset && !isNfd && (
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="group inline-flex items-center w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-900/75 dark:hover:text-gray-100"
                    onClick={handleClickSendAsset}
                  >
                    <HiArrowUpRight
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                      aria-hidden="true"
                    />
                    Send Asset
                  </button>
                </Menu.Item>
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
