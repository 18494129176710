import { Disclosure } from '@headlessui/react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import dynamic from 'next/dynamic'
import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { AiFillBank } from 'react-icons/ai'
import { HiChevronUp } from 'react-icons/hi'
import Button from 'components/Button'
import Callout from 'components/Callout'
import AssetDetails from 'components/DetailView/Assets/AssetPreview/AssetDetails'
import TokenImage from 'components/DetailView/Assets/AssetPreview/TokenImage'
import DetailsSkeleton from 'components/DetailView/DetailsSkeleton'
import PurchaseLayout from 'components/Layout/Purchase'
import PriceTag from 'components/PriceTag'
import Alert from 'components/Alert'
import AssetMedia from 'components/AssetMedia'
import UserThumbnail from 'components/UserThumbnail'
import SalesHistory from 'components/SalesHistory'
import OffersHistory from 'components/OffersHistory'
import UsdPrice from 'components/UsdPrice'
import AlgoPrice from 'components/AlgoPrice'
import LoadingDots from 'components/LoadingDots'
import Tooltip from 'components/Tooltip'
import { ResultTag } from 'components/ResultTag'
import useVaultAssets from 'api/hooks/useVaultAssets'
import { getAvatarURL } from 'helpers/avatar'
import { formatAmount } from 'helpers/format'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { clsxMerge, isAssetNft } from 'helpers/utilities'
import { getNfdVersion, meetsMinimumVersion } from 'helpers/versions'
import type { NfdRecord } from 'api/api-client'
import type { Asset } from 'types/assets'

const SimpleModal = dynamic(() => import('components/SimpleModal'))

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

interface ClaimNowProps {
  nfd: NfdRecord
  onClaim: () => void
  isDisabled: boolean
  isPolling: boolean
  activeAddress: string
}

export default function ClaimNow({
  nfd,
  onClaim,
  isDisabled,
  isPolling,
  activeAddress
}: ClaimNowProps) {
  const isReservedForUser = nfd.reservedFor === activeAddress
  const vaultAddress = nfd.nfdAccount

  const { showVaultInfo, assets, isLoading, error } = useVaultAssets(nfd)

  const [isDetailsOpen, setIsDetailsOpen] = useState<Record<number, boolean> | null>(null)

  const isExpired = dayjs(nfd.timeExpires).isBefore(dayjs())
  const expiresSoon = dayjs(nfd.timeExpires).isBefore(dayjs().add(30, 'days'))

  const handleSetIsDetailsOpen = useCallback(
    (assetId: number, isOpen: boolean) => {
      setIsDetailsOpen(
        assets
          ? assets.reduce((acc, asset) => {
              if (asset.id === assetId) {
                acc[asset.id] = isOpen
              } else {
                acc[asset.id] = false
              }
              return acc
            }, {} as Record<number, boolean>)
          : null
      )
    },
    [assets]
  )

  useEffect(() => {
    if (assets) {
      setIsDetailsOpen(
        assets.reduce((acc, asset) => {
          acc[asset.id] = false
          return acc
        }, {} as Record<number, boolean>)
      )
    }
  }, [assets])

  const getVaultAmount = (asset: Asset) => {
    const vaultAmount = asset.amounts.find((amount) => amount.account === vaultAddress)?.amount

    if (!vaultAmount) return null

    return formatAmount(vaultAmount, { decimals: asset.decimals, maxLength: 10 })
  }

  const renderPreview = (asset: Asset) => {
    if (!isAssetNft(asset.totalCreated, asset.decimals) && !asset.imageUrl) {
      return <TokenImage />
    }

    return (
      <AssetMedia
        src={asset.imageUrl}
        alt={asset.id.toString()}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="32px"
        fill
        options={{ showVideoIcon: false, stateBgColor: 'bg-gray-500/5 dark:bg-gray-500/5' }}
        videoJsOptions={{
          preload: isMobile ? 'auto' : 'metadata',
          controls: isMobile,
          fluid: true,
          fill: true
        }}
      />
    )
  }

  const renderAssetDetails = (asset: Asset) => {
    const isNft = isAssetNft(asset.totalCreated, asset.decimals)
    const isNfd = asset.unitName?.toLowerCase() === 'nfd'
    const totalAmount = asset.amounts.find((amount) => amount.account === vaultAddress)?.amount || 0

    return (
      <AssetDetails
        open={isDetailsOpen?.[asset.id] || false}
        setOpen={(open) => handleSetIsDetailsOpen(asset.id, open)}
        nfd={nfd}
        asset={asset}
        isNft={isNft}
        isNfd={isNfd}
        isAccountsOpen={false}
        totalAmount={totalAmount}
        handleClickSetField={() => null}
        handleClickSendAsset={() => null}
        handleClickMoveToVault={() => null}
        handleClickMoveToDepositAccount={() => null}
        handleClickMoveToOwnerAccount={() => null}
        handleClickFilterAccount={() => null}
        canSetField={false}
        canSendAsset={false}
        canMoveToVault={false}
        canMoveToDepositAccount={false}
        canMoveToOwnerAccount={false}
      />
    )
  }

  const renderVaultAssets = () => {
    const showVaultAssets = showVaultInfo && !!assets && assets.length > 0

    if (!showVaultAssets) return null

    if (isLoading) {
      return (
        <div className="flex items-center justify-center h-[72px]">
          <LoadingDots />
        </div>
      )
    }

    if (error) {
      return (
        <div>
          <Alert type="error" title={`Error fetching vault assets`} error={error} />
        </div>
      )
    }

    return (
      <Callout theme="blue">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg px-1 py-2 text-left font-medium">
                <span className="inline-flex items-center">
                  <AiFillBank className="h-5 w-5 mr-2" aria-hidden="true" /> Vault Assets (
                  {assets.length})
                </span>
                <HiChevronUp
                  className={clsxMerge(open ? 'transform rotate-180' : '', 'h-6 w-6')}
                  aria-hidden="true"
                />
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="mt-6 space-y-2.5 max-h-48 overflow-y-auto pr-6">
                  {assets.map((asset) => (
                    <div key={asset.id}>
                      <button
                        type="button"
                        className="group flex items-center w-full"
                        onClick={() => handleSetIsDetailsOpen(asset.id, true)}
                      >
                        <div className="relative flex-shrink-0 w-8 h-8 rounded-full overflow-hidden bg-gray-500/10">
                          {renderPreview(asset)}
                        </div>
                        <div className="ml-3 flex-1 flex items-center justify-between min-w-0 text-sm font-medium text-gray-900 dark:text-gray-200">
                          <p className="truncate font-medium group-hover:text-brand-500">
                            {asset.name}
                          </p>
                          <span className="ml-4 font-mono">{getVaultAmount(asset)}</span>
                        </div>
                      </button>
                      {renderAssetDetails(asset)}
                    </div>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Callout>
    )
  }

  if (isPolling) {
    return <DetailsSkeleton />
  }

  const renderClaimButton = () => {
    if (!isReservedForUser) {
      return (
        <Alert
          type="warning"
          title="Reserved"
          message="This NFD is reserved for a different wallet."
        />
      )
    }

    return (
      <Button
        className="max-w-xs flex-1 py-3 px-8 sm:w-full"
        onClick={onClaim}
        size="lg"
        variant="gradient"
        disabled={!activeAddress || isDisabled}
      >
        Claim now
      </Button>
    )
  }

  const renderPrice = () => {
    const { mintingKickoffAmount = '0', mintingKickoffCreator } = nfd.properties?.internal || {}

    const remainingPrice =
      activeAddress === mintingKickoffCreator
        ? Number(nfd.sellAmount) - Number(mintingKickoffAmount)
        : Number(nfd.sellAmount)

    const isRefund = remainingPrice < 0

    const RefundInfo = () => (
      <div className="space-y-6 mt-6">
        <p>
          Your winning bid of{' '}
          <AlgoPrice
            price={Number(mintingKickoffAmount)}
            className="font-semibold text-gray-900 dark:text-gray-300"
          />{' '}
          exceeded the final sale price of{' '}
          <AlgoPrice
            price={Number(nfd.sellAmount)}
            className="font-semibold text-gray-900 dark:text-gray-300"
          />
          , so we are refunding the difference of{' '}
          <AlgoPrice
            price={Math.abs(remainingPrice)}
            className="font-semibold text-gray-900 dark:text-gray-300"
          />
          .
        </p>
      </div>
    )

    return (
      <>
        {isRefund ? (
          <h3 className="text-sm text-gray-500 mb-1 dark:text-gray-400">
            Refund amount
            <SimpleModal
              triggerText="Why?"
              triggerClassName="ml-2 underline dark:no-underline dark:text-brand-500 dark:hover:text-brand-600"
              title="Why am I getting a refund?"
              content={<RefundInfo />}
              className="max-w-2xl"
            />
          </h3>
        ) : (
          <h2 className="sr-only">Price</h2>
        )}
        <div className="flex items-center">
          <PriceTag
            price={Math.abs(remainingPrice)}
            className={clsxMerge(
              isRefund
                ? 'bg-green-100 text-green-800 dark:bg-green-100/10 dark:text-green-300'
                : '',
              'pl-3 pr-3 text-xl'
            )}
          />
          <UsdPrice price={Math.abs(remainingPrice)} className="text-right ml-2" />
        </div>
      </>
    )
  }

  const renderDetails = () => {
    return (
      <>
        <div className="mt-2 sm:mt-3 space-y-6">
          <div>
            <h2 className="sr-only">Price</h2>
            {renderPrice()}
          </div>

          {nfd.seller && (
            <div>
              <h3 className="text-sm text-gray-500 mb-1">Seller</h3>
              <div className="flex">
                <UserThumbnail address={nfd.seller} className="inline-flex min-w-0 pr-2" />
              </div>
            </div>
          )}

          {nfd.reservedFor && (
            <div>
              <h3 className="text-sm text-gray-500 mb-1">Reserved for</h3>
              <div className="flex">
                <UserThumbnail
                  address={nfd.reservedFor}
                  className="inline-flex min-w-0 pr-2"
                  fallbackClassName="font-medium bg-orange-50 text-orange-700 px-2 py-0.5 rounded dark:bg-gray-750"
                />
              </div>
            </div>
          )}

          {renderVaultAssets()}
        </div>

        <div>
          <div className="mt-8 flex sm:flex-col1">{renderClaimButton()}</div>
        </div>

        <section aria-labelledby="details-heading" className="mt-12">
          <h2 id="details-heading" className="sr-only">
            Additional details
          </h2>

          <div className="border-t space-y-6 pt-6 dark:border-gray-750/75">
            <SalesHistory name={nfd.name} />
            <OffersHistory name={nfd.name} />
          </div>
        </section>
      </>
    )
  }

  return (
    <PurchaseLayout nfd={nfd}>
      <div className="md:grid md:grid-cols-[200px_1fr] md:gap-x-8 xl:grid-cols-[240px_1fr]">
        <div className="hidden md:block">
          <div className="relative md:w-full aspect-square rounded-lg bg-gray-200 overflow-hidden dark:bg-gray-800">
            <AssetMedia
              src={getAvatarURL(nfd)}
              alt={nfd.name}
              className="object-cover w-full h-full"
              priority
              sizes="240px"
              fill
            />
          </div>
        </div>

        <div>
          <h1 className="text-2xl sm:text-3xl font-extrabold tracking-tight text-gray-900 truncate dark:text-gray-100">
            {nfd.name}
          </h1>
          <div className="mt-2 flex items-center gap-x-2">
            {meetsMinimumVersion(nfd, '3') ? (
              <Tooltip
                as="span"
                text={dayjs(nfd.timeExpires).format('lll')}
                className="translate-y-3"
              >
                <p className="text-sm text-gray-700 dark:text-gray-400">
                  <ResultTag
                    label={isExpired ? 'Expired' : `Expires ${dayjs(nfd.timeExpires).fromNow()}`}
                    color={expiresSoon ? 'red' : 'gray'}
                    className={clsxMerge(expiresSoon ? 'font-bold' : '')}
                  />
                </p>
              </Tooltip>
            ) : (
              <ResultTag label={`v${getNfdVersion(nfd)}`} color="gray" className="mt-1.5" />
            )}
          </div>
          {renderDetails()}
        </div>
      </div>
    </PurchaseLayout>
  )
}
