import { nfdPurchase, type PurchaseRequestBody } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'

type PurchaseParams = {
  name: string
  body: PurchaseRequestBody
}

export function usePostPurchase(options: MutationOptions<PurchaseParams> = {}) {
  return usePostTransaction<PurchaseParams>({
    mutationFn: ({ name, body }) => nfdPurchase(name, body),
    ...options
  })
}
