import { useState } from 'react'
import { AssetTypeFilter } from '../Assets.types'
import { AccountOption } from './AccountFilter'

interface UseAccounts {
  accounts: Array<string> | null
  setAccounts: (accounts: Array<string> | null) => void
  setAccountsAsync: (accounts: Array<string> | null) => Promise<void>
  allAccounts: Array<string>
}

export function useAccounts({ accounts, setAccounts, allAccounts }: UseAccounts) {
  const accountOptions = allAccounts.map((account, i) => ({ id: i + 1, account }))

  const selectedAccounts = accountOptions.filter((option) => accounts?.includes(option.account))

  const setSelectedAccounts = (selected: Array<AccountOption>) => {
    // sort alphabetically to prevent multiple query keys
    const newAccounts = selected.map((option) => option.account).sort()

    setAccounts(newAccounts)
  }

  return {
    accountOptions,
    selectedAccounts,
    setSelectedAccounts
  }
}

export function useMobileAccounts({ accounts, setAccountsAsync, allAccounts }: UseAccounts) {
  const [selected, setSelected] = useState<Record<string, boolean>>(() => {
    const accountObject: Record<string, boolean> = {}

    const accountsSet = new Set([...(accounts || [])])

    allAccounts.forEach((account) => {
      accountObject[account] = accountsSet.has(account)
    })

    return accountObject
  })

  const [isUpdating, setIsUpdating] = useState(false)

  const handleAccountsChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isUpdating) return

    const { value: thisAccount, checked } = event.target

    // Update URL params first
    let newFilter: Array<string> | null = accounts || []

    if (checked) {
      // Filter is being added
      if (!newFilter.includes(thisAccount)) {
        newFilter.push(thisAccount)
      }
    } else {
      // Filter is being removed
      newFilter = newFilter.filter((item) => item !== thisAccount)
    }

    newFilter.sort()

    try {
      setIsUpdating(true)

      if (newFilter.length === 0) {
        await setAccountsAsync(null)
      } else {
        await setAccountsAsync(newFilter)
      }

      setIsUpdating(false)
    } catch (error) {
      // There's an error updating the URL params, so we don't update the UI
      console.error(error)
      setIsUpdating(false)
      return
    }

    // Updating the UI
    setSelected((prevAccounts) => ({
      ...prevAccounts,
      [thisAccount]: checked
    }))
  }

  return {
    selected,
    handleAccountsChange
  }
}

interface UseAssetTypes {
  typeFilter: Array<AssetTypeFilter> | null
  setTypeFilter: (typeFilter: Array<AssetTypeFilter> | null) => Promise<void>
}

export function useAssetTypes({ typeFilter, setTypeFilter }: UseAssetTypes) {
  /**
   * The checkboxes are controlled by `filters` state, separate from `typeFilter` (URL params),
   * so we can show all options as checked when `typeFilter` is null. While `typeFilter` is
   * an array of strings (enum values), `filters` is an object/map with boolean values for each filter,
   * which is better-suited for controlling multiple checkboxes in the UI.
   */
  const [filters, setFilters] = useState(() => ({
    nfts: typeFilter?.includes(AssetTypeFilter.NFTs) || false,
    tokens: typeFilter?.includes(AssetTypeFilter.Tokens) || false,
    nfds: typeFilter?.includes(AssetTypeFilter.NFDs) || false
  }))

  const [isUpdating, setIsUpdating] = useState(false)

  /**
   * We need to keep both states in sync. The `setTypeFilter` function that comes from the
   * `useQueryParams` hook can be called asynchronously, so the URL params are updated first and
   * the checkboxes are only updated if that succeeds.
   *
   * Checking the `isUpdating` flag first prevents multiple updates from fast/repetitive clicks.
   */
  const handleFilterChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isUpdating) return

    const { value, checked } = event.target
    const thisFilter = value as AssetTypeFilter

    // Update URL params first
    let newFilter: Array<AssetTypeFilter> | null = typeFilter || []

    if (checked) {
      // Filter is being added
      if (!newFilter.includes(thisFilter)) {
        newFilter.push(thisFilter)
      }
    } else {
      // Filter is being removed
      newFilter = newFilter.filter((item) => item !== thisFilter)
    }

    // Sorting the array prevents React Query from treating `filters: ['nfts', 'tokens']` and
    // `filters: ['tokens', 'nfts']` as two different query keys and caching them separately.
    newFilter.sort()

    try {
      setIsUpdating(true)

      if (newFilter.length === 0) {
        await setTypeFilter(null)
      } else {
        await setTypeFilter(newFilter)
      }

      setIsUpdating(false)
    } catch (error) {
      // There's an error updating the URL params, so we don't update the UI
      console.error(error)
      setIsUpdating(false)
      return
    }

    // Updating the UI
    setFilters((prevFilters) => ({
      ...prevFilters,
      [thisFilter]: checked
    }))
  }

  return {
    filters,
    handleFilterChange
  }
}
