import type { NfdRecord } from 'api/api-client'

export enum Sections {
  About = 'details',
  Assets = 'assets',
  Segments = 'segments',
  Sales = 'sales',
  Offers = 'offers',
  History = 'history'
}

export type DetailProps = {
  nfd: NfdRecord
  isOwner: boolean
  showTip: boolean
}

export type PanelProps = Omit<DetailProps, 'showTip'>

export type MoreTabViews = Sections.Sales | Sections.Offers | Sections.History
