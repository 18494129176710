import { useInfiniteQuery, type UseInfiniteQueryOptions } from '@tanstack/react-query'
import axios, { type AxiosError } from 'axios'
import queryString from 'query-string'
import { useOptimisticUpdateStore } from 'store/index'
import { selectOptimisticUpdates } from 'components/DetailView/Assets/Assets.utils'
import type { NfdRecord } from 'api/api-client'
import type { AssetsParams } from 'api/types/assets'
import type { Asset } from 'types/assets'

export type AssetsQueryKey = [string, NfdRecord['name'], AssetsParams]

export type InfiniteAssetsQueryResult = {
  assets: Array<Asset>
  nextPage: number | null
}

type UseInfiniteAssetsParams = AssetsParams & {
  limit?: number
}

interface UseInfiniteAssets {
  queryKey: AssetsQueryKey
  name: string
  params: UseInfiniteAssetsParams
  options?: UseInfiniteQueryOptions<InfiniteAssetsQueryResult, AxiosError>
}

export default function useInfiniteAssets({
  queryKey,
  name,
  params,
  options = {}
}: UseInfiniteAssets) {
  const fetchAssets = async ({ pageParam = 0 }) => {
    const { limit = 20, ...rest } = params

    const { data: assets } = await axios.get<Asset[]>(`/api/assets/${name}`, {
      params: {
        limit,
        offset: pageParam * limit,
        ...rest
      },
      paramsSerializer: (params) => queryString.stringify(params)
    })

    const nextPage = assets.length === limit ? pageParam + 1 : null

    return {
      assets,
      nextPage
    }
  }

  const getOptimisticUpdates = useOptimisticUpdateStore((state) => state.getOptimisticUpdates)

  return useInfiniteQuery<InfiniteAssetsQueryResult, AxiosError>({
    queryKey,
    queryFn: fetchAssets,
    getNextPageParam: (lastPage) => lastPage.nextPage,
    select: (data) => {
      const updates = getOptimisticUpdates({ queryKey })
      return selectOptimisticUpdates(data, updates)
    },
    refetchOnWindowFocus: false,
    ...options
  })
}
