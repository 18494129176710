import { useQuery } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import * as React from 'react'
import { getByName } from '@/api/hooks/useName'
import type { NfdRecord } from '@/api/api-client'
import type { ICustomError } from '@/api/customError'

export enum PollingStatus {
  IDLE = 'idle',
  POLLING = 'polling',
  SUCCESS = 'success',
  ERROR = 'error'
}

interface UseNfdPollingResult {
  startPolling: () => void
  status: PollingStatus
  error: Error | null
}

export function useNfdPolling(
  name: string,
  condition: (nfd: NfdRecord, activeAddress?: string | null) => boolean,
  eventName: string,
  eventId?: string
): UseNfdPollingResult {
  const [status, setStatus] = React.useState<PollingStatus>(PollingStatus.IDLE)

  const { activeAddress } = useWallet()

  const { data: nfd, error } = useQuery<NfdRecord | void, ICustomError>({
    queryKey: ['name', name, { view: 'full', poll: true, nocache: true }],
    queryFn: () => getByName(name, { view: 'full', poll: true, nocache: true }),
    enabled: status === PollingStatus.POLLING,
    refetchInterval: 2000,
    retry: 3
  })

  React.useEffect(() => {
    if (status === PollingStatus.POLLING) {
      if (nfd && condition(nfd, activeAddress)) {
        setStatus(PollingStatus.SUCCESS)
        // Emit custom event
        window.dispatchEvent(
          new CustomEvent(eventName, { detail: { name: nfd.name, id: eventId } })
        )
      } else if (error) {
        setStatus(PollingStatus.ERROR)
      }
    }
  }, [activeAddress, condition, error, eventId, eventName, nfd, status])

  const startPolling = () => {
    setStatus(PollingStatus.POLLING)
  }

  return { startPolling, status, error }
}
