import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import queryString from 'query-string'
import type { NftListing } from 'types/listings'
import type { AssetsQueryKey } from 'api/hooks/useInfiniteAssets'

export default function useForSale(queryKey: AssetsQueryKey) {
  const [, name, params] = queryKey

  const fetchListings = async (): Promise<Array<NftListing>> => {
    const { data: listings } = await axios.get(`/api/listings/${name}`, {
      params,
      paramsSerializer: (params) => queryString.stringify(params)
    })
    return listings
  }

  return useQuery<Array<NftListing>, Error>(queryKey, fetchListings)
}
