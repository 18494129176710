import { useQueryClient } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import { useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { usePostSale } from 'api/hooks/usePostSale'
import { usePostCancelSale } from 'api/hooks/usePostCancelSale'
import confirm from 'components/confirm'
import SuccessToast from 'components/sell/SuccessToast'
import TxnSuccess from 'components/toasts/TxnSuccess'
import ConfirmDialog from 'components/transfer/ConfirmDialog'
import { checkBalance } from 'helpers/checkBalance'
import { convertAlgosToMicroalgos, convertMicroalgosToAlgos, formatPrice } from 'helpers/utilities'
import useErrorToast from 'hooks/useErrorToast'
import type { NfdRecord } from 'types/api'

export default function useForm(nfd: NfdRecord, reservedFor?: string, sellAmount?: string) {
  const [isGift, setIsGift] = useState(false)
  const [reservedAddress, setReservedAddress] = useState<string>(
    reservedFor || nfd.reservedFor || ''
  )

  const price = useMemo(
    () =>
      sellAmount
        ? convertMicroalgosToAlgos(Number(sellAmount)).toString()
        : nfd.sellAmount
        ? convertMicroalgosToAlgos(nfd.sellAmount).toString()
        : '',
    [sellAmount, nfd.sellAmount]
  )

  const { activeAddress } = useWallet()
  const router = useRouter()
  const handleError = useErrorToast()
  const queryClient = useQueryClient()

  const { mutateAsync: postTransfer, isLoading } = usePostSale({
    onSuccess: (data, params) => {
      queryClient.invalidateQueries({ queryKey: ['name', params.name] }).then(() => {
        router.push(`/name/${nfd.name}`)
      })
    },
    toasts: {
      success: SuccessToast
    }
  })

  const { mutateAsync: cancelTransfer, isLoading: isCancelTransferLoading } = usePostCancelSale({
    onSuccess: (data, params) => {
      queryClient.invalidateQueries({ queryKey: ['name', params.name] }).then(() => {
        router.push(`/name/${nfd.name}`)
      })
    },
    toasts: {
      success: TxnSuccess
    }
  })

  const handleSubmitPostTransfer = async () => {
    try {
      if (!activeAddress) {
        throw new Error('No active address')
      }

      const salePrice = convertAlgosToMicroalgos(parseFloat(price))

      if (!reservedAddress) {
        throw new Error('Reserved address is required')
      }

      await postTransfer({
        name: nfd.name,
        body: {
          offer: salePrice,
          sender: activeAddress,
          reservedFor: reservedAddress,
          payReceiver: isGift
        }
      })
    } catch (e) {
      handleError(e)
    }
  }

  const handleClickCancelTransfer = async () => {
    try {
      if (!activeAddress) {
        throw new Error('No active address')
      }

      await cancelTransfer({
        name: nfd.name,
        body: {
          sender: activeAddress
        }
      })
    } catch (e) {
      handleError(e)
    }
  }

  const handleGoBack = (path: string | undefined) => {
    router.push(path || `/name/${nfd.name}`)
  }

  const handleChangeReserveAddress = (value: string) => {
    setReservedAddress(value)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      if (!activeAddress) {
        throw new Error('No active address')
      }

      const balance = await checkBalance(activeAddress, 1108000, false)

      if (!balance.hasSufficientBalance) {
        throw new Error(
          `Insufficient balance in ${activeAddress}. Must have a minimum available balance of ${formatPrice(
            balance.balanceRequired as number
          )} ALGO to continue.`
        )
      }

      const confirmed = await confirm({
        title: `Metadata warning`,
        confirmText: `Yes, transfer NFD`,
        confirmation: ConfirmDialog()
      })

      confirmed && (await handleSubmitPostTransfer())
    } catch (error) {
      handleError(error)
    }
  }

  const handleToggleGift = () => {
    setIsGift(!isGift)
  }

  const isForSale = nfd.state === 'forSale' && nfd.owner === activeAddress

  const isValid = useMemo(() => {
    return (
      parseFloat(price) === 1 &&
      reservedAddress &&
      reservedAddress !== nfd.owner &&
      (reservedAddress?.match(/^[A-Z2-7]{58}$/)?.length || reservedAddress === '')
    )
  }, [price, reservedAddress, nfd.owner])

  const isDirty = useMemo(() => {
    const originalPrice = nfd.sellAmount ? convertMicroalgosToAlgos(nfd.sellAmount).toString() : ''
    const originalAddress = nfd.reservedFor || ''

    return price !== originalPrice || reservedAddress !== originalAddress
  }, [nfd.reservedFor, nfd.sellAmount, price, reservedAddress])

  return {
    isDirty,
    isValid,
    price,
    reservedAddress,
    handleChangeReserveAddress,
    handleClickCancelTransfer,
    handleGoBack,
    handleToggleGift,
    isGift,
    isForSale,
    isLoading,
    isCancelTransferLoading,
    handleSubmit
  }
}
