import { type ReactElement, useEffect, useMemo, useState } from 'react'
import { QueryClient, dehydrate, useQueryClient } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
// import dayjs from 'dayjs'
// import toast from 'react-hot-toast'
import DetailView from 'components/DetailView'
import DetailsSkeleton from 'components/DetailView/DetailsSkeleton'
import NotFound from 'components/DetailView/NotFound'
import Layout from 'components/Layout'
import Meta from 'components/Meta'
// import Alert from 'components/Alert'
import Confetti from 'components/name/Confetti'
import TransferForm from 'components/transfer/TransferForm'
import SellForm from 'components/sell/SellForm'
import ClaimNow from 'components/claim/ClaimNow'
import BuyNow from 'components/buy/BuyNow'
import SuccessToast from 'components/sell/SuccessToast'
// import BidSuccess from 'components/toasts/BidSuccess'
// import ErrorToast from 'components/toasts/ErrorToast'
import { checkBalance } from 'helpers/checkBalance'
import { formatPrice } from 'helpers/utilities'
import usePrevious from 'hooks/usePrevious'
import useErrorToast from 'hooks/useErrorToast'
import useTermsOfService from 'hooks/useTermsOfService'
import useName, { getByName } from 'api/hooks/useName'
import { usePostPurchase } from 'api/hooks/usePostPurchase'
// import useGetAuction from 'api/hooks/useGetAuction'
// import { usePostEscrowOffer } from 'api/hooks/usePostEscrowOffer'
import { useNocacheStore } from 'store/index'
// import { CustomError } from 'api/customError'
import type { GetServerSideProps } from 'next'
// import type { AxiosError } from 'axios'

type ProfilePageProps = {
  name: string
  purchased: 'true' | 'false'
}

const ProfilePage = ({ name, purchased }: ProfilePageProps) => {
  const [isPurchased, setIsPurchased] = useState(purchased === 'true')
  const [polling, setPolling] = useState<{ type: string; interval: number } | null>(null)

  const { activeAddress, activeAccount } = useWallet()
  const acceptTerms = useTermsOfService()
  const handleError = useErrorToast()
  const queryClient = useQueryClient()

  const isNocache = useNocacheStore((state) => state.isNocache)
  const ownedNfds = useNocacheStore((state) => state.nocache)

  // See https://linear.app/txnlab/issue/TXN-1361/fix-scroll-position-when-following-profile-page-links
  useEffect(() => window.scrollTo(0, 0), [])

  const {
    data: nfd,
    error,
    isInitialLoading
  } = useName({
    name,
    params: {
      view: 'full',
      ...(isPurchased || polling !== null || isNocache(name) ? { nocache: true } : {}),
      ...(polling?.type === 'purchase' ? { poll: true } : {})
    },
    options: {
      enabled: name.length > 0,
      refetchInterval: polling?.interval || 0
    }
  })

  // const auctionQuery = useGetAuction({
  //   name,
  //   options: { enabled: nfd?.saleType === 'auction' }
  // })

  const prevNfd = usePrevious(nfd)

  const hasBeenPurchased = useMemo(() => {
    return (
      ['forSale', 'reserved'].includes(prevNfd?.state as string) &&
      nfd?.state === 'owned' &&
      prevNfd?.owner !== activeAddress &&
      nfd?.owner === activeAddress
    )
  }, [activeAddress, nfd, prevNfd])

  useEffect(() => {
    if (hasBeenPurchased) {
      setIsPurchased(true)
      setPolling(null)
    }
  }, [hasBeenPurchased])

  // const shouldPollAuction = useMemo(() => {
  //   return (
  //     nfd?.state === 'forSale' &&
  //     nfd?.saleType === 'auction' &&
  //     !!auctionQuery.data?.auctionInfo?.endTime &&
  //     dayjs(auctionQuery.data.auctionInfo.endTime).add(5, 'seconds').isAfter(dayjs()) &&
  //     !polling
  //   )
  // }, [auctionQuery.data?.auctionInfo?.endTime, nfd, polling])

  // useEffect(() => {
  //   if (shouldPollAuction) {
  //     setPolling({ type: 'auction', interval: 2000 })
  //   }
  // }, [shouldPollAuction])

  // const postEscrowOffer = usePostEscrowOffer({
  //   onSuccess: (data, params) => {
  //     queryClient.invalidateQueries({ queryKey: ['name', params.name] })
  //     queryClient.invalidateQueries({ queryKey: ['search'] })
  //     queryClient.invalidateQueries({ queryKey: ['auctions'] })
  //     queryClient.invalidateQueries({ queryKey: ['browse'] })
  //   },
  //   toasts: {
  //     success: BidSuccess
  //   }
  // })

  // const handleEscrowOffer = async (offer: number) => {
  //   try {
  //     await acceptTerms()

  //     if (!activeAddress) {
  //       throw new Error('Wallet is not connected')
  //     }

  //     if (!nfd) {
  //       throw new Error('No NFD found')
  //     }

  //     const balance = await checkBalance(activeAddress, offer)

  //     if (!balance.hasSufficientBalance) {
  //       throw new Error(
  //         `Insufficient available balance. A minimum available balance of ${formatPrice(
  //           balance.balanceRequired as number,
  //           false,
  //           { maximumFractionDigits: 6 }
  //         )} ALGO is required to complete this transaction.`
  //       )
  //     }

  //     await postEscrowOffer.mutateAsync({
  //       name,
  //       body: {
  //         buyer: activeAddress,
  //         offer
  //       }
  //     })
  //   } catch (error: unknown) {
  //     if (
  //       (error instanceof Error || error instanceof CustomError) &&
  //       error.message.includes('rejected by ApprovalProgram')
  //     ) {
  //       const errorMessage = 'Bid was rejected. Check the current floor price and try again.'

  //       console.error('Bid rejected by ApprovalProgram', error)

  //       toast.error(
  //         (t) => {
  //           return ErrorToast({
  //             error: errorMessage,
  //             t
  //           })
  //         },
  //         { duration: 5000 }
  //       )
  //     } else {
  //       handleError(error)
  //     }
  //   }
  // }

  const postPurchase = usePostPurchase({
    onSuccess: (data, params) => {
      queryClient.invalidateQueries({ queryKey: ['search'] })
      queryClient.invalidateQueries({ queryKey: ['auctions'] })
      queryClient.invalidateQueries({ queryKey: ['browse'] })
      queryClient.invalidateQueries({ queryKey: ['name', params.name] }).then(() => {
        setPolling({ type: 'purchase', interval: 2000 })
      })
    },
    onError: (error) => {
      handleError(error)
    },
    toasts: {
      success: SuccessToast
    }
  })

  const handlePurchase = async () => {
    try {
      await acceptTerms()

      if (!activeAddress) {
        throw new Error('Wallet is not connected')
      }

      if (!nfd) {
        throw new Error('No NFD found')
      }

      const { mintingKickoffAmount, mintingKickoffCreator } = nfd.properties?.internal || {}

      const isClaim = activeAddress === mintingKickoffCreator

      const offer = isClaim
        ? Math.max(Number(nfd.sellAmount) - Number(mintingKickoffAmount), 0)
        : Number(nfd.sellAmount)

      const balance = await checkBalance(activeAddress, offer)

      if (!balance.hasSufficientBalance) {
        throw new Error(
          `Insufficient available balance. A minimum available balance of ${formatPrice(
            balance.balanceRequired as number,
            false,
            { maximumFractionDigits: 6 }
          )} ALGO is required to complete this transaction.`
        )
      }

      postPurchase.mutate({
        name,
        body: {
          buyer: activeAddress,
          offer
        }
      })
    } catch (error) {
      handleError(error)
    }
  }

  const renderContent = () => {
    // if (isInitialLoading || auctionQuery.isInitialLoading) {
    //   return <DetailsSkeleton />
    // }
    if (isInitialLoading) {
      return <DetailsSkeleton />
    }

    if (error?.statusCode === 404) {
      return <NotFound name={name} activeAddress={activeAccount?.address} />
    }

    // if (error || auctionQuery.error) {
    //   const showError = error || (auctionQuery.error as AxiosError)

    //   return (
    //     <div className="px-6 py-5">
    //       <Alert type="error" title={`Error fetching data for "${name}"`} error={showError} />
    //     </div>
    //   )
    // }
    if (isInitialLoading) {
      return <DetailsSkeleton />
    }

    if (!nfd) {
      return null
    }

    const isOwnedByUser = nfd.owner === activeAccount?.address
    const isReserved = !!nfd.reservedFor
    const isTransfer = nfd.sellAmount === 1000000

    if (nfd.state === 'forSale') {
      if (isOwnedByUser && isReserved && isTransfer) {
        return <TransferForm nfd={nfd} activeAddress={activeAccount?.address} />
      }

      if (isOwnedByUser) {
        return <SellForm nfd={nfd} activeAddress={activeAccount?.address} />
      }

      if (isReserved) {
        return (
          <ClaimNow
            nfd={nfd}
            onClaim={handlePurchase}
            isDisabled={postPurchase.isLoading || !activeAddress}
            isPolling={polling?.type === 'purchase'}
            activeAddress={activeAddress as string}
          />
        )
      }

      return (
        <BuyNow
          nfd={nfd}
          // auction={auctionQuery.data}
          onBuy={handlePurchase}
          isDisabled={postPurchase.isLoading || !activeAddress}
          // onEscrowOffer={handleEscrowOffer}
          // isBidDisabled={postEscrowOffer.isLoading || !activeAddress}
          isPolling={polling?.type === 'purchase'}
        />
      )
    }

    if (nfd.state === 'reserved') {
      return (
        <ClaimNow
          nfd={nfd}
          onClaim={handlePurchase}
          isDisabled={postPurchase.isLoading || !activeAddress}
          isPolling={polling?.type === 'purchase'}
          activeAddress={activeAddress as string}
        />
      )
    }

    const isOwner = activeAddress === nfd.owner

    const showPurchasedTip = isPurchased && ownedNfds.length === 0

    return (
      <>
        <main className="flex-1 flex flex-col">
          <DetailView key={nfd.name} nfd={nfd} isOwner={isOwner} showTip={showPurchasedTip} />
        </main>

        {/* 🎉 */}
        <Confetti key={`${isPurchased}`} show={isPurchased} />
      </>
    )
  }

  const bio = nfd?.properties?.userDefined?.bio

  return (
    <>
      <Meta
        title={name}
        image={`https://nfd-og-image.vercel.app/api/profile?name=${name}&network=${process.env.NEXT_PUBLIC_VERCEL_ENV}`}
        {...(bio && { description: bio })}
      />
      {renderContent()}
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const name = context.params?.name
  const { purchased = 'false' } = context.query

  const queryClient = new QueryClient()

  await queryClient.prefetchQuery(['name', name], () =>
    getByName(name as string, { view: 'full' }, true)
  )

  return {
    props: {
      name,
      key: name,
      purchased,
      dehydratedState: dehydrate(queryClient)
    }
  }
}

ProfilePage.getLayout = (page: ReactElement) => <Layout>{page}</Layout>

export default ProfilePage
