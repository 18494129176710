type TruncateAddressOptions<T extends boolean> = {
  start?: number
  end?: number
  jsx?: T
}

export const truncateAddress = <T extends boolean = false>(
  address: string | undefined,
  options?: TruncateAddressOptions<T>
): T extends true ? React.ReactNode : string => {
  const { jsx = false as T, start = 6, end = 4 } = options ?? {}

  if (!address) {
    return (jsx ? <></> : '') as T extends true ? React.ReactNode : string
  }

  const startString = address.slice(0, start)
  const endString = address.slice(-end)

  return (
    jsx ? (
      <>
        {startString}&hellip;{endString}
      </>
    ) : (
      `${startString}...${endString}`
    )
  ) as T extends true ? React.ReactNode : string
}
