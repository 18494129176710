import Link from 'next/link'
import { useRouter } from 'next/router'
import { classNames } from 'helpers/utilities'
import { tabs } from './Assets.constants'
import { AssetSubNav } from './Assets.types'
import type { NfdRecord } from 'api/api-client'

interface SubNavigationProps {
  nfd: NfdRecord
  activeTab: AssetSubNav
}

export default function SubNavigation({ nfd, activeTab }: SubNavigationProps) {
  const router = useRouter()

  const isActive = (tab: AssetSubNav) => {
    return activeTab === tab
  }

  const renderTabs = () => {
    return tabs.map((tab) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, view, show, ...otherParams } = router.query

      let url = `/name/${nfd.name}?view=assets&show=${tab.value}`

      for (const key in otherParams) {
        url += `&${key}=${otherParams[key]}`
      }

      return (
        <li key={tab.value} className="relative overflow-hidden flex-1">
          <div className="overflow-hidden">
            <Link
              href={url}
              shallow
              aria-current={isActive(tab.value) ? 'page' : undefined}
              className="group focus:outline-none"
            >
              <span
                className={classNames(
                  isActive(tab.value)
                    ? 'h-[3px] bg-brand-500'
                    : 'h-0.5 bg-transparent group-hover:bg-gray-300 dark:group-hover:bg-gray-800',
                  'absolute bottom-0 top-auto w-full'
                )}
                aria-hidden="true"
              />
              <span className="flex items-center justify-center py-3 sm:p-4 2xl:py-3 2xl:px-6 text-sm font-medium dark:group-focus:bg-gray-500/5">
                <span className="flex-shrink-0">
                  <tab.icon
                    className={classNames(
                      isActive(tab.value)
                        ? 'text-brand-500'
                        : 'text-gray-400/50 group-hover:text-gray-400/75 group-focus:text-gray-400/75 dark:text-white/20 dark:group-hover:text-white/30 dark:group-focus:text-white/30',
                      'hidden sm:flex sm:-ml-2 h-6 w-6 2xl:my-1'
                    )}
                    aria-hidden="true"
                  />
                </span>
                <span className="sm:ml-2 flex min-w-0 flex-col">
                  <span
                    className={classNames(
                      isActive(tab.value)
                        ? 'text-gray-900 dark:text-gray-100'
                        : 'text-gray-500 group-hover:text-gray-700 group-focus:text-gray-700 dark:group-hover:text-gray-300 dark:group-focus:text-gray-300',
                      'text-sm whitespace-nowrap sm:text-base font-medium'
                    )}
                  >
                    {tab.label}
                  </span>
                </span>
              </span>
            </Link>
          </div>
        </li>
      )
    })
  }

  return (
    <div
      className="2xl:w-[34rem] 3xl:w-[36rem] 4xl:w-[42rem] 5xl:w-[48rem]"
      aria-label="Assets Category"
    >
      <ol
        role="list"
        className="flex divide-x divide-gray-200 2xl:border-r 2xl:border-gray-200 dark:divide-gray-800 dark:xl:border-gray-800"
      >
        {renderTabs()}
      </ol>
    </div>
  )
}
