import Loading from 'components/Loading'
import type { IconType } from 'react-icons'

interface IconBadgeProps {
  icon: IconType
  label: string | React.ReactNode
  isLoading?: boolean
}

export default function IconBadge({ icon, label, isLoading }: IconBadgeProps) {
  const Icon = ({ className = '' }) => (icon ? icon({ className }) : null)

  return (
    <div className="flex items-center text-sm font-medium text-gray-600/80 dark:text-gray-300">
      {isLoading ? (
        <Loading color="mr-1.5 text-gray-900 dark:text-gray-500" size="h-5 w-5" />
      ) : (
        <Icon
          className="mr-1.5 h-5 w-5 flex-shrink-0 sm:text-gray-500/80 dark:sm:text-gray-500"
          aria-hidden="true"
        />
      )}
      <span className="hidden sm:inline">{label}</span>
    </div>
  )
}
