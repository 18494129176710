import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { nfdBadges, NfdBadges200 } from 'src/api/api-client'
import { captureException } from '@sentry/nextjs'

export const getBadges = async (name: string, ssr?: boolean, options?: AxiosRequestConfig) => {
  const { data } = await nfdBadges(name, {
    ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
    ...options
  })

  return data
}

type UseBadgesArgs = {
  name: string
  options?: UseQueryOptions<NfdBadges200, AxiosError>
}

export default function useBadges({ name, options }: UseBadgesArgs) {
  const result = useQuery<NfdBadges200, AxiosError>(['badges', { name }], () => getBadges(name), {
    ...options
  })

  if (result.error) {
    captureException(result.error)
  }

  return result
}
