import { Disclosure, Transition } from '@headlessui/react'
import * as React from 'react'
import { NfdRecord } from 'api/api-client'
import { clsxMerge } from 'helpers/utilities'
import HistoryItem from './HistoryItem'
import { useHistory } from './History.hooks'
import { HistoryData } from './History.types'

interface SingleHistoryItemProps {
  historyItem: HistoryData
  isLastItem: boolean
}

const SingleHistoryItem: React.FC<SingleHistoryItemProps> = React.memo(
  ({ historyItem, isLastItem }) => (
    <HistoryItem
      historyItem={historyItem}
      isCollapsible={false}
      isOpen={false}
      isLastItem={isLastItem}
    />
  )
)

SingleHistoryItem.displayName = 'SingleHistoryItem'

interface CollapsibleHistoryItemsProps {
  historyItems: HistoryData[]
  historyItemsIdx: number
  totalHistoryLength: number
}

const CollapsibleHistoryItems: React.FC<CollapsibleHistoryItemsProps> = React.memo(
  ({ historyItems, historyItemsIdx, totalHistoryLength }) => (
    <Disclosure>
      {({ open }) => (
        <>
          {historyItems.map((historyItem, historyItemIdx) => {
            const isCollapsible = historyItems.length > 1
            const isLastItem = historyItem.isParent
              ? historyItemsIdx === totalHistoryLength - 1
              : open && historyItemIdx === historyItems.length - 1

            if (historyItem.isParent) {
              return (
                <Disclosure.Button key={`parent-${historyItemIdx}`}>
                  <HistoryItem
                    historyItem={historyItem}
                    isCollapsible={isCollapsible}
                    isOpen={open}
                    isLastItem={isLastItem}
                  />
                </Disclosure.Button>
              )
            }

            return (
              <Transition
                key={`child-${historyItemIdx}`}
                enter="transition duration-250 ease-out transform"
                enterFrom="transform opacity-0 -translate-y-full"
                enterTo="transform opacity-100 translate-y-0"
              >
                <Disclosure.Panel className={clsxMerge(isLastItem ? 'sm:pb-4' : '', 'sm:pl-8')}>
                  <HistoryItem
                    historyItem={historyItem}
                    isCollapsible={isCollapsible}
                    isOpen={open}
                    isLastItem={isLastItem}
                  />
                </Disclosure.Panel>
              </Transition>
            )
          })}
        </>
      )}
    </Disclosure>
  )
)

CollapsibleHistoryItems.displayName = 'CollapsibleHistoryItems'

interface HistoryProps {
  nfd: NfdRecord
}

function History({ nfd }: HistoryProps) {
  const historyGroups = useHistory(nfd)

  const renderHistory = () => {
    if (!historyGroups || !historyGroups.length) {
      return null
    }

    return historyGroups.map((historyItems, historyItemsIdx) => {
      if (historyItems.length === 1) {
        return (
          <div key={`history-item-${historyItemsIdx}`} className="relative">
            <SingleHistoryItem
              historyItem={historyItems[0]}
              isLastItem={historyItemsIdx === historyGroups.length - 1}
            />
          </div>
        )
      }

      if (historyItems.every((item) => item.isParentGroup)) {
        return historyItems.map((historyItem, idx) => (
          <div key={`history-item-${historyItemsIdx}-${idx}`} className="relative">
            <SingleHistoryItem
              historyItem={historyItem}
              isLastItem={idx === historyItems.length - 1}
            />
          </div>
        ))
      }

      return (
        <div key={`history-items-${historyItemsIdx}`} className="relative">
          <CollapsibleHistoryItems
            historyItems={historyItems}
            historyItemsIdx={historyItemsIdx}
            totalHistoryLength={historyGroups.length}
          />
        </div>
      )
    })
  }

  return (
    <div className="flex-1 bg-gray-100 pt-2 pb-8 5xl:pt-4 dark:bg-gray-950/40">
      <div className="max-w-5xl mx-auto p-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg dark:bg-gray-850 dark:highlight">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
              History
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-500">
              Timeline of activity for this NFD
            </p>
          </div>
          <div className="border-t border-gray-200 dark:border-gray-750/75">
            <div className="px-4 py-5 sm:px-6">{renderHistory()}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(History)
