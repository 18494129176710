import Link from 'next/link'
import { classNames } from 'helpers/utilities'
import { BADGES } from './Badges.constants'
import type { BadgeType } from 'types/api'
import BadgeIcon from './BadgeIcon'

interface BadgeProps {
  type: BadgeType
  rank?: number
}

export default function Badge({ type, rank }: BadgeProps) {
  const badge = BADGES[type]

  const renderContent = () => {
    return (
      <div className="flex items-center md:space-x-2">
        <div className={classNames('flex items-center space-x-1', rank ? 'mr-2 md:mr-1' : '')}>
          <BadgeIcon badge={badge} className="dark:dark-adjust" />
          {rank && (
            <span className="relative bg-gray-100 px-2 py-0.5 rounded-sm text-gray-600 text-sm font-semibold md:bg-transparent md:px-0 md:text-sm md:font-semibold dark:bg-gray-800 dark:text-gray-400 dark:md:bg-transparent dark:md:text-gray-400/80">
              #{rank}
              <span className="block md:hidden bg-gray-100 h-2 w-2 absolute top-1/2 -translate-y-1/2 rotate-45 -left-[4px] dark:bg-gray-800" />
            </span>
          )}
        </div>
        <div aria-hidden="true" className="hidden md:block w-px h-5 bg-gray-300 dark:bg-gray-700" />
        <h2 className="hidden md:block text-xs font-semibold uppercase tracking-wide text-gray-500 px-1 dark:text-gray-400">
          {badge.label}
        </h2>
      </div>
    )
  }

  const containerClassName =
    'mb-1 rounded-sm md:mb-3 md:bg-gray-100 md:rounded-xl md:py-1.5 md:px-2.5 md:pr-4 dark:md:bg-gray-850'

  if (badge.href) {
    return (
      <Link href={badge.href} className={classNames(containerClassName, 'outline-brand-500')}>
        {renderContent()}
      </Link>
    )
  }

  // @todo: add `if (badge.modalComponent)` condition

  return <div className={containerClassName}>{renderContent()}</div>
}
