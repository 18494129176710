import { HiChevronLeft } from 'react-icons/hi'
import { classNames } from 'helpers/utilities'

type NextPreviousButtonProps = {
  forward: boolean
  handleClickNext: (e: React.MouseEvent<HTMLButtonElement>, forward: boolean) => void
  className?: string
}
export default function NextPreviousButton({
  forward,
  handleClickNext,
  className
}: NextPreviousButtonProps) {
  return (
    <button
      onClick={(e) => handleClickNext(e, forward)}
      className={classNames(
        'rounded-full shadow-lg bg-gray-900/80 lg:bg-white/5 text-white/80 hover:text-white focus:outline-none',
        className ? className : ''
      )}
    >
      <HiChevronLeft
        className={classNames(forward ? 'rotate-180' : '', 'h-10 w-10')}
        aria-hidden="true"
      />
    </button>
  )
}
