import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { HiChevronDown } from 'react-icons/hi2'
import Checkbox from 'components/Checkbox'
import { AssetSubNav, AssetTypeFilter } from '../Assets.types'
import { classNames } from 'helpers/utilities'

interface TypeFilterProps {
  filters: { [key in AssetTypeFilter]: boolean }
  handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  filtersCount: number
  activeTab: AssetSubNav
  className?: string
}

export default function TypeFilter({
  filters,
  handleFilterChange,
  filtersCount,
  activeTab,
  className = 'inline-block'
}: TypeFilterProps) {
  return (
    <Popover className={classNames('relative text-left', className)}>
      <Popover.Button className="group inline-flex justify-center rounded-sm px-2 py-1 text-sm font-medium text-gray-700 hover:text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500 dark:text-gray-300">
        <span>Types</span>
        {filtersCount > 0 && (
          <span className="ml-1.5 rounded bg-brand-500 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-white">
            {filtersCount}
          </span>
        )}
        <HiChevronDown
          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400"
          aria-hidden="true"
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute right-0 z-20 mt-2.5 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-850 dark:ring-white dark:ring-opacity-5">
          <form className="space-y-4">
            {Object.entries(AssetTypeFilter)
              // hide "Tokens" option in For Sale tab
              .filter(([, value]) => {
                return !(activeTab === AssetSubNav.ForSale && value === AssetTypeFilter.Tokens)
              })
              .map(([key, value]) => (
                <div key={value} className="flex items-center">
                  <Checkbox
                    id={value}
                    label={key}
                    value={value}
                    checked={filters[value]}
                    onChange={handleFilterChange}
                    labelClassName="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 dark:text-white"
                  />
                </div>
              ))}
          </form>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
