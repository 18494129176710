export enum HistoryType {
  Claim = 'Claimed',
  Many = 'Updated',
  Mint = 'Minted',
  Purchase = 'Purchased',
  Sell = 'Listed for sale',
  CancelSell = 'Cancelled sale',
  Auction = 'Placed on auction',
  Update = 'Changed',
  Verify = 'Verified',
  Address = 'Address',
  Remove = 'Removed',
  Internal = 'Internal',
  Upgrade = 'Upgraded contract',
  Renew = 'Renewed'
}

export type HistoryData = {
  type: HistoryType
  isParent: boolean // is a single item or a parent of a group of items that can be expanded
  text: string
  key?: string
  value?: string
  date: string
  price?: number | string
  address?: string
  isParentGroup?: boolean // belongs to a group of non-expandable "parent" items, e.g. v3 mints
}

export type FilteredChanges = [string, string][]
