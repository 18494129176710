import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { nfdActivity, NfdActivityParams } from 'src/api/api-client'
import type { NFDActivity } from 'types/api'
import { captureException } from '@sentry/nextjs'

export const getActivity = async (
  params: NfdActivityParams,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const { data } = await nfdActivity(params, {
    ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
    ...options
  })

  return data
}

type UseNameArgs = {
  params: NfdActivityParams
  options?: UseQueryOptions<NFDActivity[], AxiosError>
}

export default function useGetActivity({ params, options }: UseNameArgs) {
  const result = useQuery<NFDActivity[], AxiosError>(
    ['activity', { ...params }],
    () => getActivity(params),
    {
      ...options
    }
  )

  if (result.error) {
    captureException(result.error)
  }

  return result
}
