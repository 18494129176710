import Badge from './Badge'
import useBadgesComponent from './Badges.hooks'
import type { NfdRecord } from 'api/api-client'

export default function Badges({ nfd }: { nfd: NfdRecord }) {
  const { donations, isLoading, error } = useBadgesComponent(nfd)

  const getClasses = (i: number) => {
    if (i < 2) {
      return ''
    } else if (i === 2) {
      return 'hidden xl:block'
    }
    return 'hidden'
  }

  if (isLoading || error) {
    return null
  }

  return (
    <div className="flex items-center space-x-4 sm:space-x-6 xl:space-x-8">
      {donations.map((donation, i) => (
        <div key={donation.name} className={getClasses(i)}>
          <Badge {...donation} />
        </div>
      ))}
    </div>
  )
}
