import type { NfdRecord } from 'api/api-client'

export const listAlgoAccounts = (nfd: NfdRecord): string[] => {
  return Array.from(
    new Set([
      ...(nfd.owner ? [nfd.owner] : []),
      ...(nfd.nfdAccount ? [nfd.nfdAccount] : []),
      ...(nfd.caAlgo || [])
    ])
  )
}

export const isNfdRecord = (object: unknown): object is NfdRecord => {
  return (
    typeof object === 'object' &&
    object !== null &&
    'name' in object &&
    typeof (object as NfdRecord).name === 'string'
  )
}
