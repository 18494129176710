export default function ConfirmDialog() {
  return (
    <>
      <p className="text-sm text-gray-500">
        Transferring an NFD will erase all of its stored metadata, including any verified social
        media accounts.
      </p>
      <p className="mt-2 text-sm text-gray-500">This cannot be undone.</p>
      <p className="mt-2 text-sm text-gray-500">Are you sure you want to continue?</p>
    </>
  )
}
