import { Dialog, Transition } from '@headlessui/react'
import Link from 'next/link'
import { Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import { HiOutlineXMark } from 'react-icons/hi2'
import AssetMedia from 'components/AssetMedia'
import ExplorerIcon from 'components/ExplorerIcon'
import UserThumbnail from 'components/UserThumbnail'
import { IconPeraExplorer } from 'components/DetailView/Assets/Assets.icons'
import { useExplorerStore } from 'store/index'
import { iconMap, marketplaceMap } from './AssetListingPreview.constants'
import { renderPrice } from './AssetListingPreview.utils'
import galleryImageLoader from 'helpers/galleryImageLoader'
import type { NftListing } from 'types/listings'

interface AssetListingDetailsProps {
  open: boolean
  setOpen: (open: boolean) => void
  asset: NftListing
}

export default function AssetListingDetails({ open, setOpen, asset }: AssetListingDetailsProps) {
  const selectedExplorer = useExplorerStore((state) => state.selectedExplorer)
  const lookupByAssetId = useExplorerStore((state) => state.lookupByAssetId)

  const renderInfo = () => {
    return (
      <div>
        <dl className="-mt-1 divide-y divide-gray-200 border-b border-t border-gray-200 dark:divide-gray-800 dark:border-gray-800">
          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500">Creator</dt>
            <dd className="text-gray-900 dark:text-gray-100 max-w-[75%] xs:max-w-[80%] sm:max-w-[16rem] flex items-center justify-end pl-2">
              <UserThumbnail
                address={asset.creatorAddress}
                size="sm"
                textColor="text-gray-900 group-hover:text-brand-500 dark:text-gray-100"
                forceAddressFallback={asset.unitName === 'NFD'}
                fallbackClassName="text-gray-900 truncate dark:text-gray-100"
                wrapperClassName="min-w-0 flex items-center"
                staleTime={Infinity}
              />
            </dd>
          </div>

          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500">Asset ID</dt>
            <dd className="text-gray-900 dark:text-gray-100">{asset.assetId}</dd>
          </div>

          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500">Marketplace</dt>
            <dd className="flex items-center text-gray-900 dark:text-gray-100">
              <div className="flex justify-center items-center px-2">
                {iconMap[asset.marketplace]()}
              </div>
              {marketplaceMap[asset.marketplace]}
            </dd>
          </div>

          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500">Price</dt>
            <dd className="text-gray-900 dark:text-gray-100">{renderPrice(asset)}</dd>
          </div>
        </dl>
      </div>
    )
  }

  const renderViewListing = () => {
    if (asset.unitName?.toLocaleLowerCase() === 'nfd') {
      return (
        <div className="mt-4">
          <Link
            href={`/name/${asset.name}`}
            className="flex items-center justify-center shadow-sm font-medium w-full px-4 py-2 text-sm rounded-md border border-transparent text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50 disabled:bg-brand-500 dark:bg-brand-600 dark:hover:bg-brand-500 dark:focus:ring-offset-gray-900 dark:ring-gray-100 dark:disabled:opacity-25 dark:disabled:bg-brand-600"
          >
            View listing
          </Link>
        </div>
      )
    }

    return (
      <div className="mt-4">
        <a
          href={asset.marketplaceLink}
          className="flex items-center justify-center shadow-sm font-medium w-full px-4 py-2 text-sm rounded-md border border-transparent text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50 disabled:bg-brand-500 dark:bg-brand-600 dark:hover:bg-brand-500 dark:focus:ring-offset-gray-900 dark:ring-gray-100 dark:disabled:opacity-25 dark:disabled:bg-brand-600"
          target="_blank"
          rel="noreferrer"
        >
          {`${asset.auction ? 'Bid' : 'Buy'} on ${marketplaceMap[asset.marketplace]}`}
        </a>
      </div>
    )
  }

  const renderImage = () => {
    return (
      <AssetMedia
        src={asset.imageUrl}
        alt={asset.assetId.toString()}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="320px"
        fill
        options={{
          optimizeGifs: false,
          stateBgColor: 'bg-gray-500/5 dark:bg-gray-500/5'
        }}
        videoJsOptions={{
          preload: isMobile ? 'auto' : 'metadata',
          controls: isMobile,
          fluid: true,
          fill: true
        }}
      />
    )
  }

  const renderExplorerLinks = () => {
    return (
      <div className="pb-6 pt-4">
        <div className="flex text-sm">
          <a
            href={lookupByAssetId(asset.assetId)}
            className="group inline-flex items-center text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
            target="_blank"
            rel="noreferrer"
          >
            <ExplorerIcon
              className="w-4 h-4 text-gray-400 group-hover:text-gray-500 dark:text-gray-600 dark:group-hover:text-gray-500"
              explorer={selectedExplorer}
            />
            <span className="ml-2">View on {selectedExplorer}</span>
          </a>
        </div>
        <div className="mt-4 flex text-sm">
          <a
            href={`https://explorer.perawallet.app/asset/${asset.assetId}`}
            className="group inline-flex items-center text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
            target="_blank"
            rel="noreferrer"
          >
            <IconPeraExplorer
              className="w-4 h-4 text-gray-400 group-hover:text-gray-500 dark:text-gray-600 dark:group-hover:text-gray-500"
              aria-hidden="true"
            />
            <span className="ml-2">View on Pera Explorer</span>
          </a>
        </div>
      </div>
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8 dark:bg-gray-900">
                    <div className="space-y-6 pb-16">
                      <div>
                        <div className="relative aspect-square block w-full overflow-hidden rounded-lg">
                          {renderImage()}
                        </div>
                        <div className="mt-5 flex items-start justify-between">
                          <div>
                            <h2 className="text-xl font-semibold leading-7 text-gray-900 dark:text-gray-100">
                              <span className="sr-only">Details for </span>
                              {asset.name}
                            </h2>
                          </div>
                        </div>
                      </div>
                      {renderInfo()}
                      {renderViewListing()}
                      {renderExplorerLinks()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
