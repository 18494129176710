import { useMemo } from 'react'
import useSuggest from 'api/hooks/useSuggest'
import { ZERO_ADDRESS } from 'src/data/constants'

interface UseNotFoundProps {
  name: string
  activeAddress: string | undefined
}

export default function useNotFound({ name, activeAddress }: UseNotFoundProps) {
  const { data, isInitialLoading: isLoading } = useSuggest({
    name,
    params: { view: 'full', buyer: activeAddress || ZERO_ADDRESS }
  })

  const match = data?.[0]

  const { state, category } = match || {}

  const isAvailable = useMemo(() => {
    return state === 'available' && category !== 'curated'
  }, [category, state])

  const isCurated = useMemo(() => {
    return state === 'available' && category === 'curated'
  }, [category, state])

  return {
    isLoading,
    isAvailable,
    isCurated
  }
}
