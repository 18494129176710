import { useMemo } from 'react'
import { CgGlobeAlt, CgMail } from 'react-icons/cg'
import { FaDiscord, FaTelegram, FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa'
import LoadingDots from 'components/LoadingDots'
import useLinks from './Links.hooks'
import { copyToClipboard } from 'helpers/copyToClipboard'
import { formatUsername } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'
import type { UseQueryResult } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

export default function Links({ nfd }: { nfd: NfdRecord }) {
  const { telegramQuery, discordQuery, isLocalhost } = useLinks(nfd)

  const hasVerifiedAccts = useMemo(() => {
    const keys = ['domain', 'email', 'twitter', 'telegram', 'discord', 'linkedin', 'github']
    return Object.keys(nfd.properties?.verified || []).some((key) => keys.includes(key))
  }, [nfd])

  if (!hasVerifiedAccts) {
    return null
  }

  const renderDynamicLink = (
    query: UseQueryResult<string, AxiosError>,
    decorateFn = (name: string) => name
  ) => {
    if (query.isInitialLoading) {
      return <LoadingDots className="ml-2" />
    }

    if (query.isError || !query.data) {
      return null
    }

    return decorateFn(query.data)
  }

  const chipClassName =
    'h-9 mb-2 flex items-center text-sm text-gray-700 font-medium mr-2.5 outline-brand-500 bg-gray-50 hover:bg-gray-100/80 rounded-full px-3 py-1.5 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-750/75 dark:highlight'

  return (
    <div className="border-l-4 border-gray-300 pl-3 pt-2 dark:border-gray-800">
      <label className="block text-xs font-semibold uppercase tracking-wide text-gray-500 mb-2">
        Verified
      </label>
      <div className="flex flex-row flex-wrap">
        {nfd.properties?.verified?.domain && (
          <a
            href={`https://${nfd.properties.verified.domain}`}
            target="_blank"
            rel="noreferrer"
            className={chipClassName}
          >
            <CgGlobeAlt
              className="flex-shrink-0 -ml-0.5 mr-1.5 h-6 w-6 text-gray-500"
              aria-hidden="true"
            />
            {nfd.properties.verified.domain}
          </a>
        )}

        {nfd.properties?.verified?.email && (
          <a href={`mailto:${nfd.properties.verified.email}`} className={chipClassName}>
            <CgMail
              className="flex-shrink-0 -ml-0.5 mr-1.5 h-6 w-6 text-gray-500"
              aria-hidden="true"
            />
            {nfd.properties.verified.email}
          </a>
        )}

        {nfd.properties?.verified?.twitter && (
          <a
            href={`https://twitter.com/${formatUsername(nfd.properties.verified.twitter, true)}`}
            target="_blank"
            rel="noreferrer"
            className={chipClassName}
          >
            <FaTwitter className="flex-shrink-0 mr-1.5 h-5 w-5 text-[#1DA1F2]" aria-hidden="true" />
            {formatUsername(nfd.properties.verified.twitter)}
          </a>
        )}

        {nfd.properties?.verified?.github && (
          <a
            href={`https://github.com/${nfd.properties.verified.github}`}
            target="_blank"
            rel="noreferrer"
            className={chipClassName}
          >
            <FaGithub
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-black dark:text-white"
              aria-hidden="true"
            />
            {nfd.properties.verified.github}
          </a>
        )}

        {nfd.properties?.verified?.discord && !discordQuery.isError && !isLocalhost && (
          <a
            className={chipClassName}
            href={`https://discordapp.com/users/${nfd.properties.verified.discord}`}
          >
            <FaDiscord className="flex-shrink-0 mr-1.5 h-5 w-5 text-[#5865F2]" aria-hidden="true" />
            {renderDynamicLink(discordQuery)}
          </a>
        )}

        {nfd.properties?.verified?.telegram && !telegramQuery.isError && !isLocalhost && (
          <button
            className={chipClassName}
            data-clipboard-text={telegramQuery.data || ''}
            onClick={copyToClipboard}
            disabled={!telegramQuery.data}
          >
            {/* this wrapping span makes the paper airplane white */}
            <span className="flex-shrink-0 mr-1.5 h-5 w-5 rounded-full bg-white dark:ring-inset dark:ring-1 dark:ring-gray-800">
              <FaTelegram className="h-5 w-5 text-[#2AABEE]" aria-hidden="true" />
            </span>
            {renderDynamicLink(telegramQuery, (name) => `@${name}`)}
          </button>
        )}

        {nfd.properties?.verified?.linkedin && (
          <a
            href={`https://linkedin.com/in/${nfd.properties.verified.linkedin}`}
            target="_blank"
            rel="noreferrer"
            className={chipClassName}
          >
            <FaLinkedin
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-[#0a66c2]"
              aria-hidden="true"
            />
            {nfd.properties.verified.linkedin}
          </a>
        )}
      </div>
    </div>
  )
}
