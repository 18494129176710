import React, { useMemo } from 'react'
import { AiFillBank } from 'react-icons/ai'
import { BiWallet } from 'react-icons/bi'
import { HiPlusSmall } from 'react-icons/hi2'
import Tooltip from 'components/ReactTooltip'
import { classNames, truncateAddress } from 'helpers/utilities'
import type { Asset } from 'types/assets'
import type { NfdRecord } from 'api/api-client'

interface AccountsBadgeProps {
  nfd: NfdRecord
  asset: Asset
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  compact?: boolean
  lightStyles?: boolean
}

const AccountsBadge = React.forwardRef<HTMLButtonElement, AccountsBadgeProps>(
  ({ nfd, asset, onClick, className = '', compact = false, lightStyles = false }, ref) => {
    const badgeClasses = classNames(
      lightStyles
        ? 'dark:ring-1 dark:ring-inset dark:ring-black/40'
        : 'ring-1 ring-inset ring-black/40',
      'flex items-center justify-center overflow-hidden rounded-lg'
    )

    const iconClasses = 'h-3.5 w-3.5'

    const VaultBadge = ({ label = '' }) => (
      <span
        className={classNames(
          badgeClasses,
          'h-6 w-6 bg-brand-500 xl:bg-brand-600 xl:group-hover:bg-brand-500 xl:transition-colors'
        )}
        data-tooltip-id={`${asset.id}-${label}`}
        data-tooltip-content="NFD Vault"
        data-tooltip-delay-show={150}
      >
        <AiFillBank className={classNames(iconClasses, 'text-white')} aria-hidden="true" />
      </span>
    )

    const WalletBadge = ({ account = '' }) => (
      <span
        className={classNames(
          badgeClasses,
          lightStyles
            ? 'bg-gray-600 group-hover:bg-gray-500 dark:bg-gray-700 dark:group-hover:bg-gray-600'
            : 'bg-gray-700 group-hover:bg-gray-600',
          'h-6 w-6 transition-colors'
        )}
        data-tooltip-id={`${asset.id}-${account}`}
        data-tooltip-content={truncateAddress(account) as string}
        data-tooltip-delay-show={150}
      >
        <BiWallet
          className={classNames(
            iconClasses,
            lightStyles
              ? 'text-gray-300 group-hover:text-gray-200 dark:text-gray-400 dark:group-hover:text-gray-300'
              : 'text-gray-400 group-hover:text-gray-300',
            'transition-colors'
          )}
          aria-hidden="true"
        />
      </span>
    )

    const WalletCount = ({ count = 0 }) => (
      <span
        className={classNames(
          badgeClasses,
          count < 2 ? 'w-6' : 'px-1.5',
          lightStyles
            ? 'bg-gray-600 text-gray-100 group-hover:bg-gray-500 dark:bg-gray-700 dark:text-gray-400 dark:group-hover:bg-gray-600 dark:group-hover:text-gray-300'
            : 'bg-gray-700 text-gray-400 group-hover:bg-gray-600 group-hover:text-gray-300',
          'h-6 transition-colors'
        )}
        data-tooltip-id={`${asset.id}-count`}
        data-tooltip-content={`${count} Accounts`}
        data-tooltip-delay-show={150}
      >
        <BiWallet className={iconClasses} aria-hidden="true" />
        <span
          className={classNames(
            count < 2 ? 'hidden' : '',
            'ml-[3px] text-xs font-semibold text-gray-300'
          )}
        >
          {count}
        </span>
      </span>
    )

    const WalletCountPlus = ({ count = 0 }) => (
      <span
        className={classNames(
          badgeClasses,
          'h-6 w-6 bg-gray-700 text-gray-400 transition-[color,background-color] group-hover:w-auto group-hover:bg-gray-600 group-hover:text-gray-300'
        )}
        data-tooltip-id={`${asset.id}-count-plus`}
        data-tooltip-content={`${count} More Accounts`}
        data-tooltip-delay-show={150}
      >
        <span className="inline-flex items-center transition-[margin,opacity] m-0 opacity-0 group-hover:ml-1 group-hover:mr-1.5 group-hover:opacity-100">
          <HiPlusSmall className={iconClasses} aria-hidden="true" />
          <span className="text-xs font-semibold text-gray-300">{count}</span>
        </span>
      </span>
    )

    const accounts = useMemo(() => {
      const accounts = asset.amounts.map((assetAmount) => {
        if (assetAmount.account === nfd.nfdAccount) {
          return nfd
        } else {
          return assetAmount.account
        }
      })

      return accounts.sort((a, b) => {
        if (typeof a === 'string' && typeof b === 'object') {
          return 1
        } else if (typeof a === 'object' && typeof b === 'string') {
          return -1
        } else {
          return 0
        }
      })
    }, [asset.amounts, nfd])

    if (accounts.length === 0 || (accounts.length === 1 && typeof accounts[0] === 'string')) {
      return null
    }

    const renderBadges = () => {
      const maxBadges = 7

      return accounts.slice(0, maxBadges).map((account, i) => {
        if (i === maxBadges - 1 && accounts.length > maxBadges) {
          const remainingCount = accounts.length - maxBadges + 1
          return (
            <span
              key={i}
              className="transition-[margin] ease-in-out"
              style={{ zIndex: accounts.length - i, transitionDelay: `${i * 30}ms` }}
            >
              <WalletCountPlus count={remainingCount} />
              <Tooltip id={`${asset.id}-count-plus`} />
            </span>
          )
        } else if (typeof account === 'string') {
          return (
            <span
              key={i}
              className="transition-[margin] ease-in-out"
              style={{ zIndex: accounts.length - i, transitionDelay: `${i * 30}ms` }}
            >
              <WalletBadge account={account} />
              <Tooltip id={`${asset.id}-${account}`} />
            </span>
          )
        } else {
          return (
            <span
              key={i}
              className="transition-[margin] ease-in-out"
              style={{ zIndex: accounts.length - i, transitionDelay: `${i * 30}ms` }}
            >
              <VaultBadge label={account.name} />
              <Tooltip id={`${asset.id}-${account.name}`} />
            </span>
          )
        }
      })
    }

    const renderBadgesMobile = () => {
      const wallets = accounts.filter((account) => typeof account === 'string')

      return (
        <>
          {typeof accounts[0] === 'object' && (
            <>
              <VaultBadge label={accounts[0].name} />
              <Tooltip id={`${asset.id}-${accounts[0].name}`} style={{ zIndex: 20 }} />
            </>
          )}
          {wallets.length === 1 ? (
            <>
              <WalletBadge account={wallets[0] as string} />
              <Tooltip id={`${asset.id}-${wallets[0] as string}`} style={{ zIndex: 20 }} />
            </>
          ) : wallets.length > 1 ? (
            <>
              <WalletCount count={wallets.length} />
              <Tooltip id={`${asset.id}-count`} style={{ zIndex: 20 }} />
            </>
          ) : null}
        </>
      )
    }

    return (
      <button ref={ref} type="button" className={classNames('group', className)} onClick={onClick}>
        <span className={classNames(compact ? 'flex' : 'xl:hidden flex', 'space-x-0.5 p-2')}>
          {renderBadgesMobile()}
        </span>
        <span
          className={classNames(
            compact ? 'hidden' : 'hidden xl:flex isolate',
            '-space-x-3.5 hover:space-x-0.5 p-2'
          )}
        >
          {renderBadges()}
        </span>
      </button>
    )
  }
)

AccountsBadge.displayName = 'VaultBadge'

export default AccountsBadge
