import { useRef, useState } from 'react'
import { HiMagnifyingGlass, HiXMark } from 'react-icons/hi2'
import { useDebouncedCallback } from 'use-debounce'
import { classNames } from 'helpers/utilities'

interface SearchFilterProps {
  searchQuery: string | null
  setSearchQuery: (value: string | null) => void
  className?: string
}

export default function SearchFilter({
  searchQuery,
  setSearchQuery,
  className = ''
}: SearchFilterProps) {
  const [query, setQuery] = useState<string>(searchQuery ?? '')

  const inputRef = useRef<HTMLInputElement>(null)

  const debounced = useDebouncedCallback((value) => {
    setSearchQuery(value)
  }, 500)

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setQuery(value)
    debounced(value ? value : null)
  }

  const handleClearInput = () => {
    setQuery('')
    setSearchQuery(null)
    inputRef.current?.focus()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      handleClearInput()
    }
  }

  return (
    <div className={classNames('w-full', className)}>
      <label htmlFor="search-assets" className="sr-only">
        Search assets
      </label>
      <div className="relative rounded-md shadow-sm text-gray-400 focus-within:text-gray-600 dark:text-gray-600 dark:focus-within:text-gray-400">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <HiMagnifyingGlass className="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          ref={inputRef}
          type="text"
          id="search-assets"
          className={classNames(
            !query ? '' : 'sm:pr-12',
            'block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6 dark:bg-white/5 dark:text-white dark:ring-white/10 dark:focus:ring-brand-500 dark:placeholder:text-gray-500'
          )}
          value={query}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
          placeholder="Search"
        />
        <button
          type="button"
          className={classNames(
            !query ? 'hidden' : 'hidden sm:flex',
            'absolute inset-y-0 right-0 items-center px-2.5'
          )}
          onClick={handleClearInput}
          aria-label="Clear"
          tabIndex={-1}
        >
          <HiXMark className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}
