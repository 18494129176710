export const IconNftExplorer = ({ className = '' }) => (
  <svg viewBox="0 0 32.77 29.55" stroke="currentColor" fill="currentColor" className={className}>
    <path d="M8.51,0C5.86,0,3.36,1.13,1.1,3.28l-.2,.19v4.58l1.13-1.2c2.25-2.4,4.31-3.5,6.16-3.5s3.38,.95,4.63,3.1c1.26,2.17,1.9,4.77,1.9,7.85v.12L0,29.55H4.7l10.36-10.72c.23,1.62,.49,3.22,1.03,4.52h0v.02c.73,1.6,1.59,2.85,2.6,3.71,1.84,1.61,3.71,2.48,5.57,2.48,2.62,0,5.11-1.13,7.41-3.27l.21-.19v-4.58l-1.13,1.2c-2.25,2.4-4.33,3.5-6.21,3.5s-3.33-.92-4.58-2.99c-1.19-2.07-1.8-4.56-1.87-7.45L32.77,.55h-4.63l-10.4,10.71c-.21-1.69-.45-3.35-.99-4.72-.69-1.72-1.52-3.06-2.54-4h0v-.02c-1.92-1.65-3.82-2.53-5.69-2.53h0Z" />
  </svg>
)

export const IconPeraExplorer = ({ className = '' }) => (
  <svg viewBox="0 0 95 95" fill="currentColor" className={className}>
    <path d="M53.2,14.1c2,8.5,1.4,15.9-1.5,16.7c-2.9,0.7-6.9-5.6-9-14c-2-8.5-1.4-15.9,1.5-16.7 C47.2-0.7,51.2,5.6,53.2,14.1z" />
    <path d="M87,21.4c-4.5-4.8-13.5-3.5-20.1,2.9c-6.6,6.4-8.3,15.5-3.7,20.3c4.5,4.8,13.5,3.5,20.1-2.9 C89.9,35.3,91.5,26.2,87,21.4z" />
    <path d="M51,95c2.9-0.7,3.5-8.6,1.3-17.6c-2.2-9-6.3-15.7-9.2-15c-2.9,0.7-3.5,8.6-1.3,17.6C43.9,89,48.1,95.7,51,95z" />
    <path d="M21.4,25.8c8.4,2.5,14.5,6.8,13.7,9.7c-0.8,2.9-8.3,3.2-16.6,0.7C10,33.7,3.9,29.4,4.7,26.5 C5.6,23.7,13,23.3,21.4,25.8z" />
    <path d="M75.7,58.2c8.9,2.6,15.4,7.1,14.6,9.9c-0.8,2.9-8.7,3.1-17.6,0.5c-8.9-2.6-15.4-7.1-14.6-9.9 C59,55.8,66.8,55.6,75.7,58.2z" />
    <path d="M30.8,52.2c-2.1-2.1-8.9,1-15.2,7c-6.3,6-9.8,12.6-7.7,14.8c2.1,2.1,8.9-1,15.2-7C29.4,61,32.9,54.4,30.8,52.2z" />
  </svg>
)

export const IconAlgoXNft = ({ className = '' }) => (
  <svg className={className} viewBox="0 0 54.78 37.79">
    <polygon
      points="12.35 37.79 17.05 37.79 17.05 33.69 29.29 33.69 29.29 37.79 33.67 37.79 33.67 29.46 37.91 29.46 37.91 25.22 42.07 25.22 42.07 20.99 46.24 20.99 46.24 17.25 50.33 17.25 50.33 20.92 54.78 20.92 54.78 16.82 50.45 16.82 50.45 12.47 46.35 12.47 46.35 16.71 41.93 16.71 41.93 8.42 38.07 8.42 38.07 4 25.36 4 25.36 0 20.99 0 20.99 4.05 17.05 4.05 17.05 0 12.35 0 12.35 4.05 8.38 4.05 8.38 12.28 0 12.28 0 25.32 8.28 25.32 8.28 29.74 12.28 29.74 12.35 37.79"
      fill="#f51a8e"
    />
    <rect x="12.38" y="8.38" width="4.68" height="4.33" fill="#fff" />
    <rect x="12.38" y="8.38" width="4.68" height="4.33" />
  </svg>
)
