import Image from 'next/image'
import Link from 'next/link'
import AlgoPrice from 'components/AlgoPrice'
import Tooltip from 'components/Tooltip'

interface BadgeProps {
  name: string
  fullName: string | undefined
  image: string | undefined
  total: number
  rank?: number | undefined
}

export default function Badge({ name, fullName, image, total, rank }: BadgeProps) {
  const renderCard = () => {
    if (rank && rank > 0) {
      return (
        <span className="w-16 h-6 [perspective:1000px]">
          <span className="relative w-full h-full transition-transform duration-500 [transform-style:preserve-3d] group-hover:[transform:rotatex(180deg)]">
            <span className="absolute inset-0 [backface-visibility:hidden]">
              <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-gray-700 bg-gray-100 ml-2 pr-1 py-0.5 rounded-sm z-[2]">
                <AlgoPrice
                  price={total}
                  options={{ notation: 'compact' }}
                  className="ml-1.5 mr-0.5"
                />
                <span className="block bg-gray-100 h-2 w-2 absolute rotate-45 -left-[4px]" />
              </span>
            </span>
            <span className="absolute inset-0 [backface-visibility:hidden] [transform:rotatex(180deg)]">
              <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-gray-700 bg-gray-200 ml-2 pr-1 py-0.5 rounded-sm z-[1]">
                #{rank}
                <span className="block bg-gray-200 h-2 w-2 absolute rotate-45 -left-[4px]" />
              </span>
            </span>
          </span>
        </span>
      )
    }

    return (
      <span className="relative flex items-center justify-center text-xs font-semibold text-gray-700 bg-gray-100 ml-2 py-0.5 rounded-sm dark:bg-gray-800 dark:text-gray-400">
        <AlgoPrice price={total} options={{ notation: 'compact' }} className="ml-2 mr-3" />
        <span className="block bg-gray-100 h-2 w-2 absolute rotate-45 -left-[4px] dark:bg-gray-800" />
      </span>
    )
  }

  if (!image) {
    return null
  }

  return (
    <Link href={`/name/${name}`}>
      <Tooltip as="span" text={fullName || name}>
        <span className="flex items-center group xl:scale-110">
          <span className="w-6 h-6">
            <Image
              className="rounded-full overflow-hidden"
              src={image}
              width={24}
              height={24}
              alt={name}
            />
          </span>{' '}
          {renderCard()}
        </span>
      </Tooltip>
    </Link>
  )
}
