import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import Link from 'next/link'
import * as React from 'react'
import { HiChevronDown } from 'react-icons/hi'
import AlgoPrice from 'components/AlgoPrice'
import AlgoSymbol from 'components/AlgoSymbol'
import Linkify from 'components/Linkify'
import { addAlgoProtocol } from 'helpers/linkify'
import { clsxMerge, convertAlgosToMicroalgos } from 'helpers/utilities'
import { HistoryData, HistoryType } from './History.types'
import Icon, { IconColor } from './Icon'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)

type HistoryItemProps = {
  historyItem: HistoryData
  isCollapsible: boolean
  isOpen: boolean
  isLastItem: boolean
}

export default function HistoryItem({
  historyItem,
  isCollapsible,
  isOpen,
  isLastItem
}: HistoryItemProps) {
  const historyValue = React.useMemo(() => {
    if (historyItem.value === undefined) {
      return historyItem.value
    }

    return addAlgoProtocol(historyItem.value)
  }, [historyItem.value])

  const Key = ({ children }: { children: React.ReactNode }) => (
    <span className="mr-1 inline-flex items-center rounded-sm bg-gray-100 px-1.5 py-0.5 text-sm font-medium text-gray-600 font-mono scale-90 origin-left whitespace-nowrap dark:bg-gray-800 dark:text-gray-300">
      {children}
    </span>
  )

  const renderRemoveHistory = (historyItem: HistoryData) => (
    <>
      <Key>{historyItem.key}</Key>
      <span className="text-gray-500 inline-flex items-center dark:text-gray-500">(Removed)</span>
    </>
  )

  const renderVerifyHistory = (historyItem: HistoryData, historyValue: string | undefined) => (
    <>
      <Key>{historyItem.key}</Key>
      <span className="inline-flex items-center truncate font-medium dark:text-gray-100">
        <Linkify>{historyValue}</Linkify>
      </span>
    </>
  )

  const renderHistory = () => {
    if (historyItem.isParent) {
      return (
        <>
          <span className="text-gray-700 font-medium truncate dark:text-gray-300">
            {historyItem.type} {historyItem.text}
            {historyItem.address && (
              <Link
                href={`/address/${historyItem.address}`}
                className="text-brand-500 truncate hover:text-brand-600 outline-brand-500 rounded-md px-1"
              >
                {historyItem.address}
              </Link>
            )}
          </span>
        </>
      )
    }

    if (historyItem.type === HistoryType.Remove) {
      return renderRemoveHistory(historyItem)
    }

    if (historyItem.type === HistoryType.Verify) {
      return renderVerifyHistory(historyItem, historyValue)
    }

    return (
      <>
        <Key>{historyItem.key}</Key>
        {/* @todo: truncate Linkify links */}
        {/* @todo: show prefs as font-mono */}
        <span className="inline-flex items-center truncate font-medium dark:text-gray-100">
          <Linkify>{historyValue}</Linkify>
        </span>
      </>
    )
  }

  const renderIcon = () => {
    if (historyItem.isParent) {
      return (
        <Icon
          type={historyItem.type}
          size={'lg'}
          isParent={historyItem.isParent}
          color={IconColor.Brand}
        />
      )
    }

    return <Icon type={historyItem.type} />
  }

  const renderDate = () => {
    return (
      historyItem.isParent && (
        <span
          className="text-gray-400 dark:text-gray-500"
          title={dayjs(historyItem.date).format('LLL')}
        >
          {dayjs(historyItem.date).fromNow()}
        </span>
      )
    )
  }

  const renderPrice = () => {
    return (
      historyItem.price && (
        <span className="text-gray-800 ml-2 bg-gray-100 font-semibold px-2.5 min-w-fit rounded-full leading-normal dark:bg-gray-800 dark:text-gray-300">
          {typeof historyItem.price === 'number' ? (
            <AlgoPrice price={convertAlgosToMicroalgos(historyItem.price)} />
          ) : (
            <>
              <AlgoSymbol /> {historyItem.price}
            </>
          )}
        </span>
      )
    )
  }

  const renderChevron = () => {
    return (
      historyItem.isParent &&
      isCollapsible && (
        <HiChevronDown
          className={`transition-transform ${
            isOpen ? 'transform rotate-180' : ''
          } w-5 h-5 ml-1 text-gray-400 inline dark:text-gray-600`}
        />
      )
    )
  }

  const renderConnector = () => {
    if (isLastItem || !historyItem.isParent) {
      return null
    }
    return (
      <span
        className={clsxMerge(
          'absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-300 dark:bg-gray-700'
        )}
        aria-hidden="true"
      />
    )
  }

  return (
    <div
      className={clsxMerge(!historyItem.isParent ? 'relative pb-6 sm:pb-4' : 'pb-6', 'max-w-max')}
    >
      {renderConnector()}
      <div className="relative flex items-center space-x-3 w-full">
        <div className="relative flex items-center rounded-full">{renderIcon()}</div>
        <div className="min-w-0 flex-1">
          <div className="text-sm text-left">
            <p className="flex items-center rounded-full">
              {renderHistory()}
              {renderPrice()}
              {renderChevron()}
            </p>
            {renderDate()}
          </div>
        </div>
      </div>
    </div>
  )
}
