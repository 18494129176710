import { HiOutlineGift } from 'react-icons/hi'
import { Switch } from '@headlessui/react'
import Toggle from 'components/Toggle'
import { classNames } from 'helpers/utilities'
import AlgoPrice from 'components/AlgoPrice'

type ToggleGiftProps = {
  isGift: boolean
  handleChange: (isGift: boolean) => void
}

export default function ToggleGift({ isGift, handleChange }: ToggleGiftProps) {
  return (
    <div className="pt-4 max-w-sm">
      <Switch.Group
        as="div"
        className="flex items-center justify-between w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 dark:bg-gray-800 dark:border-none"
      >
        <span className="flex items-center justify-between flex-1">
          <Switch.Label
            as="span"
            className="text-sm text-gray-900 font-medium text-ellipsis overflow-hidden inline-flex items-center dark:text-gray-300"
            passive
          >
            <HiOutlineGift
              className={classNames(
                'w-6 h-6 mr-3',
                isGift ? 'text-brand-500' : 'text-gray-400 dark:text-gray-500'
              )}
            />
            Give NFD as a gift
          </Switch.Label>
        </span>
        <Toggle checked={isGift} onChange={handleChange} />
      </Switch.Group>
      <p className="mt-2 text-sm text-gray-500" id="reserved-description">
        Send <AlgoPrice price={1108000} className="font-medium text-gray-600 dark:text-gray-400" />{' '}
        to the recipient to cover the cost to claim
      </p>
    </div>
  )
}
