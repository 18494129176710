import styled from 'styled-components'

export const HeaderGrid = styled.div`
  grid-template-areas:
    'name'
    'bio'
    'links'
    'aside';

  @media (min-width: 768px) {
    grid-template-areas:
      'name aside'
      'bio aside'
      'links aside';
  }
`

export const HeaderName = styled.div`
  grid-area: name;
`

export const HeaderBio = styled.div`
  grid-area: bio;
`

export const HeaderAside = styled.aside`
  grid-area: aside;
`

export const HeaderLinks = styled.div`
  grid-area: links;
`
