import History from './History'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'
import { captureException } from '@sentry/nextjs'

export default withErrorBoundary(History, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    console.error('Failed to load activity', error, info)
    captureException(error)
  }
})
