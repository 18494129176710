import Image from 'next/image'
import { BiCopy } from 'react-icons/bi'
import Tooltip from 'components/Tooltip'
import { copyToClipboard } from 'helpers/copyToClipboard'
import { classNames } from 'helpers/utilities'
import { iconClassName } from './CryptoAddress.constants'

interface CryptoAddressProps {
  address: string
  symbol: string
  copy?: boolean
  expand?: boolean
}

export default function CryptoAddress({
  address,
  symbol,
  copy = false,
  expand = false
}: CryptoAddressProps) {
  return (
    <div
      className={classNames(
        expand ? 'flex-1' : '',
        'text-sm text-gray-900 font-mono flex items-center justify-between min-w-0 dark:text-gray-400'
      )}
    >
      <div className="flex items-center min-w-0">
        <Tooltip as="span" text={symbol.toUpperCase()} className="font-sans">
          <Image
            src={`/img/crypto/${symbol}.svg`}
            alt={symbol}
            width={20}
            height={20}
            className={iconClassName[symbol]}
          />
        </Tooltip>
        <span className={classNames(expand ? 'flex-1' : '', 'mx-4 truncate')} title={address}>
          {address}
        </span>
      </div>

      {copy && (
        <div className="inline-flex -space-x-px rounded-md shadow-sm ml-3 sm:ml-4">
          <Tooltip
            as="span"
            text="Copy"
            wrapperClassName="group"
            className="font-sans translate-y-2"
          >
            <button
              type="button"
              className="relative inline-flex items-center group-first:rounded-l-md group-last:rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 outline-brand-500 dark:bg-gray-750 dark:border-transparent dark:border-l-black/20 dark:group-first:border-l-transparent dark:text-gray-300 dark:hover:bg-gray-700"
              data-clipboard-text={address}
              onClick={copyToClipboard}
            >
              <span className="sr-only">Copy address</span>
              <BiCopy className="h-[18px] w-[18px]" aria-hidden="true" />
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  )
}
