import Image from 'next/image'
import Link from 'next/link'
import { BiCopy, BiLinkExternal, BiWallet } from 'react-icons/bi'
import Tooltip from 'components/Tooltip'
import { copyToClipboard } from 'helpers/copyToClipboard'
import { classNames } from 'helpers/utilities'
import { useExplorerStore } from 'store/index'

interface AlgoAddressProps {
  address: string
  copy?: boolean
  link?: boolean
  logo?: boolean
  expand?: boolean
  isDeposit?: boolean
  href?: string
}

export default function AlgoAddress({
  address,
  copy = false,
  link = false,
  logo = false,
  expand = false,
  isDeposit = false,
  href = `/address/${address}`
}: AlgoAddressProps) {
  const selectedExplorer = useExplorerStore((state) => state.selectedExplorer)
  const lookupByAddress = useExplorerStore((state) => state.lookupByAddress)

  return (
    <div
      className={classNames(
        expand ? 'flex-1' : '',
        'text-sm text-gray-900 font-mono flex items-center justify-between min-w-0 dark:text-gray-400'
      )}
    >
      <div className="flex items-center min-w-0">
        {logo && (
          <span className="flex items-center min-w-[20px] mr-4">
            <Tooltip as="span" text="ALGO" className="translate-y-2">
              <Image
                src={`/img/crypto/algo.svg`}
                alt="ALGO"
                width={20}
                height={20}
                className="no-dark-adjust dark:invert dark:opacity-25"
              />
            </Tooltip>
          </span>
        )}
        <Link
          href={href}
          className={classNames(
            expand && isDeposit ? 'flex-initial' : expand ? 'flex-1' : '',
            'mr-2.5 truncate hover:text-brand-500 outline-brand-500'
          )}
          title={address}
        >
          {address}
        </Link>

        {isDeposit && (
          <Tooltip as="span" text="Deposit address" className="flex-auto font-sans translate-y-2">
            <span className="inline-flex items-center p-2 rounded-full text-xs font-medium bg-brand-100 text-brand-700 font-sans dark:bg-brand-500/10 dark:text-brand-500/75">
              <BiWallet className="h-4 w-4" aria-hidden="true" />
            </span>
          </Tooltip>
        )}
      </div>

      <div className="flex items-center flex-nowrap">
        {(link || copy) && (
          <div className="inline-flex -space-x-px rounded-md shadow-sm ml-3 sm:ml-4">
            {link && (
              <Tooltip
                as="span"
                text={selectedExplorer}
                wrapperClassName="group"
                className="font-sans translate-y-2"
              >
                <a
                  href={lookupByAddress(address)}
                  className="relative inline-flex items-center group-first:rounded-l-md group-last:rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 outline-brand-500 dark:bg-gray-750 dark:border-transparent dark:border-l-black/20 dark:group-first:border-l-transparent dark:text-gray-300 dark:hover:bg-gray-700"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">View on {selectedExplorer}</span>
                  <BiLinkExternal className="h-[18px] w-[18px]" aria-hidden="true" />
                </a>
              </Tooltip>
            )}
            {copy && (
              <Tooltip
                as="span"
                text="Copy"
                wrapperClassName="group"
                className="font-sans translate-y-2"
              >
                <button
                  type="button"
                  className="relative inline-flex items-center group-first:rounded-l-md group-last:rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 outline-brand-500 dark:bg-gray-750 dark:border-transparent dark:border-l-black/20 dark:group-first:border-l-transparent dark:text-gray-300 dark:hover:bg-gray-700"
                  data-clipboard-text={address}
                  onClick={copyToClipboard}
                >
                  <span className="sr-only">Copy address</span>
                  <BiCopy className="h-[18px] w-[18px]" aria-hidden="true" />
                </button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
