/* eslint-disable @next/next/no-img-element */
import { memo, useMemo, useState } from 'react'
import AssetMedia from 'components/AssetMedia'
import VerifiedBadge from 'components/VerifiedBadge'
import Tooltip from 'components/Tooltip'
import LightBox from 'components/LightBox'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { getBannerURL } from 'helpers/banner'
import { classNames } from 'helpers/utilities'
import { isBannerVerified } from 'helpers/banner'
import type { NfdRecord } from 'api/api-client'

function Banner({ nfd }: { nfd: NfdRecord }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleClickOpen = () => {
    setIsOpen(true)
  }

  const handleClickClose = () => {
    setIsOpen(false)
  }

  const isVerified = useMemo(() => {
    return isBannerVerified(nfd)
  }, [nfd])

  const renderVerifiedBadge = () => {
    if (isVerified) {
      return (
        <>
          <div className="absolute bottom-0 right-0 -mr-12 -mb-12 scale-50 sm:hidden">
            <VerifiedBadge small />
          </div>
          <div className="hidden sm:block absolute bottom-0 right-0 -mr-12 -mb-12">
            <Tooltip text="Verified banner" direction="left" className="-translate-y-7">
              <div>
                <VerifiedBadge />
              </div>
            </Tooltip>
          </div>
        </>
      )
    }

    return null
  }

  // @todo - support video backgrounds
  const BannerImage = ({ className = '' }: { className?: string }) => (
    <AssetMedia
      src={getBannerURL(nfd)}
      alt={`${nfd.name} banner`}
      className={classNames(className, 'object-cover w-full h-full')}
      loader={galleryImageLoader}
      priority
      sizes="(max-width: 1024px) 100vw, 1024px"
      fill
      options={{
        defaultImg: '/img/nfd-banner-placeholder.jpg',
        stateBgColor: 'bg-gray-200 dark:bg-gray-850'
      }}
    />
  )

  const aspectRatioClasses = 'relative w-full aspect-[3/1]'

  return (
    <div
      className={classNames('xl:aspect-auto xl:h-[341px] xl:overflow-hidden', aspectRatioClasses)}
    >
      <BannerImage className="hidden xl:block" />
      <div className="absolute inset-0 backdrop-blur-md bg-black/30 [-webkit-transform-style:preserve-3d]">
        <div className="xl:max-w-5xl xl:mx-auto">
          <button
            type="button"
            onClick={handleClickOpen}
            className={classNames(
              isVerified ? 'relative overflow-hidden xl:rounded-br-lg' : '',
              aspectRatioClasses,
              'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500'
            )}
          >
            <BannerImage />
            {renderVerifiedBadge()}
          </button>
        </div>
      </div>
      <LightBox
        asaId={nfd.properties?.verified?.bannerasaid}
        isOpen={isOpen}
        closeModal={handleClickClose}
        imageUrl={getBannerURL(nfd)}
        imageAlt={''}
      />
    </div>
  )
}

export default memo(Banner)
