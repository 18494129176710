import { Fragment, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import { HiOutlineX, HiOutlineSearch } from 'react-icons/hi'
import Alert from 'components/Alert'
import GridListToggle from 'components/GridListToggle'
import ItemLayout from 'components/ItemLayout'
import Loading from 'components/Loading'
import NfdResult from 'components/NfdResult'
import MintSegment from './MintSegment'
import useSegments from './Segments.hooks'
import { classNames } from 'helpers/utilities'
import type { PanelProps } from 'components/DetailView/DetailView.types'
import type { NfdRecord, NfdRecordCollection } from 'api/api-client'

export default function Segments({ nfd, isOwner }: PanelProps) {
  const searchRef = useRef<HTMLInputElement>(null)

  const {
    segments,
    isInitialLoading,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetchPage,
    fetchedPages,
    activeAccount,
    isUnlocked,
    viewSetting,
    setViewSetting,
    searchText,
    handleSearchTextChange,
    resetSearchText,
    handleKeyDown,
    hasFilter,
    hasSegments
  } = useSegments({ nfd, searchRef })

  const isList = viewSetting === 'list'

  const renderResults = (results: NfdRecordCollection, pageIdx: number) => {
    if (!segments) {
      return null
    }

    return results.map((result: NfdRecord, i) => {
      if (i === results.length - 1) {
        // last element in the page

        if (pageIdx === segments.pages.length - 1 && hasNextPage) {
          // this is last page, and there is a next page

          return (
            <InView key={result.name} onChange={(inView) => inView && fetchNextPage()}>
              {({ ref }) => (
                <div ref={ref}>
                  <NfdResult
                    key={result.name}
                    view={viewSetting}
                    nfd={result}
                    activeWallet={activeAccount?.address}
                    context="browse"
                  />
                </div>
              )}
            </InView>
          )
        }
      }

      if (i === 0) {
        // first element on the page

        if (!fetchedPages.has(pageIdx)) {
          // after navigating back, this page is not fetched yet

          return (
            <InView key={nfd.name} onChange={(inView) => inView && refetchPage(pageIdx)}>
              {({ ref }) => (
                <div ref={ref}>
                  <NfdResult
                    key={result.name}
                    view={viewSetting}
                    nfd={result}
                    activeWallet={activeAccount?.address}
                    context="browse"
                  />
                </div>
              )}
            </InView>
          )
        }
      }

      return (
        <NfdResult
          key={result.name}
          view={viewSetting}
          nfd={result}
          activeWallet={activeAccount?.address}
          context="browse"
        />
      )
    })
  }

  const renderSegments = () => {
    if (isInitialLoading) {
      return (
        <div className="flex items-center justify-center py-32">
          <Loading />
        </div>
      )
    }

    if (error) {
      return <Alert type="error" title={`Error fetching segments`} error={error} />
    }

    if (!segments || !segments?.pages[0]?.results?.length) {
      const message = hasFilter
        ? 'No segments found matching search text'
        : 'No segments have been minted yet'

      return (
        <div className="py-6 px-4 sm:px-6 lg:p-8">
          <Alert type="info" title={message} />
        </div>
      )
    }

    return (
      <>
        <div className={classNames(isList ? '' : 'px-4 py-5 sm:px-6')}>
          <ItemLayout view={viewSetting}>
            {segments?.pages.map((page, pageIdx) => (
              <Fragment key={pageIdx}>{renderResults(page.results, pageIdx)}</Fragment>
            ))}
          </ItemLayout>
        </div>

        {isFetchingNextPage && (
          <div className="flex flex-col items-center justify-center py-32">
            <Loading />
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <MintSegment nfd={nfd} isOwner={isOwner} isUnlocked={isUnlocked} />
      <div className="flex-1 flex flex-col">
        <div className="bg-gray-50 flex-1 pt-2 pb-8 5xl:pt-4 dark:bg-gray-950/40">
          <div
            className={classNames(
              'p-4 sm:px-6 lg:px-8 5xl:px-10',
              !hasSegments ? 'max-w-5xl mx-auto ' : ''
            )}
          >
            <div className="bg-white shadow sm:rounded-lg overflow-hidden dark:bg-gray-850 dark:highlight">
              <div className="flex justify-between items-center px-4 py-5 sm:px-6 xl:space-x-2">
                <div className="hidden sm:inline-block">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                    Segments
                  </h3>
                  <p className="hidden xl:block mt-1 max-w-2xl text-sm text-gray-500">
                    All segments of{' '}
                    <strong className="text-gray-600 font-semibold dark:text-gray-300">
                      {nfd.name}
                    </strong>
                  </p>
                </div>
                <div className="flex items-center justify-between w-full sm:w-auto">
                  <div className="w-full sm:w-80 relative rounded-md shadow-sm text-gray-400 focus-within:text-gray-600 dark:text-gray-600 dark:focus-within:text-gray-400">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <HiOutlineSearch className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <label htmlFor="segment-search" className="sr-only">
                      Search segments
                    </label>
                    <input
                      ref={searchRef}
                      id="segment-search"
                      name="segment-search"
                      type="text"
                      value={searchText}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      autoComplete="off"
                      autoCorrect="off"
                      className="block w-full rounded-md border-gray-300 px-10 focus:border-brand-500 focus:ring-brand-500 sm:text-sm dark:bg-gray-750/75 dark:border-transparent dark:text-gray-100 dark:focus:border-brand-500 dark:placeholder-gray-500 dark:caret-gray-400"
                      placeholder="Search segments"
                      maxLength={27}
                    />
                    {searchText.length > 0 && (
                      <div className="absolute inset-y-0 right-0 flex items-center">
                        <button
                          type="button"
                          className="inline-flex items-center mr-2 border border-transparent rounded-full text-gray-400 bg-white hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 focus:text-gray-600 dark:bg-transparent dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-offset-gray-800"
                          onClick={resetSearchText}
                        >
                          <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    )}
                  </div>
                  <GridListToggle onChange={setViewSetting} value={viewSetting} />
                </div>
              </div>
              <div className="border-t border-gray-200 dark:border-gray-750/75">
                {renderSegments()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
