import { NfdRecord } from 'api/api-client'
import { isSegmentsSupported } from 'helpers/versions'
import { isValidRoot } from 'helpers/utilities'
import { Sections } from './DetailView.types'

export const isTabEnabled = (nfd: NfdRecord, tab: Sections) => {
  switch (tab) {
    case Sections.Segments:
      return isSegmentsSupported(nfd) && isValidRoot(nfd.name)
    default:
      return true
  }
}
