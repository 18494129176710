import { HiOutlineInformationCircle } from 'react-icons/hi'
import type { TooltipRenderProps } from 'react-joyride'

export const PurchasedTooltip = ({ step, skipProps, tooltipProps }: TooltipRenderProps) => (
  <div
    {...tooltipProps}
    className="w-96 bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 dark:bg-gray-800 dark:ring-gray-800/40"
    id="purchased-tooltip"
  >
    <div className="w-0 flex-1 p-4">
      <div className="flex items-start">
        <div className="flex-shrink-0 pt-0.5">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-brand-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-gray-750/75">
            <HiOutlineInformationCircle
              className="h-6 w-6 text-brand-600 dark:text-brand-400"
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="ml-3 w-0 flex-1">
          {step.title && (
            <p className="text-base font-medium text-gray-900 dark:text-gray-100">{step.title}</p>
          )}
          <div className="mt-1 text-sm text-gray-500">{step.content}</div>
        </div>
      </div>
    </div>
    <div className="flex border-l border-gray-200 dark:border-gray-750/75">
      <button
        {...skipProps}
        title="Dismiss"
        type="button"
        className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-brand-600 hover:text-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 dark:text-brand-500 dark:hover:text-brand-600"
      >
        Dismiss
      </button>
    </div>
  </div>
)
