interface GetGridLimit {
  width: number
  height: number
  columnGap?: number
  rowGap?: number
  cellMinWidth: number
  cellHeight?: number | ((width: number) => number)
}

export const getGridLimit = ({
  width,
  height,
  columnGap = 0,
  rowGap = 0,
  cellMinWidth,
  cellHeight = (width: number) => width
}: GetGridLimit) => {
  let numColumns = 0

  for (let i = 1; i * cellMinWidth + (i - 1) * columnGap < width; i++) {
    numColumns = i
  }

  const cellWidth = (width - (numColumns - 1) * columnGap) / numColumns
  const rowHeight = typeof cellHeight === 'function' ? cellHeight(cellWidth) : cellHeight

  let numRows = 0

  for (let i = 1; i * rowHeight + (i - 1) * rowGap < height; i++) {
    numRows = i
  }

  return numRows * numColumns
}

interface GetListLimit {
  height: number
  rowHeight: number
  rowGap?: number
}

export const getListLimit = ({ height, rowHeight, rowGap = 0 }: GetListLimit) => {
  let numRows = 0

  for (let i = 1; i * rowHeight + (i - 1) * rowGap < height; i++) {
    numRows = i
  }

  return numRows
}
