import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { TiArrowSortedDown } from 'react-icons/ti'
import { classNames } from 'helpers/utilities'
import { Sections } from './DetailView.types'
import { moreTabViews } from './DetailView.constants'
import { HiCheck } from 'react-icons/hi2'

interface MoreTabProps {
  isActive: boolean
  activeTab: Sections
  onTabClick: (section: Sections) => void
  isMenuOpen: boolean
  setIsMenuOpen: (isMenuOpen: boolean) => void
}

export default function MoreTab({
  isActive,
  activeTab,
  onTabClick,
  isMenuOpen,
  setIsMenuOpen
}: MoreTabProps) {
  return (
    <>
      {/* Dropdown menu for lg+ screens */}
      <Menu
        as="div"
        className="relative hidden lg:inline-block text-left pl-8 pr-4 sm:pr-6 lg:pr-8"
      >
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                className={classNames(
                  isActive
                    ? 'border-brand-500 text-gray-900 dark:text-gray-100'
                    : open
                    ? 'text-gray-700 border-gray-300 dark:text-gray-300 dark:border-gray-700'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-500 dark:hover:text-gray-300 dark:hover:border-gray-700',
                  'inline-flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm capitalize outline-brand-500'
                )}
                aria-current={isActive ? 'page' : undefined}
              >
                More
                <TiArrowSortedDown
                  className="ml-1 -mr-1 h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-4 sm:right-6 lg:right-8 z-[22] mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-850 dark:text-gray-400 dark:ring-gray-800/40">
                <div className="py-1">
                  {moreTabViews.map((section) => (
                    <Menu.Item key={section}>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active ? 'bg-brand-500 text-white dark:bg-brand-600' : '',
                            'relative block w-full pl-4 pr-9 py-2 text-left text-sm capitalize',
                            activeTab === section || active
                              ? 'text-gray-900 dark:text-gray-200'
                              : 'text-gray-700 dark:text-gray-400',
                            activeTab === section ? 'font-semibold' : 'font-normal'
                          )}
                          onClick={() => onTabClick(section)}
                        >
                          {section}

                          {activeTab === section && (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                              <HiCheck className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      {/* Opens decoupled menu modal on < lg screens */}
      <div className="lg:hidden pl-8 pr-4 sm:pr-6 lg:pr-8">
        <button
          type="button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className={classNames(
            isActive
              ? 'border-brand-500 text-gray-900 dark:text-gray-100'
              : isMenuOpen
              ? 'text-gray-700 border-gray-300 dark:text-gray-300 dark:border-gray-700'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-500 dark:hover:text-gray-300 dark:hover:border-gray-700',
            ' lg:hidden inline-flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm capitalize outline-brand-500'
          )}
          aria-current={isActive ? 'page' : undefined}
        >
          More
          <TiArrowSortedDown className="ml-1 -mr-1 h-4 w-4 text-gray-400" aria-hidden="true" />
        </button>
      </div>
    </>
  )
}
