import { Dialog, Transition } from '@headlessui/react'
import dynamic from 'next/dynamic'
import { Fragment, useEffect, useState } from 'react'
import { HiX } from 'react-icons/hi'
import Loading from 'components/Loading'
import LightboxAsset from './LightboxAsset'
import NextPreviousButton from './NextPreviousButton'
import { classNames } from 'helpers/utilities'

const AssetInfo = dynamic(() => import('./AssetInfo'))

type LightBox = {
  nfdName?: string
  asaId?: string
  isOpen: boolean
  closeModal: () => void
  imageUrl: string
  imageAlt: string
  handleAdvanceLightboxImage?: (forward: boolean) => void
  showPrevNext?: Array<'prev' | 'next'>
}

export default function LightBox({
  nfdName,
  asaId,
  isOpen,
  closeModal,
  imageUrl,
  imageAlt,
  handleAdvanceLightboxImage,
  showPrevNext
}: LightBox) {
  const [isMediaLoading, setIsMediaLoading] = useState(true)

  useEffect(() => {
    setIsMediaLoading(true)
  }, [imageUrl])

  const handleClickNextImage = (e: React.MouseEvent<HTMLButtonElement>, forward: boolean) => {
    e.stopPropagation()
    if (handleAdvanceLightboxImage) {
      handleAdvanceLightboxImage(forward)
    }
  }

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={closeModal} className="relative z-[60]" onClick={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/90" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0">
            <Dialog.Panel className="relative h-screen">
              <NextPreviousButton
                forward={false}
                handleClickNext={handleClickNextImage}
                className={classNames(
                  showPrevNext && showPrevNext.includes('prev')
                    ? 'absolute z-[90] left-0 top-1/2 -translate-y-1/2 m-6 md:m-8'
                    : 'hidden'
                )}
              />
              <NextPreviousButton
                forward={true}
                handleClickNext={handleClickNextImage}
                className={classNames(
                  showPrevNext && showPrevNext.includes('next')
                    ? 'absolute z-[90] right-0 top-1/2 -translate-y-1/2 m-6 md:m-8'
                    : 'hidden'
                )}
              />
              <div className="fixed z-[90] right-0 m-4 md:m-8">
                <button
                  type="button"
                  className="bg-transparent rounded-full p-2 text-white/80 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                  onClick={closeModal}
                >
                  <span className="sr-only">Close</span>
                  <HiX className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div className="relative h-screen flex items-center justify-center">
                <div className={classNames(isMediaLoading ? 'block opacity-20' : 'hidden')}>
                  <Loading color="text-white" />
                </div>

                <div className="fixed inset-0 sm:inset-4 lg:inset-24 z-[80]">
                  <LightboxAsset
                    src={imageUrl}
                    alt={imageAlt}
                    onLoadingComplete={() => setIsMediaLoading(false)}
                    nfdName={nfdName}
                  />
                </div>
              </div>

              {asaId && (
                <div className="fixed z-[90] bottom-0 inset-x-0">
                  <AssetInfo asaId={asaId} />
                </div>
              )}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}
