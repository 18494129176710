import { classNames } from 'helpers/utilities'

interface ListHeaderProps {
  show: boolean
  isVault?: boolean
  showAccounts?: boolean
}

export default function ListHeader({
  show,
  isVault = false,
  showAccounts = false
}: ListHeaderProps) {
  const gridTemplate = classNames(
    'md:grid-cols-[2.25rem_3fr_1fr_2fr_48px]',
    'xl:grid-cols-[2.25rem_2fr_1fr_96px_1fr_24px]',
    '2xl:grid-cols-[2.25rem_2fr_1.5fr_1fr_1fr_140px_24px]',
    '4xl:grid-cols-[2.25rem_1.5fr_1fr_0.75fr_0.75fr_140px_48px]'
  )

  return (
    <div
      className={classNames(
        show
          ? 'hidden md:grid gap-x-6 px-4 sm:px-6 xl:px-8 bg-gray-50 py-3.5 text-sm font-semibold text-gray-900 2xl:sticky 2xl:z-[19] dark:bg-gray-950 dark:text-gray-500'
          : 'hidden',
        isVault ? '2xl:top-[257px]' : '2xl:top-[177px]',
        gridTemplate
      )}
    >
      <div>Asset</div>
      <div className="flex justify-end">
        <span
          className={classNames(
            showAccounts ? 'hidden md:inline w-20 pl-2 xl:w-24 4xl:w-32' : 'hidden'
          )}
        >
          Accounts
        </span>
      </div>
      <div className="hidden 2xl:block">Creator</div>
      <div className="hidden md:block">Unit Name</div>
      <div className="hidden xl:block">Asset ID</div>
      <div className="hidden md:block text-right">Quantity</div>
      <div className="hidden md:block" />
    </div>
  )
}
