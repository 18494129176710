import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import { nfdDonationListV2, NfdRecordCollection } from 'src/api/api-client'
import { captureException } from '@sentry/nextjs'
import type { ICustomError } from 'api/customError'

export const getDonationList = async (ssr?: boolean, options?: AxiosRequestConfig) => {
  const { data } = await nfdDonationListV2({
    ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
    ...options
  })

  return data
}

type UseDonationListArgs = {
  options?: UseQueryOptions<NfdRecordCollection, ICustomError>
}

export default function useDonationList({ options }: UseDonationListArgs) {
  const result = useQuery<NfdRecordCollection, ICustomError>(
    ['donation-list'],
    () => getDonationList(),
    {
      ...options
    }
  )

  if (result.error) {
    captureException(result.error)
  }

  return result
}
