import Image from 'next/image'
import type { Marketplace } from 'types/listings'

export const AlgoXNFTIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 54.78 37.79">
    <polygon
      points="12.35 37.79 17.05 37.79 17.05 33.69 29.29 33.69 29.29 37.79 33.67 37.79 33.67 29.46 37.91 29.46 37.91 25.22 42.07 25.22 42.07 20.99 46.24 20.99 46.24 17.25 50.33 17.25 50.33 20.92 54.78 20.92 54.78 16.82 50.45 16.82 50.45 12.47 46.35 12.47 46.35 16.71 41.93 16.71 41.93 8.42 38.07 8.42 38.07 4 25.36 4 25.36 0 20.99 0 20.99 4.05 17.05 4.05 17.05 0 12.35 0 12.35 4.05 8.38 4.05 8.38 12.28 0 12.28 0 25.32 8.28 25.32 8.28 29.74 12.28 29.74 12.35 37.79"
      fill="#f51a8e"
    />
    <rect x="12.38" y="8.38" width="4.68" height="4.33" fill="#fff" />
    <rect x="12.38" y="8.38" width="4.68" height="4.33" />
  </svg>
)

export const RandGalleryIcon = () => {
  return <Image alt="randgallery icon" src="/img/icon-randgallery.png" height={20} width={20} />
}

export const ShuflIcon = () => {
  return <Image alt="shufl icon" src="/img/icon-shufl.png" height={20} width={20} />
}

export const ExaIcon = () => {
  return <Image alt="exa icon" src="/img/icon-exa.png" height={20} width={20} />
}

export const NFDomainsIcon = () => {
  return <Image alt="nfdomains icon" src="/img/icon-nfdomains.png" height={20} width={20} />
}

export const iconMap: { [key in Marketplace]: () => JSX.Element } = {
  RandGallery: RandGalleryIcon,
  AlgoXNFT: AlgoXNFTIcon,
  Shufl: ShuflIcon,
  Exa: ExaIcon,
  NFDomains: NFDomainsIcon
}

export const marketplaceMap: { [key in Marketplace]: string } = {
  RandGallery: 'Rand Gallery',
  AlgoXNFT: 'AlgoXNFT',
  Shufl: 'Shufl',
  Exa: 'Exa Market',
  NFDomains: 'NFDomains'
}
