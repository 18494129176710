import { Transition } from '@headlessui/react'
import { useState } from 'react'
import useIsSticky from 'hooks/useIsSticky'
import { classNames } from 'helpers/utilities'
import { tabs } from './Assets.constants'
import type { AssetSubNav } from './Assets.types'

interface StickyNavigationProps {
  top: number
  activeTab: AssetSubNav
  className?: string
}

export default function StickyNav({ top, activeTab, className = '' }: StickyNavigationProps) {
  const [forceHide, setForceHide] = useState(false)
  const { isSticky, sentinelRef } = useIsSticky()

  const showNav = !forceHide && isSticky

  const active = tabs.find((tab) => tab.value === activeTab)

  const scrollTo = (offset: number, callback: () => void) => {
    const fixedOffset = offset.toFixed()
    const onScroll = () => {
      if (window.scrollY.toFixed() === fixedOffset) {
        window.removeEventListener('scroll', onScroll)
        callback()
      }
    }

    window.addEventListener('scroll', onScroll)
    onScroll()
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    })
  }

  const handleClickBackToTop = () => {
    setForceHide(true)

    const rect = sentinelRef.current?.getBoundingClientRect()
    const topDistance = Math.abs((rect?.top || 0) - top)
    const scrollPosition = window.scrollY || document.documentElement.scrollTop

    const scrollTarget = scrollPosition - topDistance

    scrollTo(scrollTarget, () => setForceHide(false))
  }

  return (
    <>
      <div
        className={classNames(
          'fixed inset-x-0 lg:left-64 h-10 z-20 overflow-hidden',
          showNav ? 'pointer-events-auto' : 'pointer-events-none',
          className
        )}
        style={{ top: `${top}px` }}
      >
        <Transition
          show={showNav}
          enter="transition-transform duration-300"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition-transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div className="flex items-center justify-between h-10 px-4 sm:px-6 xl:px-8 bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-gray-800">
            <p className="flex items-center font-medium text-sm">
              {active ? (
                <span className="inline-flex items-center">
                  <active.icon className="h-5 w-5 text-brand-500" aria-hidden={true} />
                  <span className="ml-2">{active.label} Assets</span>
                </span>
              ) : (
                <span />
              )}
            </p>
            <button
              type="button"
              className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-white/0 dark:text-white dark:ring-0 dark:hover:bg-white/10"
              onClick={handleClickBackToTop}
            >
              Back to top
            </button>
          </div>
        </Transition>
      </div>

      <div ref={sentinelRef} className="absolute top-0 h-[1px]" />
    </>
  )
}
