import Alert from 'components/Alert'
import ItemLayout from 'components/ItemLayout'
import Loading from 'components/Loading'
import AssetListingPreview from 'components/DetailView/Assets/AssetListingPreview'
import ForSaleListHeader from './ForSaleListHeader'
import useForSale from './ForSale.hooks'
import { getQueryKey } from 'components/DetailView/Assets/Assets.utils'
import { AssetTypeFilter } from 'components/DetailView/Assets/Assets.types'
import { classNames } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

export interface ForSaleProps {
  nfd: NfdRecord
  searchQuery: string | null
  accounts: string[] | null
  typeFilter: Array<AssetTypeFilter> | null
  viewSetting: 'grid' | 'list'
}

export default function ForSale({
  nfd,
  searchQuery,
  accounts,
  typeFilter,
  viewSetting
}: ForSaleProps) {
  // Ignore 'tokens' filter
  const filter = typeFilter?.includes(AssetTypeFilter.Tokens)
    ? typeFilter.filter((type) => type !== 'tokens')
    : typeFilter

  const queryKey = getQueryKey('nfd-for-sale-assets', nfd, {
    searchQuery,
    accounts,
    typeFilter: filter
  })

  const { data, isLoading, error } = useForSale(queryKey)

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center py-32">
        <Loading />
      </div>
    )
  }

  if (error) {
    return (
      <div className="py-8 px-4 sm:px-6 xl:px-8">
        <Alert type="error" title={`Error fetching listings`} error={error} />
      </div>
    )
  }

  if (!data || data.length === 0) {
    return (
      <div className="py-8 px-4 sm:px-6 xl:px-8">
        <Alert type="info" title="No listings found" />
      </div>
    )
  }

  const isList = viewSetting === 'list'

  return (
    <>
      <ForSaleListHeader show={isList} />

      <ItemLayout
        view={viewSetting}
        className={classNames(isList ? '' : 'py-12 px-4 sm:px-6 xl:px-8')}
      >
        {data.map((listing) => (
          <AssetListingPreview key={listing.id} asset={listing} view={viewSetting} />
        ))}
      </ItemLayout>
    </>
  )
}
