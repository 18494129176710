import type { IconType } from 'react-icons'

interface IconBadgeProps {
  icon: IconType
  label: string | React.ReactNode
}

export default function IconBadge({ icon, label }: IconBadgeProps) {
  const Icon = ({ className = '' }) => (icon ? icon({ className }) : null)

  return (
    <div className="mt-2 mr-6 flex items-center text-sm font-medium text-gray-600/80 dark:text-gray-400">
      <Icon
        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400/80 dark:text-gray-600"
        aria-hidden="true"
      />
      {label}
    </div>
  )
}
