import { useEffect, useState } from 'react'
import { queryTypes, useQueryState } from 'hooks/useHydratedQueryState'
import { useAssetsViewStore } from 'store/index'
import { isVaultsSupported } from 'helpers/versions'
import { AssetSubNav, AssetTypeFilter } from './Assets.types'
import type { NfdRecord } from 'api/api-client'

export default function useAssets(nfd: NfdRecord) {
  // Assets tab sub navigation
  const [activeTab] = useQueryState<AssetSubNav>(
    'show',
    queryTypes.stringEnum(Object.values(AssetSubNav)).withDefault(AssetSubNav.Owned)
  )

  // Filter by search query
  const [searchQuery, setSearchQuery] = useQueryState('q', queryTypes.string)

  const searchQueryProps = {
    searchQuery,
    setSearchQuery: (searchQuery: string | null) => setSearchQuery(searchQuery, { scroll: false })
  }

  // Filter by account(s)
  const includeVault = isVaultsSupported(nfd)
  const allAccounts = [
    ...new Set([
      ...(nfd.caAlgo || []),
      ...(includeVault && nfd?.nfdAccount ? [nfd?.nfdAccount] : []),
      ...(nfd.owner ? [nfd.owner] : [])
    ])
  ]

  const [accounts, setAccounts] = useQueryState('accounts', {
    parse: (queryString: string) => {
      return queryString
        .split(',')
        .map((query) => {
          if (allAccounts.includes(query)) {
            return query
          }
          return null
        })
        .filter((value) => value !== null && value !== undefined) as string[]
    }
  })

  const accountsProps = {
    allAccounts,
    accounts,
    setAccounts: (accounts: string[] | null) => setAccounts(accounts, { scroll: false }),
    setAccountsAsync: async (accounts: string[] | null) => {
      await setAccounts(accounts, { scroll: false })
    }
  }

  // Filter by asset type(s)
  const [typeFilter, setTypeFilter] = useQueryState<Array<AssetTypeFilter>>('filter', {
    parse: (queryString: string) => {
      return queryString
        .split(',')
        .map((query) => {
          const asEnum = query as unknown as AssetTypeFilter
          if (Object.values(AssetTypeFilter).includes(asEnum)) {
            return asEnum
          }
          return null
        })
        .filter((value) => value !== null && value !== undefined) as Array<AssetTypeFilter>
    },
    serialize: (value: Array<AssetTypeFilter>) => value?.join(',')
  })

  const typeFilterProps = {
    typeFilter,
    setTypeFilter: async (typeFilter: Array<AssetTypeFilter> | null) => {
      await setTypeFilter(typeFilter, { scroll: false })
    }
  }

  const [isReady, setIsReady] = useState(false)
  useEffect(() => setIsReady(true), [])

  const viewSetting = useAssetsViewStore((state) => state.view)
  const setViewSetting = useAssetsViewStore((state) => state.setView)

  return {
    isReady,
    activeTab,
    viewSetting,
    setViewSetting,
    ...searchQueryProps,
    ...accountsProps,
    ...typeFilterProps
  }
}
