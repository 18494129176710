import { AiFillBank } from 'react-icons/ai'
import { TbHandStop } from 'react-icons/tb'
import Alert from 'components/Alert'
import LoadingDots from 'components/LoadingDots'
import VaultModal from 'components/NfdResult/VaultInfo/VaultModal'
import useVaultAssets from 'api/hooks/useVaultAssets'
import { classNames } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

interface WarningProps {
  nfd: NfdRecord
  className?: string
}

export default function Warning({ nfd, className = '' }: WarningProps) {
  const showWarning = nfd.state === 'owned' // not for sale yet (hidden if managing sale)
  const showRootWarning = nfd.properties?.internal?.segmentLocked === '0'

  const { showVaultInfo: showVaultWarning, assets, isLoading } = useVaultAssets(nfd)

  const renderMetadataWarningMessage = () => {
    return (
      <span className="block">
        Listing for sale will{' '}
        <strong className="font-semibold text-gray-800 dark:text-gray-300">
          clear all metadata
        </strong>
        , including avatar and banner&nbsp;image.
      </span>
    )
  }

  const renderRootWarningMessage = () => {
    return (
      <span className="block">
        This{' '}
        <strong className="font-semibold text-gray-800 dark:text-gray-300">unlocked root</strong>{' '}
        will also become{' '}
        <strong className="font-semibold text-gray-800 dark:text-gray-300">locked</strong>{' '}
        upon&nbsp;listing.
      </span>
    )
  }

  const renderVaultWarning = () => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center py-12">
          <LoadingDots />
        </div>
      )
    }

    if (showVaultWarning) {
      return (
        <Alert
          type="custom"
          alignIcon="top"
          theme={{
            bgColor: 'bg-red-50',
            icon: TbHandStop,
            iconColor: 'text-red-400',
            darkIcon: TbHandStop,
            darkIconColor: 'text-red-500',
            accentColor: 'border-red-500'
          }}
        >
          <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
            VAULT CONTAINS ASSETS!
          </h3>
          <p className="mt-2 text-sm text-gray-700 first-letter:capitalize dark:text-gray-400">
            This NFD&apos;s vault contains{' '}
            <strong className="font-semibold text-gray-800 dark:text-gray-300">
              {assets?.length} assets
            </strong>
            . Listing for sale will{' '}
            <strong className="font-semibold text-gray-800 dark:text-gray-300">
              transfer ownership
            </strong>{' '}
            of these assets to the buyer.
          </p>
          <VaultModal
            nfd={nfd}
            assets={assets || []}
            className="mt-4 mb-2 inline-flex items-center justify-center shadow-sm font-medium group px-3.5 py-1.5 text-sm rounded-md border border-transparent text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-50 focus:ring-brand-500 disabled:opacity-50 disabled:bg-brand-500 dark:bg-brand-600 dark:hover:bg-brand-500 dark:focus:ring-offset-gray-900 dark:ring-gray-100 dark:disabled:opacity-25 dark:disabled:bg-brand-600"
          >
            <AiFillBank className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            View Assets
          </VaultModal>
        </Alert>
      )
    }

    return null
  }

  if (!showWarning) return null

  return (
    <div className={classNames('space-y-4', className)}>
      {renderVaultWarning()}
      <Alert
        type="warning"
        title="Metadata will be lost"
        message={renderMetadataWarningMessage()}
        alignIcon="top"
      />
      {showRootWarning && (
        <Alert
          type="warning"
          title="Root will be locked"
          message={renderRootWarningMessage()}
          alignIcon="top"
        />
      )}
    </div>
  )
}
