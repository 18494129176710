import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { NFDActivity, NFDActivityChanges, NfdRecord } from 'api/api-client'
import { convertMicroalgosToAlgos } from 'helpers/utilities'
import { meetsMinimumVersion } from 'helpers/versions'
import { FilteredChanges, HistoryData, HistoryType } from './History.types'

dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(utc)

export class HistoryFactory {
  private handlers: HistoryHandler[] = [
    new MintedV3Handler(),
    new RenewedByOwnerHandler(),
    new PurchasedExpiredHandler(),
    new MintedHandler(),
    new AuctionHandler(),
    new UserDefinedPropertiesHandler(),
    new VerifiedPropertiesHandler(),
    new MixedPropertiesHandler(),
    new InitialClaimHandler(),
    new ClaimedHandler(),
    new ListedForSaleHandler(),
    new CancelledSaleHandler(),
    new ContractUpgradeHandler()
  ]

  createHistoryItems(activity: NFDActivity, nfd: NfdRecord): HistoryData[] {
    const { changes, timeChanged } = activity

    if (!changes) return []

    for (const handler of this.handlers) {
      if (handler.condition(changes)) {
        return handler.createHistoryItems(changes, nfd, timeChanged)
      }
    }

    return []
  }
}

const typeMap: Record<string, HistoryType> = {
  u: HistoryType.Update,
  v: HistoryType.Verify
}

abstract class HistoryHandler {
  abstract condition(changes: NFDActivityChanges): boolean
  abstract createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[]

  protected getPropertyChanges(changes: NFDActivityChanges): FilteredChanges {
    return Object.entries(changes).filter(([key]) => !key.startsWith('i'))
  }

  protected getType(key: string, value: string): HistoryType {
    if (value === '') return HistoryType.Remove
    return typeMap[key[0]] || HistoryType.Internal
  }

  protected getPrice(price: string, nfd: NfdRecord): number {
    let basePrice = Number(price)
    if (nfd.saleType !== 'auction') {
      const isVersion2 = meetsMinimumVersion(nfd, '2')
      const carryCost = isVersion2 ? 2500000 : 5000000
      basePrice += carryCost
    }
    return convertMicroalgosToAlgos(basePrice)
  }
}

class MintedV3Handler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    return changes['i:minting'] === 'registry'
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    return [
      {
        type: HistoryType.Claim,
        text: 'by',
        isParent: true,
        date: timeChanged,
        address: changes['i:owner'],
        isParentGroup: true
      },
      {
        type: HistoryType.Mint,
        text: 'for',
        price: this.getPrice(changes['i:sellamt'], nfd),
        isParent: true,
        date: timeChanged,
        isParentGroup: true
      }
    ]
  }
}

class RenewedByOwnerHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    return changes['i:expirationTime'] !== undefined && changes['i:owner'] === undefined
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    const changeTime = dayjs.utc(Number(changes['i:timeChanged']) * 1000)
    const expirationTime = dayjs.utc(Number(changes['i:expirationTime']) * 1000)
    const duration = dayjs.duration(expirationTime.diff(changeTime))
    return [
      {
        type: HistoryType.Renew,
        text: `for ${duration.humanize()} by owner`,
        isParent: true,
        date: timeChanged
      }
    ]
  }
}

class PurchasedExpiredHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    return changes['i:expirationTime'] !== undefined && changes['i:owner'] !== undefined
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    const address = changes['i:owner']
    const changeTime = dayjs.utc(Number(changes['i:timeChanged']) * 1000)
    const expirationTime = dayjs.utc(Number(changes['i:expirationTime']) * 1000)
    const duration = dayjs.duration(expirationTime.diff(changeTime))
    return [
      {
        type: HistoryType.Renew,
        text: `for ${duration.humanize()} by`,
        isParent: true,
        date: timeChanged,
        address
      }
    ]
  }
}

class MintedHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    return changes['i:minting'] === '1' && changes['i:saleType'] === 'buyItNow'
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    return [
      {
        type: HistoryType.Mint,
        text: '',
        isParent: true,
        date: timeChanged
      }
    ]
  }
}

class AuctionHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    return changes['i:saleType'] === 'auction'
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    return [
      {
        type: HistoryType.Auction,
        text: '',
        isParent: true,
        date: timeChanged
      }
    ]
  }
}

class UserDefinedPropertiesHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    const propertyChanges = this.getPropertyChanges(changes)
    return (
      propertyChanges.length > 0 &&
      propertyChanges.every(([key]) => key.startsWith('u')) &&
      changes['i:sellamt'] === undefined
    )
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    const propertyChanges = this.getPropertyChanges(changes)
    const numOfProperties = propertyChanges.length

    const historyItems: HistoryData[] = [
      {
        type: HistoryType.Update,
        text: `${numOfProperties > 1 ? `${numOfProperties} ` : ''}user defined propert${
          numOfProperties > 1 ? 'ies' : 'y'
        }`,
        isParent: true,
        date: timeChanged
      }
    ]

    propertyChanges.forEach(([key, value]) => {
      const formattedKey = key.startsWith('v:caAlgo') ? 'Address' : key.slice(2)
      historyItems.push({
        type: this.getType(key, value),
        text: `${key}: ${value}`,
        key: formattedKey,
        value,
        isParent: false,
        date: timeChanged,
        price: ''
      })
    })

    return historyItems
  }
}

class VerifiedPropertiesHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    const propertyChanges = this.getPropertyChanges(changes)
    return (
      propertyChanges.length > 0 &&
      propertyChanges.every(([key]) => key.startsWith('v')) &&
      changes['i:sellamt'] === undefined
    )
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    const propertyChanges = this.getPropertyChanges(changes)
    const numOfProperties = propertyChanges.length
    const parentText = propertyChanges.every(([key]) => key.startsWith('v:caAlgo'))
      ? `${numOfProperties > 1 ? `${numOfProperties} ` : ''}wallet address${
          numOfProperties > 1 ? 'es' : ''
        }`
      : `${numOfProperties > 1 ? `${numOfProperties} ` : ''}propert${
          numOfProperties > 1 ? 'ies' : 'y'
        }`

    const historyItems: HistoryData[] = [
      {
        type: HistoryType.Verify,
        text: parentText,
        isParent: true,
        date: timeChanged
      }
    ]

    propertyChanges.forEach(([key, value]) => {
      const isAddress = key.startsWith('v:caAlgo')
      if (isAddress) {
        const addresses = value.split(',')
        addresses.forEach((address, index) => {
          historyItems.push({
            type: HistoryType.Address,
            text: `${key}: ${value}`,
            key: index === 0 ? 'Deposit Address' : 'Linked Address',
            value: address,
            isParent: false,
            date: timeChanged,
            price: ''
          })
        })
      } else {
        historyItems.push({
          type: this.getType(key, value),
          text: `${key}: ${value}`,
          key: key.slice(2),
          value,
          isParent: false,
          date: timeChanged,
          price: ''
        })
      }
    })

    return historyItems
  }
}

class MixedPropertiesHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    const propertyChanges = this.getPropertyChanges(changes)
    return (
      propertyChanges.length > 0 &&
      propertyChanges.some(([key]) => key.startsWith('v')) &&
      propertyChanges.some(([key]) => key.startsWith('u')) &&
      changes['i:sellamt'] === undefined
    )
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    const propertyChanges = this.getPropertyChanges(changes)
    const numOfProperties = propertyChanges.length

    const historyItems: HistoryData[] = [
      {
        type: HistoryType.Many,
        text: `${numOfProperties} properties`,
        isParent: true,
        date: timeChanged
      }
    ]

    propertyChanges.forEach(([key, value]) => {
      const formattedKey = key.startsWith('v:caAlgo') ? 'Address' : key.slice(2)

      historyItems.push({
        type: this.getType(key, value),
        text: `${key}: ${value}`,
        key: formattedKey,
        value,
        isParent: false,
        date: timeChanged,
        price: ''
      })
    })

    return historyItems
  }
}

class InitialClaimHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    return (
      changes['i:minting'] === '' &&
      changes['i:highestSoldAmt'] !== undefined &&
      changes['i:highestSoldAmt'] !== ''
    )
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    const address = changes['i:owner']
    return [
      {
        type: HistoryType.Claim,
        text: 'by',
        isParent: true,
        date: timeChanged,
        price: this.getPrice(changes['i:highestSoldAmt'], nfd),
        address
      }
    ]
  }
}

class ClaimedHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    return (
      changes['i:minting'] === undefined &&
      changes['i:timePurchased'] !== undefined &&
      changes['i:timePurchased'] !== '' &&
      changes['i:owner'] !== undefined &&
      changes['i:owner'] !== ''
    )
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    const address = changes['i:owner']
    return [
      {
        type: HistoryType.Purchase,
        text: 'by',
        isParent: true,
        date: timeChanged,
        address
      }
    ]
  }
}

class ListedForSaleHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    return (
      changes['i:minting'] === undefined &&
      changes['i:sellamt'] !== undefined &&
      changes['i:sellamt'] !== ''
    )
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    return [
      {
        type: HistoryType.Sell,
        text: '',
        isParent: true,
        date: timeChanged,
        price: this.getPrice(changes['i:sellamt'], nfd)
      }
    ]
  }
}

class CancelledSaleHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    return (
      changes['i:owner'] === undefined &&
      changes['i:sellamt'] !== undefined &&
      changes['i:sellamt'] === ''
    )
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    return [
      {
        type: HistoryType.CancelSell,
        text: '',
        isParent: true,
        date: timeChanged
      }
    ]
  }
}

class ContractUpgradeHandler extends HistoryHandler {
  condition(changes: NFDActivityChanges): boolean {
    return changes['i:ver'] !== undefined
  }

  createHistoryItems(
    changes: NFDActivityChanges,
    nfd: NfdRecord,
    timeChanged: string
  ): HistoryData[] {
    return [
      {
        type: HistoryType.Upgrade,
        text: 'to version ' + changes['i:ver'],
        isParent: true,
        date: timeChanged
      }
    ]
  }
}
