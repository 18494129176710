import dayjs from 'dayjs'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { RiShoppingBasket2Line } from 'react-icons/ri'
import AssetMedia from 'components/AssetMedia'
import Tooltip from 'components/ReactTooltip'
import TextAvatar from 'components/TextAvatar'
import UserThumbnail from 'components/UserThumbnail'
import AssetListingDetails from './AssetListingDetails'
import AssetListingMenu from './AssetListingMenu'
import { renderPrice, showTextAvatar } from './AssetListingPreview.utils'
import { iconMap, marketplaceMap } from './AssetListingPreview.constants'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { classNames } from 'helpers/utilities'
import type { NftListing } from 'types/listings'

interface AssetListingPreviewProps {
  asset: NftListing
  view: 'grid' | 'list'
}

function AssetListingPreview({ asset, view }: AssetListingPreviewProps) {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  const isList = view === 'list'
  const isMultiBasket = !!asset.basketSize && asset.basketSize > 1

  const renderMenu = () => {
    return <AssetListingMenu asset={asset} handleClickViewDetails={setIsDetailsOpen} />
  }

  const renderGridInfo = () => {
    const wrapperClassName = classNames(isList ? 'hidden' : 'mt-3')

    return (
      <div className={wrapperClassName}>
        <h3 className="truncate text-sm font-medium text-gray-900 dark:text-gray-100">
          {asset.name}
        </h3>
        <div className="mt-1.5 h-7 flex items-center justify-between text-sm">
          <div className="w-4/5 flex items-center">
            <UserThumbnail
              address={asset.creatorAddress}
              size="sm"
              className="text-gray-400 truncate"
              forceAddressFallback={asset.unitName === 'NFD'}
              fallbackClassName="text-gray-400 truncate dark:text-gray-400/80"
              wrapperClassName="flex items-center min-w-0"
              staleTime={Infinity}
            />
          </div>
          {renderMenu()}
        </div>

        {/* footer */}
        <div className="mt-3">
          <a
            href={asset.marketplaceLink}
            target="_blank"
            rel="noreferrer noopener"
            className="text-gray-900 hover:text-gray-500 bg-gray-100 p-1 rounded-full inline-block outline-brand-500 group dark:bg-gray-800 dark:text-gray-300 dark:hover:text-brand-500"
            data-tooltip-id={`listing-${asset.assetId}`}
            data-tooltip-content={`${asset.auction ? 'Bid' : 'Buy'} on ${
              marketplaceMap[asset.marketplace]
            }`}
          >
            <div className="flex divide-x divide-gray-300 dark:divide-white/10">
              <div className="flex justify-center items-center px-2">
                {iconMap[asset.marketplace]()}
              </div>
              {asset.auction && (
                <div className="flex items-center font-medium px-2">
                  {dayjs(asset.auction.endingTime).fromNow(true)}
                </div>
              )}
              <div className="flex justify-center items-center pl-2.5 pr-2">
                {renderPrice(asset)}
              </div>
              {isMultiBasket && (
                <div className="flex items-center justify-center text-gray-500 px-2 group-hover:text-gray-500 dark:text-gray-400/80 dark:group-hover:text-brand-500">
                  <RiShoppingBasket2Line className="mr-0.5" />
                  <span className="text-gray-700 dark:text-gray-300 group-hover:text-gray-500 dark:group-hover:text-brand-500">
                    {asset.basketSize}
                  </span>
                </div>
              )}
            </div>
          </a>
          <Tooltip id={`listing-${asset.assetId}`} />
        </div>
      </div>
    )
  }

  const renderListInfo = () => {
    const gridTemplate = classNames(
      'grid-cols-1',
      'md:grid-cols-[1fr_1fr_1fr_48px]',
      'xl:grid-cols-[1fr_1fr_1fr_1fr_1fr_24px]'
    )

    const wrapperClassName = classNames(isList ? 'flex-1 grid gap-x-6' : 'hidden', gridTemplate)

    return (
      <div className={wrapperClassName}>
        <div className="flex items-center min-w-0">
          <div className="flex-1 text-sm 4xl:text-base 4xl:pl-1 truncate">
            <span
              className="cursor-pointer hover:text-brand-500"
              onClick={() => setIsDetailsOpen(true)}
            >
              {asset.name}
            </span>
            <dl className="font-normal md:hidden">
              <dt className="sr-only">Price</dt>
              <dd className="text-gray-700 dark:text-gray-400">
                <div className="flex items-center gap-x-2">
                  {iconMap[asset.marketplace]()}
                  {renderPrice(asset)}
                </div>
              </dd>
            </dl>
          </div>
          <div className="flex items-center pl-2 md:hidden">{renderMenu()}</div>
        </div>
        <div className="hidden md:flex items-center text-sm">{renderPrice(asset)}</div>
        <div className="hidden md:flex items-center text-sm">
          <div className="flex items-center gap-x-2">
            {iconMap[asset.marketplace]()}
            {marketplaceMap[asset.marketplace]}
          </div>
        </div>
        <div className="hidden xl:flex items-center truncate">
          <UserThumbnail
            address={asset.creatorAddress}
            size="sm"
            className="text-gray-400 truncate"
            forceAddressFallback={asset.unitName === 'NFD'}
            fallbackClassName="text-gray-400 truncate dark:text-gray-400/80"
            wrapperClassName="flex items-center min-w-0"
            staleTime={Infinity}
          />
        </div>
        <div className="hidden xl:flex items-center text-sm text-gray-500">{asset.assetId}</div>
        <div className="hidden md:flex items-center justify-center -my-1.5">{renderMenu()}</div>
      </div>
    )
  }

  const renderPreview = () => {
    if (!isList && showTextAvatar(asset)) {
      return (
        <TextAvatar
          name={asset.name}
          imgSrc={asset.imageUrl}
          className="absolute inset-0 object-cover w-full h-full"
        />
      )
    }

    return (
      <AssetMedia
        src={asset.imageUrl}
        alt={asset.assetId}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="(max-width: 640px) 100vw, 360px"
        fill
        options={{
          stateBgColor: 'bg-gray-500/5 dark:bg-gray-500/5'
        }}
        videoJsOptions={{
          preload: isMobile ? 'auto' : 'metadata',
          controls: isMobile,
          fluid: true,
          fill: true
        }}
      />
    )
  }

  return (
    <>
      <div
        key={asset.assetId}
        className={classNames(isList ? 'flex items-center px-4 sm:px-6 xl:px-8' : '')}
      >
        <div className={classNames(isList ? 'py-3 pr-3' : '')}>
          <button
            type="button"
            className={classNames(
              isList
                ? 'w-12 rounded-full'
                : 'w-full rounded-lg hover:scale-[1.02] transition hover:shadow-md origin-bottom [-webkit-transform-style:preserve-3d]',
              'relative aspect-square overflow-hidden focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'
            )}
            onClick={() => setIsDetailsOpen(true)}
          >
            {renderPreview()}
          </button>
        </div>
        {renderGridInfo()}
        {renderListInfo()}
      </div>

      <AssetListingDetails open={isDetailsOpen} setOpen={setIsDetailsOpen} asset={asset} />
    </>
  )
}

function propsAreEqual(prevProps: AssetListingPreviewProps, nextProps: AssetListingPreviewProps) {
  const { asset, view } = nextProps

  return prevProps.asset.assetId === asset.assetId && prevProps.view === view
}

export default React.memo(AssetListingPreview, propsAreEqual)
