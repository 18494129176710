import Link from 'next/link'
import * as React from 'react'
import { HiCheckCircle, HiKey, HiLockClosed, HiLockOpen, HiOutlineX } from 'react-icons/hi'
import { NfdRecord } from 'api/api-client'
import SegmentsIcon from 'components/icons/Segments'
import MintDialog from 'components/mint/MintDialog'
import PriceTag from 'components/PriceTag'
import UpgradeRequired from 'components/UpgradeRequired'
import UsdPrice from 'components/UsdPrice'
import { classNames } from 'helpers/utilities'
import { isSegmentsSupported } from 'helpers/versions'
import useDebounce from 'hooks/useDebounce'
import IconBadge from './IconBadge'
import PriceBadge from './PriceBadge'
import { useGetQuote } from 'api/hooks/useGetQuote'
import { useWallet } from '@txnlab/use-wallet-react'
import useWalletBalance from 'hooks/useWalletBalance'
import { useHydrated } from 'react-hydration-provider'
import Alert from 'components/Alert'

interface MintSegmentProps {
  nfd: NfdRecord
  isUnlocked: boolean
  isOwner: boolean
}

export default function MintSegment({ nfd, isUnlocked, isOwner }: MintSegmentProps) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [segmentName, setSegmentName] = React.useState('')

  const debouncedQuery = useDebounce(segmentName, 300)

  const isQueryEnabled = React.useMemo(
    () =>
      debouncedQuery.length > 0 &&
      debouncedQuery.length <= 27 &&
      debouncedQuery === segmentName &&
      debouncedQuery.match(/^[a-z0-9]+$/gm) !== null,
    [debouncedQuery, segmentName]
  )

  const { activeAddress } = useWallet()
  const { walletAvailableBalance } = useWalletBalance()

  const availableBalance = parseFloat(walletAvailableBalance || '0')
  const mintSearchEnabled = availableBalance >= 0.1

  const priceQuery = useGetQuote({
    name: `${debouncedQuery}.${nfd.name}`,
    params: {
      buyer: activeAddress || ''
    },
    options: {
      enabled: isQueryEnabled,
      refetchOnWindowFocus: false,
      retry: false
    }
  })

  const result = priceQuery.data

  const isLoading = React.useMemo(() => {
    return priceQuery.isInitialLoading || debouncedQuery !== segmentName
  }, [debouncedQuery, segmentName, priceQuery.isInitialLoading])

  const isAvailable = React.useMemo(() => {
    return priceQuery.data?.exists === false
  }, [priceQuery.data])

  const isMintEnabled = React.useMemo(() => {
    if (isLoading || priceQuery.isError) {
      return false
    }

    return isAvailable
  }, [isAvailable, isLoading, priceQuery.isError])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lowercase = event.target.value.toLowerCase()
    const filtered = lowercase.replace(/[^a-z0-9]/gi, '')
    setSegmentName(filtered)
  }

  const resetInput = () => {
    setSegmentName('')
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      resetInput()
    }
  }

  const hydrated = useHydrated()

  const renderWarning = () => {
    if (!hydrated) {
      return null
    }

    if (!activeAddress) {
      return (
        <Alert
          type="warning"
          className="mt-8 sm:min-w-96 xl:my-8"
          data-cy="mint-wallet-not-connected"
        >
          <p className="text-sm text-amber-700 dark:text-gray-300">Wallet not connected</p>
        </Alert>
      )
    }

    if (!mintSearchEnabled) {
      return (
        <Alert
          type="warning"
          className="mt-8 sm:min-w-96 xl:my-8"
          data-cy="mint-wallet-not-connected"
        >
          <p className="text-sm text-amber-700 dark:text-gray-300">
            Minimum balance of 0.1 ALGO required
          </p>
        </Alert>
      )
    }

    return null
  }

  const renderMessage = () => {
    if (isUnlocked && isOwner) {
      return (
        <span>
          You have{' '}
          <Link
            href={`/manage/${nfd.name}?view=segments`}
            className="text-gray-700 font-semibold hover:text-brand-500 dark:text-brand-500 dark:hover:text-brand-600 dark:font-medium"
          >
            unlocked
          </Link>{' '}
          segmenting for <strong className="text-gray-500 font-semibold">{nfd.name}</strong>.
        </span>
      )
    }

    if (isUnlocked) {
      return (
        <span>
          The owner of <strong className="text-gray-500 font-semibold">{nfd.name}</strong> has
          unlocked segmenting.
        </span>
      )
    }

    return (
      <span>
        Mint access is restricted to the NFD owner until it is{' '}
        {isOwner ? (
          <Link
            href={`/manage/${nfd.name}?view=segments`}
            className="text-gray-700 font-semibold hover:text-brand-500 dark:text-brand-500 dark:hover:text-brand-600 dark:font-medium"
          >
            unlocked
          </Link>
        ) : (
          'unlocked'
        )}
        .
      </span>
    )
  }

  const renderPrice = () => {
    if (priceQuery.isError) {
      return <div className="text-red-500 my-2">Error</div>
    }

    if (isAvailable && result?.price) {
      return (
        <span className="flex items-center">
          <PriceTag price={result.price} className="bg-gray-200 xl:text-base" />
          <UsdPrice price={result.price} className="text-right ml-1" />
        </span>
      )
    }

    return <div className="text-red-500 font-medium">Unavailable</div>
  }

  const renderQueryStatus = () => {
    if (isLoading) {
      return <p className="text-center text-gray-500 font-medium my-1">Checking&hellip;</p>
    }

    const fullSegmentName = `${debouncedQuery}.${nfd.name}`

    return (
      <>
        {isAvailable ? (
          <div className="min-w-0 font-semibold my-1 mr-4 flex items-center flex-nowrap">
            <p className="truncate">{fullSegmentName}</p>
            <HiCheckCircle className="inline-flex min-w-[16px] h-4 w-4 ml-1.5 text-green-500" />
          </div>
        ) : (
          <Link
            href={`/name/${fullSegmentName}`}
            className="my-1 truncate text-gray-900 font-semibold hover:text-brand-500 outline-brand-500"
          >
            {fullSegmentName}
          </Link>
        )}
        {renderPrice()}
      </>
    )
  }

  const renderLockBadge = () => {
    const badge = isUnlocked
      ? { icon: HiLockOpen, label: 'Unlocked' }
      : { icon: HiLockClosed, label: 'Locked' }

    return <IconBadge icon={badge.icon} label={badge.label} />
  }

  const renderOwnerBadge = () => {
    if (!isOwner) {
      return null
    }

    return <IconBadge icon={HiKey} label="Owner" />
  }

  const renderPriceBadge = () => {
    if (!isOwner && !isUnlocked) {
      return null
    }
    return <PriceBadge nfd={nfd} />
  }

  const renderCountBadge = () => {
    const count = Number(nfd.properties?.internal?.segmentCount)

    if (!count) {
      return null
    }

    return <IconBadge icon={SegmentsIcon} label={`${count} total`} />
  }

  const renderSearch = () => {
    const hideSearch = (!isOwner && !isUnlocked) || !mintSearchEnabled

    if (!mintSearchEnabled) {
      return renderWarning()
    }

    if (!hideSearch && !isSegmentsSupported(nfd)) {
      return (
        <div className="sm:w-full sm:max-w-md xl:ml-8">
          <UpgradeRequired nfd={nfd} isOwner={isOwner} compact action={<>enable&nbsp;minting</>} />
        </div>
      )
    }

    return (
      <div className="mt-6 sm:w-full sm:max-w-md xl:mt-7 xl:ml-8">
        <div className={classNames(hideSearch ? 'invisible' : '', 'mb-2 sm:flex')}>
          <label htmlFor="segment-name" className="sr-only">
            Segment name
          </label>
          <div className="relative w-full rounded-md shadow-sm">
            <input
              ref={inputRef}
              id="segment-name"
              name="segment-name"
              type="text"
              value={segmentName}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              className="w-full rounded-md border-white shadow-sm focus:border-white pl-5 pr-10 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-brand-500 dark:bg-gray-850 dark:border-transparent dark:text-gray-100 dark:focus:ring-1 dark:focus:ring-offset-1 dark:focus:ring-brand-500 dark:focus:border-brand-500 dark:placeholder-gray-500 dark:caret-gray-400"
              placeholder="Enter segment name"
              maxLength={27}
            />
            {segmentName.length > 0 && (
              <div className="absolute inset-y-0 right-2 flex items-center">
                <button
                  type="button"
                  className="inline-flex items-center p-1 border border-transparent rounded-full text-gray-400 bg-white hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 focus:text-gray-600 dark:bg-transparent dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-brand-500 dark:focus:text-gray-400"
                  onClick={resetInput}
                >
                  <HiOutlineX className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            )}
          </div>
          <MintDialog
            name={`${segmentName}.${nfd.name}`}
            disabled={!isQueryEnabled || !isMintEnabled}
          >
            <button className="mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-brand-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-brand-500 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0 disabled:opacity-50 disabled:bg-brand-500 dark:disabled:opacity-25">
              Mint
            </button>
          </MintDialog>
        </div>
        <div className="px-1">
          <div
            className={classNames(
              segmentName === '' ? 'hidden xl:flex xl:invisible' : 'flex',
              'items-center justify-between flex-wrap text-sm'
            )}
          >
            {renderQueryStatus()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-950">
      <div className="max-w-5xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
        <div className="xl:flex xl:items-center">
          <div className="xl:w-0 xl:flex-1">
            <h2 className="text-xl font-bold tracking-tight text-gray-900 md:text-2xl dark:text-gray-100">
              Mint a segment
            </h2>
            <p className="mt-1 max-w-3xl text-sm text-gray-500 md:text-base">{renderMessage()}</p>
            <div className="mt-1 flex flex-wrap">
              {renderLockBadge()}
              {renderOwnerBadge()}
              {renderPriceBadge()}
              {renderCountBadge()}
            </div>
          </div>
          {renderSearch()}
        </div>
      </div>
    </div>
  )
}
