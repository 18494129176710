import * as React from 'react'
import { NfdRecord } from 'api/api-client'
import useGetActivity from 'api/hooks/useGetActivity'
import { HistoryFactory } from './History.handlers'
import { HistoryData } from './History.types'

function historyReducer(
  state: HistoryData[][],
  action: { type: 'ADD_SERIES' | 'CLEAR_HISTORY'; payload?: HistoryData[] }
): HistoryData[][] {
  switch (action.type) {
    case 'CLEAR_HISTORY':
      return []
    case 'ADD_SERIES':
      return [...state, action.payload!]
    default:
      return state
  }
}

export function useHistory(nfd: NfdRecord) {
  const [history, dispatch] = React.useReducer(historyReducer, [])

  const { data: activityData } = useGetActivity({
    params: {
      name: [nfd.name],
      limit: 50
    }
  })

  React.useEffect(() => {
    if (!activityData) return

    dispatch({ type: 'CLEAR_HISTORY' })

    const factory = new HistoryFactory()
    activityData.forEach((activity) => {
      const series = factory.createHistoryItems(activity, nfd)
      if (series.length) {
        dispatch({ type: 'ADD_SERIES', payload: series })
      }
    })
  }, [activityData, nfd])

  const historySorted = React.useMemo(() => {
    return [...history].sort((a, b) => {
      const dateA = new Date(a[0]?.date || 0).valueOf()
      const dateB = new Date(b[0]?.date || 0).valueOf()
      return dateB - dateA
    })
  }, [history])

  return historySorted
}
