import Badge from './Badge'
import useMeritBadges from './Badges.hooks'
import type { NfdRecord } from 'api/api-client'
import useNfdPreferences from 'hooks/useNfdPreferences'

export default function Badges({ nfd }: { nfd: NfdRecord }) {
  const { badges, isLoading, isError } = useMeritBadges({ name: nfd.name })
  const { checkIsBadgeEnabled } = useNfdPreferences(nfd)

  if (isLoading || isError || badges.length === 0) {
    return null
  }

  return (
    <div className="flex md:justify-end w-full mb-5 md:pl-8">
      <div className="flex flex-wrap md:flex-col w-full">
        {badges.map(
          (badge) => checkIsBadgeEnabled(badge.type) && <Badge key={badge.type} {...badge} />
        )}
      </div>
    </div>
  )
}
