import { BiSolidWallet } from 'react-icons/bi'
import { CgList, CgPlayListRemove } from 'react-icons/cg'
import {
  HiDatabase,
  HiTag,
  HiBadgeCheck,
  HiInformationCircle,
  HiBackspace,
  HiOutlineLockClosed
} from 'react-icons/hi'
import { HiSparkles } from 'react-icons/hi2'
import { MdOutlineStorefront } from 'react-icons/md'
import { RiAuctionFill } from 'react-icons/ri'
import { TbClockPlus, TbFlag3Filled } from 'react-icons/tb'
import { clsxMerge } from 'helpers/utilities'
import { HistoryType } from './History.types'

export enum IconColor {
  Brand = 'brand',
  Gray = 'gray'
}

interface IconProps {
  isParent?: boolean
  type: HistoryType
  size?: 'sm' | 'lg'
  color?: IconColor
}

const iconMap = new Map([
  [HistoryType.Many, CgList],
  [HistoryType.Mint, HiSparkles],
  [HistoryType.Purchase, MdOutlineStorefront],
  [HistoryType.Claim, TbFlag3Filled],
  [HistoryType.Update, HiDatabase],
  [HistoryType.Auction, RiAuctionFill],
  [HistoryType.Verify, HiBadgeCheck],
  [HistoryType.Remove, CgPlayListRemove],
  [HistoryType.Internal, HiInformationCircle],
  [HistoryType.Sell, HiTag],
  [HistoryType.CancelSell, HiBackspace],
  [HistoryType.Upgrade, HiOutlineLockClosed],
  [HistoryType.Address, BiSolidWallet],
  [HistoryType.Renew, TbClockPlus]
])

export default function Icon({ type, size = 'lg', isParent, color = IconColor.Gray }: IconProps) {
  const iconContainerClasses = clsxMerge(
    size === 'sm' ? 'h-5 w-5' : 'h-8 w-8',
    isParent
      ? 'bg-gray-50 dark:bg-gray-800'
      : 'bg-white ring-gray-300 dark:bg-gray-850 sm:ring-transparent dark:sm:ring-transparent sm:bg-gray-100 dark:sm:bg-gray-800',
    'rounded-full flex items-center justify-center ring-gray-300 ring-2 ring-offset-2 dark:ring-gray-700 dark:ring-offset-gray-850'
  )

  const iconClasses = clsxMerge(
    'h-5 w-5',
    color === IconColor.Brand ? 'text-brand-500' : 'text-gray-500'
  )

  const renderIcon = () => {
    const IconComponent = iconMap.get(type) || HiInformationCircle
    return <IconComponent className={iconClasses} aria-hidden="true" />
  }

  return (
    <div className="relative px-1">
      <div className={iconContainerClasses}>{renderIcon()}</div>
    </div>
  )
}
