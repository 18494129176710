import { classNames } from 'helpers/utilities'

interface ForSaleListHeaderProps {
  show: boolean
}

export default function ForSaleListHeader({ show }: ForSaleListHeaderProps) {
  const gridTemplate = classNames(
    'md:grid-cols-[2.25rem_1fr_1fr_1fr_48px]',
    'xl:grid-cols-[2.25rem_1fr_1fr_1fr_1fr_1fr_24px]'
  )

  return (
    <div
      className={classNames(
        show
          ? 'hidden md:grid gap-x-6 px-4 sm:px-6 xl:px-8 bg-gray-50 py-3.5 text-sm font-semibold text-gray-900 2xl:sticky 2xl:z-[19] dark:bg-gray-950 dark:text-gray-500 2xl:top-[177px]'
          : 'hidden',
        gridTemplate
      )}
    >
      <div>Asset</div>
      <div className="hidden md:block">&nbsp;</div>
      <div className="hidden md:block">Price</div>
      <div className="hidden md:block">Marketplace</div>
      <div className="hidden xl:block">Creator</div>
      <div className="hidden xl:block">Asset ID</div>
      <div className="hidden md:block" />
    </div>
  )
}
