import { useQuery } from '@tanstack/react-query'
import fetchDiscordName from 'api/utils/fetchDiscordName'
import fetchTelegramName from 'api/utils/fetchTelegramName'
import useErrorToast from 'hooks/useErrorToast'
import { NODE_ENV } from 'src/data/constants'
import type { NfdRecord } from 'api/api-client'
import type { AxiosError } from 'axios'

export default function useLinks(nfd: NfdRecord) {
  const handleError = useErrorToast(undefined, 5000)

  const isLocalhost = NODE_ENV === 'development'

  const telegramQuery = useQuery<string, AxiosError>(
    ['telegram', { nfd: nfd.name, username: nfd.properties?.verified?.telegram }],
    () => fetchTelegramName(nfd.properties?.verified?.telegram),
    {
      enabled: nfd.properties?.verified?.telegram !== undefined && !isLocalhost,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => handleError(`Error fetching Telegram name: ${error.message}`)
    }
  )

  const discordQuery = useQuery<string, AxiosError>(
    ['discord', { nfd: nfd.name, username: nfd.properties?.verified?.discord }],
    () => fetchDiscordName(nfd.properties?.verified?.discord),
    {
      enabled: nfd.properties?.verified?.discord !== undefined && !isLocalhost,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => handleError(`Error fetching Discord name: ${error.message}`)
    }
  )

  return {
    telegramQuery,
    discordQuery,
    isLocalhost
  }
}
