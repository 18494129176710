import { useMemo } from 'react'
import Linkify from 'components/Linkify'
import { getCustomFields } from './About.utils'
import type { NfdRecord } from 'api/api-client'

interface CustomFieldsCardProps {
  nfd: NfdRecord
}

export default function CustomFieldsCard({ nfd }: CustomFieldsCardProps) {
  const customFields = useMemo(() => getCustomFields(nfd), [nfd])

  if (customFields.length === 0) {
    return null
  }

  const renderField = (key: string) => {
    const value = nfd.properties?.userDefined?.[key]

    if (!value) {
      return null
    }

    return (
      <tr key={key}>
        <td className="px-5 py-4 border-t border-gray-200 dark:border-gray-750/75">
          <span className="inline-flex items-center rounded-sm bg-gray-100 px-1 py-0.5 text-sm font-medium text-gray-600 font-mono -ml-1 scale-90 origin-left dark:bg-gray-800 dark:text-gray-400">
            {key}
          </span>
        </td>
        <td className="w-full px-4 py-4 text-sm text-gray-900 whitespace-pre-wrap border-t border-gray-200 dark:border-gray-750/75 dark:text-gray-400">
          <Linkify decorate maxLength={28} showIcon>
            <span className="-ml-2">{value}</span>
          </Linkify>
        </td>
      </tr>
    )
  }

  return (
    <div className="break-inside-avoid-column pb-1">
      <div className="bg-white shadow overflow-hidden rounded-lg dark:bg-gray-850 dark:highlight">
        <div className="px-5 py-4">
          <h3 className="text-md leading-6 font-medium text-gray-900 dark:text-gray-300">
            Custom Fields
          </h3>
        </div>
        <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
          <tbody className="divide-y divide-gray-200 bg-white dark:bg-gray-850 dark:divide-gray-750/75">
            {customFields.map(renderField)}
          </tbody>
        </table>
      </div>
    </div>
  )
}
