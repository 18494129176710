import { useWallet } from '@txnlab/use-wallet-react'
import Link from 'next/link'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { BiCopy, BiLinkExternal } from 'react-icons/bi'
import { HiExclamationTriangle } from 'react-icons/hi2'
import { TbClockDollar, TbClockPlus } from 'react-icons/tb'
import AlgoAddress from 'components/AlgoAddress'
import Button from 'components/Button'
import ButtonLink from 'components/ButtonLink'
import RenewDialog from 'components/mint/RenewDialog'
import Tooltip from 'components/Tooltip'
import { Card, Field } from './Card'
import { useExplorerStore } from '@/store'
import { copyToClipboard } from 'src/helpers/copyToClipboard'
import { isVaultsSupported, meetsMinimumVersion } from 'helpers/versions'
import { isValidSegment, trimSegment } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

interface DetailsCardProps {
  nfd: NfdRecord
}

export default function DetailsCard({ nfd }: DetailsCardProps) {
  const { activeAddress } = useWallet()
  const selectedExplorer = useExplorerStore((state) => state.selectedExplorer)
  const lookupByAssetId = useExplorerStore((state) => state.lookupByAssetId)
  const lookupByAppId = useExplorerStore((state) => state.lookupByAppId)

  const isSegment = isValidSegment(nfd.name)
  const isConnected = !!activeAddress
  const isExpired = dayjs(nfd.timeExpires).isBefore(dayjs())
  const isOwner = nfd.owner === activeAddress

  const expiredFieldTitle = isExpired ? 'expired' : 'expires'
  const renewOwnerAction = isExpired ? 'Renew' : 'Extend'
  const renewButtonLabel = isOwner ? renewOwnerAction : 'Buy Now'
  const Icon = isOwner ? TbClockPlus : TbClockDollar

  return (
    <Card title="NFD Details">
      <Field name="owner" className="flex items-center whitespace-pre-wrap min-w-0 -my-4">
        <AlgoAddress
          address={nfd.owner as string}
          copy
          link
          expand
          isDeposit={nfd.owner === nfd.depositAccount}
        />
      </Field>

      {isVaultsSupported(nfd) && nfd.nfdAccount && (
        <Field name="vault" className="flex items-center whitespace-pre-wrap min-w-0 -my-4">
          <AlgoAddress
            address={nfd.nfdAccount}
            href={`/name/${nfd.name}?view=vault`}
            copy
            link
            expand
          />
        </Field>
      )}

      {nfd.asaID && (
        <Field name="ASA ID" className="flex items-center justify-between -my-4">
          <span className="font-mono dark:text-gray-400">{nfd.asaID}</span>
          <div className="inline-flex -space-x-px rounded-md shadow-sm ml-3">
            <Tooltip text={selectedExplorer} wrapperClassName="group" className="translate-y-2">
              <a
                href={lookupByAssetId(nfd.asaID)}
                className="relative inline-flex items-center group-first:rounded-l-md group-last:rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 outline-brand-500 dark:bg-gray-750 dark:border-transparent dark:border-l-black/20 dark:group-first:border-l-transparent dark:text-gray-300 dark:hover:bg-gray-700"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">View on {selectedExplorer}</span>
                <BiLinkExternal className="h-[18px] w-[18px]" aria-hidden="true" />
              </a>
            </Tooltip>
            <Tooltip text="Copy" wrapperClassName="group" className="translate-y-2">
              <button
                type="button"
                className="relative inline-flex items-center group-first:rounded-l-md group-last:rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 outline-brand-500 dark:bg-gray-750 dark:border-transparent dark:border-l-black/20 dark:group-first:border-l-transparent dark:text-gray-300 dark:hover:bg-gray-700"
                data-clipboard-text={nfd.asaID}
                onClick={copyToClipboard}
              >
                <span className="sr-only">Copy ASA ID</span>
                <BiCopy className="h-[18px] w-[18px]" aria-hidden="true" />
              </button>
            </Tooltip>
          </div>
        </Field>
      )}

      {nfd.appID && (
        <Field name="App ID" className="flex items-center justify-between -my-4">
          <span className="font-mono dark:text-gray-400">{nfd.appID}</span>
          <div className="inline-flex -space-x-px rounded-md shadow-sm ml-3">
            <Tooltip text={selectedExplorer} wrapperClassName="group" className="translate-y-2">
              <a
                href={lookupByAppId(nfd.appID)}
                className="relative inline-flex items-center group-first:rounded-l-md group-last:rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 outline-brand-500 dark:bg-gray-750 dark:border-transparent dark:border-l-black/20 dark:group-first:border-l-transparent dark:text-gray-300 dark:hover:bg-gray-700"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">View on {selectedExplorer}</span>
                <BiLinkExternal className="h-[18px] w-[18px]" aria-hidden="true" />
              </a>
            </Tooltip>
            <Tooltip text="Copy" wrapperClassName="group" className="translate-y-2">
              <button
                type="button"
                className="relative inline-flex items-center group-first:rounded-l-md group-last:rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 outline-brand-500 dark:bg-gray-750 dark:border-transparent dark:border-l-black/20 dark:group-first:border-l-transparent dark:text-gray-300 dark:hover:bg-gray-700"
                data-clipboard-text={nfd.appID}
                onClick={copyToClipboard}
              >
                <span className="sr-only">Copy App ID</span>
                <BiCopy className="h-[18px] w-[18px]" aria-hidden="true" />
              </button>
            </Tooltip>
          </div>
        </Field>
      )}

      <Field name="claimed">
        <div className="flex items-center justify-between gap-x-2">
          <span className="dark:text-gray-400">{dayjs(nfd.timePurchased).format('lll')}</span>
          <ButtonLink size="xs" href={`/name/${nfd.name}?view=history`} className="-my-1.5">
            <span className="hidden xs:inline">View&nbsp;</span>History
          </ButtonLink>
        </div>
      </Field>

      {meetsMinimumVersion(nfd, '3') && (
        <Field name={expiredFieldTitle}>
          <div className="flex items-center justify-between gap-x-2">
            <span className="dark:text-gray-400">
              {isExpired && (
                <HiExclamationTriangle
                  aria-hidden="true"
                  className="inline-flex mr-1 h-5 w-5 text-yellow-400"
                />
              )}
              {dayjs(nfd.timeExpires).fromNow()} ({dayjs(nfd.timeExpires).format('lll')})
            </span>
            {isConnected && (
              <RenewDialog nfd={nfd}>
                <Button variant="gradient" size="xs" className="gap-x-1.5 rounded-md -my-2">
                  <Icon className="h-[18px] w-[18px] xs:hidden sm:inline" aria-hidden="true" />
                  <span className="text-xs sr-only xs:not-sr-only xs:whitespace-nowrap">
                    {renewButtonLabel}
                  </span>
                </Button>
              </RenewDialog>
            )}
          </div>
        </Field>
      )}

      {isSegment && (
        <Field name="parent">
          <Link
            href={`/name/${trimSegment(nfd.name)}`}
            className="text-gray-900 hover:text-brand-500 font-medium dark:text-brand-500 dark:hover:text-brand-600"
          >
            {trimSegment(nfd.name)}
          </Link>
        </Field>
      )}
    </Card>
  )
}
