import { removeNullValues } from 'helpers/objects'
import { listAlgoAccounts } from 'helpers/nfd'
import { isVaultsSupported } from 'helpers/versions'
import type { InfiniteData, QueryKey } from '@tanstack/react-query'
import type { NfdRecord } from 'api/api-client'
import type { AssetsQueryKey, InfiniteAssetsQueryResult } from 'api/hooks/useInfiniteAssets'
import type { AssetsParams } from 'api/types/assets'
import type { AlgoAsset, Asset, AssetAmount } from 'types/assets'
import type { OperationType, OptimisticUpdate } from 'store/state/optimisticUpdates/types'
import type { FilterProps } from './Assets.types'
import type { OnSuccessParams } from 'components/SendModal/SendModal.types'

export const getQueryKey = (key: string, nfd: NfdRecord, props: FilterProps): AssetsQueryKey => {
  const { searchQuery, accounts, typeFilter } = props

  const params = removeNullValues({
    q: searchQuery,
    account: accounts,
    filter: typeFilter
  })

  return [key, nfd.name, params as AssetsParams]
}

export const isAlgoAsset = (asset: Asset | undefined): asset is AlgoAsset => {
  return asset?.id === 0
}

export const getVaultEnabledAsset = (asset: Asset, nfd: NfdRecord): Asset => {
  if (isVaultsSupported(nfd) || !isAlgoAsset(asset)) {
    return asset
  }

  const { amounts } = asset
  const filteredAmounts = amounts.filter((a) => a.account !== nfd.nfdAccount)

  return {
    ...asset,
    amounts: filteredAmounts
  }
}

/**
 * Applies optimistic updates to the given `data` object, which represents
 * an infinite list of paged gallery data, based on the provided `optimisticUpdates`.
 *
 * The function iterates over each page of the data, filtering the updates that
 * are applicable to the current page. For each update, it determines the
 * operation (either 'update' or 'remove') and applies the corresponding
 * modification to the records within the page.
 *
 * If there are no optimistic updates, the original data is returned unchanged.
 *
 * @param data - The original infinite data object containing paged gallery data.
 * @param optimisticUpdates - An array of optimistic updates to apply to the data.
 *
 * @returns The updated data object with optimistic updates applied.
 */
export const selectOptimisticUpdates = (
  data: InfiniteData<InfiniteAssetsQueryResult>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optimisticUpdates: OptimisticUpdate<any>[]
) => {
  if (optimisticUpdates.length === 0) {
    return data
  }

  const updatedData: InfiniteData<InfiniteAssetsQueryResult> = {
    ...data,
    pages: data.pages.map((page, pageIndex) => {
      const updatesForPage = optimisticUpdates.filter((update) => update.pageIndex === pageIndex)

      if (updatesForPage.length === 0) {
        return page
      }

      const updatedPage: InfiniteAssetsQueryResult = {
        ...page,
        assets: [...page.assets]
      }

      updatesForPage.forEach(({ record, operation, uniqueKey }) => {
        const key = uniqueKey as keyof Asset

        const updatedAsset = record as Asset
        const index = updatedPage.assets.findIndex((record) => record[key] === updatedAsset[key])

        switch (operation) {
          case 'update': {
            if (index !== -1) {
              updatedPage.assets[index] = updatedAsset
            }
            break
          }
          case 'remove': {
            if (index !== -1) {
              updatedPage.assets.splice(index, 1)
            }
            break
          }
          default:
            break
        }
      })

      return updatedPage
    })
  }

  return updatedData
}

interface GetUpdatedAmounts {
  sender: string
  receiver: string
  amount: number
  nfd: NfdRecord
  asset: Asset
  queryKey: QueryKey
}

export const getUpdatedAmounts = ({
  sender,
  receiver,
  amount,
  nfd,
  asset,
  queryKey
}: GetUpdatedAmounts): AssetAmount[] => {
  const updatedAmounts = asset.amounts
    .map((a) => {
      if (a.account === sender) {
        const newAmount = a.amount - amount
        // If sender has no more of this asset, remove it
        return newAmount > 0 ? { ...a, amount: newAmount } : null
      } else if (a.account === receiver) {
        return { ...a, amount: a.amount + amount }
      } else {
        return a
      }
    })
    .filter(Boolean) as AssetAmount[]

  // If receiver was not in the original amounts, see if it should be added
  if (!updatedAmounts.some((a) => a.account === receiver)) {
    const accounts = listAlgoAccounts(nfd)
    const isReceiverOwnedAccount = accounts.includes(receiver)

    const filter = queryKey[2] as AssetsParams
    const shouldAddReceivedAmount =
      isReceiverOwnedAccount && (!filter.account || filter.account?.includes(receiver))

    if (shouldAddReceivedAmount) {
      updatedAmounts.push({ account: receiver, amount })
    }
  }

  return updatedAmounts
}

export const parseSuccessParams = (params: OnSuccessParams, name: string, vaultAccount: string) => {
  if (!params) {
    throw new Error('Invalid params (undefined)')
  }

  let sender: string | undefined
  let receiver: string | undefined
  let amount: number | undefined

  if ('body' in params && 'receiver' in params.body) {
    // send from vault
    sender = vaultAccount
    receiver = params.body.receiver
    amount = params.body.amount
  } else if ('name' in params) {
    // send to vault
    sender = params.body.sender
    receiver = params.name === name ? vaultAccount : params.name
    amount = params.body.amount
  } else if ('receiver' in params) {
    // send to account
    sender = params.sender
    receiver = params.receiver
    amount = params.amount
  } else {
    throw new Error('Invalid params')
  }

  if (!sender) {
    throw new Error('Invalid sender')
  }

  if (!receiver) {
    throw new Error('Invalid receiver')
  }

  if (!amount) {
    throw new Error('Invalid amount')
  }

  return { sender, receiver, amount }
}

interface GetAssetUpdate {
  nfd: NfdRecord
  asset: Asset
  params: OnSuccessParams
  queryKey: QueryKey
}

export const getAssetUpdate = ({
  nfd,
  asset,
  params,
  queryKey
}: GetAssetUpdate): [OperationType, Asset] => {
  try {
    const parsedParams = parseSuccessParams(params, nfd.name, nfd.nfdAccount as string)
    const amounts = getUpdatedAmounts({ nfd, asset, queryKey, ...parsedParams })

    if (amounts.length === 0) {
      return ['remove', asset]
    }

    const updatedAsset = {
      ...asset,
      amounts
    }

    return ['update', updatedAsset]
  } catch (error) {
    console.error(error)
    return ['update', asset]
  }
}
