import AssetMedia from 'components/AssetMedia/AssetMedia'
import TextAvatar from 'components/TextAvatar'
import imageLoader from 'helpers/imageLoader'

interface LightboxAssetProps {
  src: string
  alt: string
  onLoadingComplete: () => void
  nfdName?: string
}

export default function LightboxAsset({
  src,
  alt,
  onLoadingComplete,
  nfdName
}: LightboxAssetProps) {
  if (nfdName) {
    return (
      <TextAvatar
        name={nfdName}
        imgSrc={src}
        className="absolute inset-0 group-hover:opacity-75 object-cover w-full h-full dark:group-hover:ring-inset dark:group-hover:ring-gray-900 dark:group-hover:ring-1"
      />
    )
  }

  return (
    <AssetMedia
      src={src}
      alt={alt}
      className="object-contain max-h-full max-w-full mx-auto"
      loader={imageLoader}
      sizes="100vw"
      quality={90}
      fill
      onLoadingComplete={onLoadingComplete}
      options={{ showLoading: false }}
      videoJsOptions={{
        controls: false,
        controlBar: false,
        responsive: false,
        fill: true,
        autoplay: true,
        loop: true
      }}
    />
  )
}
