import { Card, Field } from './Card'
import DetailsCard from './DetailsCard'
import VerifiedAddressCard from './VerifiedAddressCard'
import UnverifiedAddressCard from './UnverifiedAddressCard'
import CustomFieldsCard from './CustomFieldsCard'
import { getAboutClassName, hasFields } from './About.utils'
import type { PanelProps } from 'components/DetailView/DetailView.types'

export default function About({ nfd, isOwner }: PanelProps) {
  if (!nfd.properties) return null // @todo: handle this (edge?) case

  const renderField = (key: string) => (
    <Field key={key} name={key}>
      {nfd.properties?.userDefined?.[key]}
    </Field>
  )

  const renderCard = (title: string, fields: string[]) => {
    if (!hasFields(nfd, fields)) {
      return null
    }

    return <Card title={title}>{fields.map(renderField)}</Card>
  }

  return (
    <div className="flex-1 bg-gray-100 pt-2 pb-8 5xl:pt-4 dark:bg-gray-950/40">
      <div className="p-4 sm:px-6 lg:px-8 5xl:px-10">
        <div className={getAboutClassName(nfd)}>
          <VerifiedAddressCard nfd={nfd} isOwner={isOwner} />
          <UnverifiedAddressCard nfd={nfd} isOwner={isOwner} />

          <DetailsCard nfd={nfd} />

          {renderCard('Personal Information', ['email', 'address'])}
          {renderCard('Unverified Social Media', [
            'twitter',
            'discord',
            'telegram',
            'linkedin',
            'github'
          ])}
          {renderCard('Websites', ['domain', 'website'])}

          {nfd.properties?.userDefined?.url && (
            <Card title="Algo.xyz">
              <Field name="URL">{nfd.properties.userDefined.url}</Field>
            </Card>
          )}

          <CustomFieldsCard nfd={nfd} />
        </div>
      </div>
    </div>
  )
}
