import * as React from 'react'
import { IoMdPricetag } from 'react-icons/io'
import { useNfdConstraints } from 'api/hooks/contracts/useNfdConstraints'
import AlgoPrice from 'components/AlgoPrice'
import SimpleModal from 'components/SimpleModal'
import PriceBreakdown from 'components/manage/Segments/PriceBreakdown'
import { isSegmentingUnlocked } from 'helpers/segments'
import {
  convertCentsToUsd,
  convertMicroalgosToUsd,
  formatNumber,
  roundMicroalgos
} from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

interface PriceBadgeProps {
  nfd: NfdRecord
}

export default function PriceBadge({ nfd }: PriceBadgeProps) {
  const constraintsQuery = useNfdConstraints({
    refetchInterval: 1000 * 60 * 10, // 10 mins
    refetchOnMount: 'always',
    staleTime: 0
  })
  const constraints = constraintsQuery.data

  const platformFeeUsd = Number(constraints?.segmentPlatformCostInUsd) / 100
  const platformFee = Number(constraints?.segmentPlatformCostInAlgo)
  const carryCost = Number(constraints?.newMintCarryCost)

  const algoUsd = React.useMemo(() => {
    if (platformFee && platformFeeUsd) {
      return (platformFeeUsd / platformFee) * 1e4
    }
    return 0
  }, [platformFee, platformFeeUsd])

  const isUnlocked = isSegmentingUnlocked(nfd)
  const segmentPriceCents = parseInt(nfd?.properties?.internal?.segmentPriceUsd || '0')

  const usdMinCost = constraints
    ? convertMicroalgosToUsd(platformFee + carryCost, algoUsd, 'roundHalfUp')
    : 0

  const segmentPriceUsd = isUnlocked ? convertCentsToUsd(segmentPriceCents) : platformFeeUsd

  const DisplayPrice = () => (
    <>
      {`$ ${formatNumber(segmentPriceUsd, { minimumFractionDigits: 2 })} +`}&nbsp;
      <AlgoPrice price={carryCost} className="mt-[1px]" />
    </>
  )

  const LockedPriceModal = () => {
    if (!algoUsd || !constraints) return null

    const sellAmount = roundMicroalgos(platformFee + carryCost, 3)

    return (
      <>
        <p>
          The price of a locked segment is the sum of the{' '}
          <span className="dark:font-medium dark:text-gray-400">
            ${formatNumber(platformFeeUsd, { minimumFractionDigits: 2 })}&nbsp;USD platform fee
          </span>{' '}
          and the{' '}
          <span className="dark:font-medium dark:text-gray-400">
            <AlgoPrice price={carryCost} /> carry&nbsp;cost
          </span>
          .
        </p>

        <div className="py-6 text-left">
          <PriceBreakdown
            algoUsd={algoUsd}
            platformFee={platformFee}
            usdMinCost={usdMinCost}
            carryCost={carryCost}
            sellAmount={sellAmount}
          />
        </div>
      </>
    )
  }

  const renderPrice = () => {
    if (isUnlocked || !algoUsd) {
      return <DisplayPrice />
    }

    return (
      <SimpleModal
        triggerType="jsx"
        triggerText={<DisplayPrice />}
        triggerClassName="underline decoration-dotted decoration-gray-500 cursor-help"
        tooltip={{
          text: 'Price Breakdown',
          distance: 12
        }}
        title="Price Breakdown"
        content={<LockedPriceModal />}
        className="max-w-md"
      />
    )
  }

  return (
    <span className="mt-2 mr-6 flex items-center text-sm font-medium text-gray-600/80 dark:text-gray-400">
      <IoMdPricetag
        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400/80 dark:text-gray-600"
        aria-hidden="true"
      />
      {renderPrice()}
    </span>
  )
}
