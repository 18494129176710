import { Disclosure } from '@headlessui/react'
import { AiFillBank } from 'react-icons/ai'
import { BiWallet } from 'react-icons/bi'
import { HiFunnel } from 'react-icons/hi2'
import AccountFilter from './AccountFilter'
import SearchFilter from './SearchFilter'
import TypeFilter from './TypeFilter'
import Checkbox from 'components/Checkbox'
import GridListToggle from 'components/GridListToggle'
import { useAccounts, useAssetTypes, useMobileAccounts } from './Filters.hooks'
import { truncateAddress } from 'helpers/truncateAddress'
import { classNames } from 'helpers/utilities'
import { AssetSubNav, AssetTypeFilter } from '../Assets.types'
import type { NfdRecord } from 'api/api-client'

export interface FilterProps {
  nfd: NfdRecord
  activeTab: AssetSubNav
  searchQuery: string | null
  setSearchQuery: (searchQuery: string | null) => void
  allAccounts: string[]
  accounts: string[] | null
  setAccounts: (accounts: string[] | null) => void
  setAccountsAsync: (accounts: string[] | null) => Promise<void>
  typeFilter: Array<AssetTypeFilter> | null
  setTypeFilter: (typeFilter: Array<AssetTypeFilter> | null) => Promise<void>
  viewSetting: 'grid' | 'list'
  setViewSetting: (viewSetting: 'grid' | 'list') => void
}

export default function Filters({
  nfd,
  activeTab,
  searchQuery,
  setSearchQuery,
  allAccounts,
  accounts,
  setAccounts,
  setAccountsAsync,
  typeFilter,
  setTypeFilter,
  viewSetting,
  setViewSetting
}: FilterProps) {
  const { accountOptions, selectedAccounts, setSelectedAccounts } = useAccounts({
    accounts,
    setAccounts,
    setAccountsAsync,
    allAccounts
  })

  const { selected, handleAccountsChange } = useMobileAccounts({
    accounts,
    setAccounts,
    setAccountsAsync,
    allAccounts
  })

  const { filters, handleFilterChange } = useAssetTypes({ typeFilter, setTypeFilter })

  const numAccountFilters = accounts?.length || 0
  const numTypeFilters =
    typeFilter?.filter((f) => !(activeTab === AssetSubNav.ForSale && f === 'tokens')).length || 0
  const numTotalFilters = numAccountFilters + numTypeFilters

  const showAccountFilter = activeTab !== 'vault' && allAccounts.length > 1

  const AccountLabel = ({ account, selected }: { account: string; selected: boolean }) => {
    return (
      <div className="flex items-center space-x-2 text-gray-900 dark:text-gray-400">
        {account === nfd.nfdAccount ? (
          <AiFillBank
            className={classNames(selected ? 'opacity-90' : 'opacity-50', 'h-4 w-4')}
            aria-hidden="true"
          />
        ) : (
          <BiWallet
            className={classNames(selected ? 'opacity-90' : 'opacity-50', 'h-4 w-4')}
            aria-hidden="true"
          />
        )}
        <span
          className={classNames(
            selected ? 'font-semibold dark:text-gray-100' : 'font-normal',
            'block truncate font-mono'
          )}
        >
          {truncateAddress(account, { jsx: true })}
        </span>
      </div>
    )
  }

  return (
    <Disclosure as="section" aria-labelledby="filter-heading" className="bg-white dark:bg-gray-900">
      <h2 id="filter-heading" className="sr-only">
        Filters
      </h2>
      <div className="flex items-center justify-between space-x-6 py-3 px-4 sm:px-6 xl:px-8 2xl:flex-1 2xl:py-2.5 2xl:pl-6 4xl:px-8">
        <SearchFilter
          className="md:flex-1 max-w-sm"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <div className="flex items-center">
          <Disclosure.Button className="group flex md:hidden items-center text-sm font-medium text-gray-700 dark:text-gray-300">
            <HiFunnel
              className="xs:mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500 dark:text-gray-600 dark:group-hover:text-gray-500"
              aria-hidden="true"
            />
            <span className="hidden xs:inline">Filters</span>
            {numTotalFilters > 0 && (
              <span className="ml-1.5 rounded bg-brand-500 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-white">
                {numTotalFilters}
              </span>
            )}
          </Disclosure.Button>
          <TypeFilter
            filters={filters}
            handleFilterChange={handleFilterChange}
            filtersCount={numTypeFilters}
            activeTab={activeTab}
            className="hidden md:inline-block"
          />

          {showAccountFilter && (
            <AccountFilter
              nfd={nfd}
              accountOptions={accountOptions}
              selectedAccounts={selectedAccounts}
              setSelectedAccounts={setSelectedAccounts}
              setAccounts={setAccounts}
              className="hidden md:flex md:w-48 md:ml-6 xl:w-64 2xl:w-[11rem] 4xl:w-64 4xl:ml-8"
              labelClassName="sr-only xl:not-sr-only xl:block xl:mr-2 2xl:sr-only 4xl:not-sr-only 4xl:block 4xl:mr-2"
            />
          )}

          <div className="flex">
            <GridListToggle
              value={viewSetting}
              onChange={setViewSetting}
              className="ml-6 4xl:ml-8"
            />
          </div>
        </div>
      </div>

      <Disclosure.Panel className="md:hidden py-10 px-4 sm:px-6 border-t border-gray-200 dark:border-gray-800">
        <div className="flex justify-between xs:grid xs:grid-cols-2">
          <fieldset className="xs:col-start-1">
            <legend className="block text-sm font-medium">Types</legend>
            <div className="space-y-6 pt-6 sm:space-y-4">
              {Object.entries(AssetTypeFilter)
                // hide "Tokens" option in For Sale tab
                .filter(([, value]) => {
                  return !(activeTab === AssetSubNav.ForSale && value === AssetTypeFilter.Tokens)
                })
                .map(([key, value]) => (
                  <div key={value} className="flex items-center">
                    <Checkbox
                      id={`mobile-${value}`}
                      label={key}
                      value={value}
                      checked={filters[value]}
                      onChange={handleFilterChange}
                      labelClassName="ml-3 whitespace-nowrap pr-6 text-sm xs:text-base text-gray-900 dark:text-white"
                    />
                  </div>
                ))}
            </div>
          </fieldset>

          {showAccountFilter && (
            <fieldset className="xs:col-start-2 xs:col-span-2">
              <legend className="block text-sm font-medium">Accounts</legend>
              <div className="space-y-6 pt-6 sm:space-y-4">
                {allAccounts.map((address) => (
                  <div key={address} className="flex items-center">
                    <Checkbox
                      id={`account-${address}`}
                      label={
                        <AccountLabel
                          account={address}
                          selected={!accounts ? true : accounts.includes(address)}
                        />
                      }
                      value={address}
                      checked={selected[address]}
                      onChange={handleAccountsChange}
                      labelClassName="ml-3 whitespace-nowrap pr-6 text-sm xs:text-base text-gray-900 dark:text-white"
                    />
                  </div>
                ))}
              </div>
            </fieldset>
          )}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  )
}
