import Linkify from 'components/Linkify'
import { classNames } from 'helpers/utilities'

export interface AboutCardField {
  name?: string | undefined
  className?: string
  linkify?: boolean
  children: React.ReactNode
}

export const Field: React.FC<AboutCardField> = ({
  name,
  className = '',
  linkify = true,
  children
}) => {
  const renderContent = () => {
    if (linkify) {
      return (
        <Linkify decorate maxLength={36} showIcon>
          {children}
        </Linkify>
      )
    }

    return children
  }

  if (!children) {
    return null
  }

  return (
    <div className="px-5 py-4 grid grid-cols-[4rem_1fr] sm:grid-cols-[5rem_1fr] gap-2 xs:gap-4">
      {name && (
        <dt className="inline-flex items-center text-sm font-medium text-gray-500 capitalize">
          {name}
        </dt>
      )}
      <dd
        className={classNames(
          'text-sm text-gray-900 whitespace-pre-wrap dark:text-gray-400',
          !name ? 'col-span-2' : '',
          className
        )}
      >
        {renderContent()}
      </dd>
    </div>
  )
}

interface AboutCard {
  title: string | React.ReactNode
  children: React.ReactNode
}

export const Card: React.FC<AboutCard> = ({ title, children }) => {
  return (
    <div className="break-inside-avoid-column">
      <div className="bg-white shadow overflow-hidden rounded-lg dark:bg-gray-850 dark:highlight">
        <div className="px-5 py-4">
          <h3 className="leading-6 font-medium text-gray-900 dark:text-gray-300">{title}</h3>
        </div>
        <div className="border-t border-gray-200 dark:border-gray-750/75">
          <dl className="divide-y divide-gray-200 dark:divide-gray-750/75">{children}</dl>
        </div>
      </div>
    </div>
  )
}
