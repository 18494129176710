import { Fragment, useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import Alert from 'components/Alert'
import ItemLayout from 'components/ItemLayout'
import Loading from 'components/Loading'
import UpgradeRequired from 'components/UpgradeRequired'
import AssetPreview from '../AssetPreview'
import ListHeader from '../ListHeader'
import VaultPanel from './VaultPanel'
import useInfiniteAssets from 'api/hooks/useInfiniteAssets'
import { getQueryKey } from '../Assets.utils'
import { isVaultsSupported } from 'helpers/versions'
import { classNames } from 'helpers/utilities'
import type { Asset } from 'types/assets'
import type { AssetTypeFilter } from '../Assets.types'
import type { NfdRecord } from 'api/api-client'

interface VaultProps {
  nfd: NfdRecord
  searchQuery: string | null
  typeFilter: Array<AssetTypeFilter> | null
  viewSetting: 'grid' | 'list'
  isOwner: boolean
}

export default function Vault({ nfd, searchQuery, typeFilter, viewSetting, isOwner }: VaultProps) {
  const queryKey = getQueryKey('nfd-assets-vault', nfd, {
    searchQuery,
    accounts: [nfd.nfdAccount as string],
    typeFilter
  })

  const [, name, params] = queryKey

  const { data, isLoading, error, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteAssets({
      queryKey,
      name,
      params,
      options: {
        enabled: isVaultsSupported(nfd)
      }
    })

  const allAssets = useMemo(() => data?.pages.flatMap((page) => page.assets) || [], [data?.pages])

  const renderResults = (assets: Asset[], pageIdx: number) => {
    if (!data) return null

    const handleInViewChange = (inView: boolean) => {
      if (!inView) return

      const isLastPage = pageIdx === data.pages.length - 1

      if (isLastPage && hasNextPage) {
        fetchNextPage()
      }
    }

    return assets.map((asset: Asset, i) => {
      const isLastResult = i === assets.length - 1

      if (isLastResult) {
        return (
          <InView key={asset.id} as="div" onChange={handleInViewChange}>
            <AssetPreview nfd={nfd} asset={asset} view={viewSetting} />
          </InView>
        )
      }

      return <AssetPreview key={asset.id} nfd={nfd} asset={asset} view={viewSetting} />
    })
  }

  if (!isVaultsSupported(nfd)) {
    return (
      <div className="p-4 sm:px-6 xl:px-8">
        <UpgradeRequired nfd={nfd} isOwner={isOwner} action={<>activate&nbsp;vault</>} />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center py-32">
        <Loading />
      </div>
    )
  }

  if (error) {
    return (
      <div className="py-8 px-4 sm:px-6 xl:px-8">
        <Alert type="error" title={`Error fetching assets`} error={error} />
      </div>
    )
  }

  if (!isFetchingNextPage && allAssets.length === 0) {
    return (
      <div className="py-8 px-4 sm:px-6 xl:px-8">
        <Alert type="info" title="No assets found" />
      </div>
    )
  }

  const isList = viewSetting === 'list'

  return (
    <>
      <VaultPanel nfd={nfd} isOwner={isOwner} />

      <ListHeader show={isList} isVault />

      <ItemLayout
        view={viewSetting}
        className={classNames(isList ? '' : 'py-12 px-4 sm:px-6 xl:px-8')}
      >
        {data?.pages.map((page, pageIdx) => (
          <Fragment key={pageIdx}>{renderResults(page.assets, pageIdx)}</Fragment>
        ))}
      </ItemLayout>

      {isFetchingNextPage && (
        <div className="flex flex-col items-center justify-center py-32">
          <Loading />
        </div>
      )}
    </>
  )
}
