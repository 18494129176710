import { useState } from 'react'
import dynamic from 'next/dynamic'
import { ACTIONS, CallBackProps, STATUS, StoreHelpers } from 'react-joyride'
import { PurchasedTooltip } from 'components/joyride/PurchasedTooltip'

const Joyride = dynamic(() => import('react-joyride'), { ssr: false })

export default function PurchasedTip({ showTip }: { showTip: boolean }) {
  const [showJoyride, setShowJoyride] = useState(true)
  const [helpers, setHelpers] = useState<StoreHelpers>()

  const handleJoyrideCallback = (data: CallBackProps) => {
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED]

    if (finishedStatuses.includes(data.status) || data.action === ACTIONS.CLOSE) {
      setShowJoyride(false)
      helpers?.reset(false)
    }
  }

  if (!showTip) {
    return null
  }

  return (
    <Joyride
      run={showJoyride}
      steps={[
        {
          target: '[data-joyride-id="jr-edit-nfd"]',
          content: (
            <>
              <p>
                <strong className="font-medium text-gray-900 dark:text-gray-100">Tip:</strong> Make
                sure you set your deposit address!
              </p>
            </>
          ),
          placement: 'bottom',
          disableBeacon: true
        }
      ]}
      callback={handleJoyrideCallback}
      tooltipComponent={PurchasedTooltip}
      getHelpers={(helpers) => setHelpers(helpers)}
      continuous
      showProgress
      disableScrolling
      disableOverlay
      spotlightClicks
      styles={{
        options: {
          zIndex: 40
        }
      }}
    />
  )
}
