import Button from 'components/Button'

interface ErrorFallbackProps {
  error: Error
  resetErrorBoundary: (...args: unknown[]) => void
}

export default function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  return (
    <div className="flex-1 bg-gray-100 pt-2 pb-8 5xl:pt-4 dark:bg-gray-950/40">
      <div className="max-w-5xl mx-auto p-4 sm:px-6 lg:px-8">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
          <span className="font-bold text-brand-500">Error: </span>Failed to retrieve history
        </h3>
        <p className="mt-1 break-words max-w-2xl text-sm text-gray-500 mb-5 dark:text-gray-400">
          {error.message}
        </p>
        <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent">
          <Button variant="primary" onClick={resetErrorBoundary}>
            Try again
          </Button>
          <a href="mailto:feedback@txnlab.dev">
            <Button>Contact support</Button>
          </a>
        </div>
      </div>
    </div>
  )
}
