import PurchaseLayout from 'components/Layout/Purchase'
import AssetMedia from 'components/AssetMedia'
import SalesHistory from 'components/SalesHistory'
import OffersHistory from 'components/OffersHistory'
import Warning from './Warning'
import Form from './Form'
import { getAvatarURL } from 'helpers/avatar'
import type { NfdRecord } from 'types/api'

type SellFormProps = {
  nfd: NfdRecord
  activeAddress: string | undefined
  prevPathname?: string
  reservedFor?: string
  sellAmount?: string
}

export default function SellForm({
  nfd,
  activeAddress,
  prevPathname,
  reservedFor,
  sellAmount
}: SellFormProps) {
  return (
    <PurchaseLayout nfd={nfd}>
      <div className="md:grid md:grid-cols-[200px_1fr] md:gap-x-8 xl:grid-cols-[240px_1fr]">
        <div className="hidden md:block">
          <div className="relative md:w-full aspect-square rounded-lg bg-gray-200 overflow-hidden dark:bg-gray-800">
            <AssetMedia
              src={getAvatarURL(nfd)}
              alt={nfd.name}
              className="object-cover w-full h-full"
              priority
              sizes="240px"
              fill
            />
          </div>

          {/* Warn user that selling clears all metadata, locks root if unlocked (md+ screens only) */}
          <Warning nfd={nfd} className="mt-4" />
        </div>

        <div>
          <Form
            nfd={nfd}
            activeAddress={activeAddress}
            prevPathname={prevPathname}
            reservedFor={reservedFor}
            sellAmount={sellAmount}
          />
          <section aria-labelledby="details-heading" className="mt-12">
            <h2 id="details-heading" className="sr-only">
              Additional details
            </h2>

            <div className="border-t space-y-6 pt-6 dark:border-gray-750/75">
              <SalesHistory name={nfd.name} />
              <OffersHistory name={nfd.name} />
            </div>
          </section>
        </div>
      </div>
    </PurchaseLayout>
  )
}
