import { useWallet } from '@txnlab/use-wallet-react'
import { useState } from 'react'
import { AiFillBank } from 'react-icons/ai'
import { BiCopy } from 'react-icons/bi'
import { HiLockClosed, HiLockOpen } from 'react-icons/hi'
import { HiArrowUpRight } from 'react-icons/hi2'
import Button from 'components/Button'
import Tooltip from 'components/ReactTooltip'
import SendModal from 'components/SendModal'
import IconBadge from './IconBadge'
import VaultLockToggle from './VaultLockToggle'
import { copyToClipboard } from 'helpers/copyToClipboard'
import { isVaultUnlocked } from 'helpers/vaults'
import { truncateAddress } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

interface VaultPanelProps {
  nfd: NfdRecord
  isOwner: boolean
}

export default function VaultPanel({ nfd, isOwner }: VaultPanelProps) {
  const [isSendModalOpen, setIsSendModalOpen] = useState(false)

  const { activeAddress } = useWallet()

  const vaultAddress = nfd.nfdAccount
  const isUnlocked = isVaultUnlocked(nfd)
  const addr = truncateAddress(vaultAddress, { array: true })

  const sender = isOwner ? nfd : activeAddress
  const receiver = isOwner ? undefined : nfd
  const receiverType = isOwner ? undefined : 'nfdVault'
  const label = isOwner ? 'Send from Vault' : 'Send'

  const renderLockBadge = () => {
    if (isOwner) {
      return (
        <div className="pl-1">
          <VaultLockToggle nfd={nfd} showLabel />
        </div>
      )
    }

    const badge = isUnlocked
      ? { icon: HiLockOpen, label: 'Unlocked' }
      : { icon: HiLockClosed, label: 'Locked' }

    return (
      <div className="flex items-center py-2 px-4">
        <IconBadge icon={badge.icon} label={badge.label} />
      </div>
    )
  }

  const renderSendModal = () => {
    if (!sender) return null

    const key = typeof sender === 'string' ? sender : sender.nfdAccount

    return (
      <SendModal
        key={key}
        label={label}
        isOpen={isSendModalOpen}
        setIsOpen={setIsSendModalOpen}
        sender={sender}
        receiver={receiver}
        receiverType={receiverType}
        showReceiver
      />
    )
  }

  return (
    <>
      <div className="2xl:sticky 2xl:top-[177px] 2xl:z-[19] bg-gray-100 dark:bg-gray-975">
        <div className="flex items-center justify-between px-4 py-4 sm:px-6 xl:py-5">
          <div className="flex-1 flex items-center space-x-3 xs:space-x-5 divide-x divide-gray-300 dark:divide-gray-750">
            <button
              type="button"
              className="group inline-flex items-center px-1 py-0.5 -mx-1 whitespace-nowrap rounded text-base text-gray-700 font-mono focus:outline-none focus:ring-2 focus:ring-brand-500 dark:text-gray-300"
              onClick={copyToClipboard}
              data-clipboard-text={vaultAddress}
              data-tooltip-id="copy-vault-address"
              data-tooltip-content="Copy Vault address"
            >
              <AiFillBank
                className="hidden flex-shrink-0 xs:inline-flex mr-2 h-6 w-6 text-gray-600 dark:text-gray-500"
                aria-hidden="true"
              />
              <span className="hidden xs:inline">
                {addr[0]}&hellip;{addr[1]}
              </span>
              <BiCopy
                className="inline-flex xs:hidden sm:inline-flex flex-shrink-0 xs:ml-3 h-6 w-6 opacity-50 text-gray-600 dark:text-gray-500 group-hover:opacity-100"
                aria-hidden="true"
              />
            </button>
            {renderLockBadge()}
          </div>

          {!!sender && (
            <>
              <Button
                variant="primary"
                size="sm"
                onClick={() => setIsSendModalOpen(true)}
                className="xl:hidden"
              >
                <span className="sm:hidden">Send {isOwner ? <></> : <>to Vault</>}</span>
                <span className="hidden sm:inline">
                  {isOwner ? 'Send from Vault' : 'Send to Vault'}
                </span>
              </Button>
              <Button
                variant="primary"
                onClick={() => setIsSendModalOpen(true)}
                className="hidden xl:inline-flex"
                icon={HiArrowUpRight}
              >
                {isOwner ? 'Send from Vault' : 'Send to Vault'}
              </Button>
            </>
          )}
        </div>
      </div>

      <Tooltip id="copy-vault-address" style={{ zIndex: 21 }} />
      <Tooltip id="vault-lock-toggle" offset={16} style={{ zIndex: 21 }} />

      {renderSendModal()}
    </>
  )
}
