import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import { captureException } from '@sentry/nextjs'
import { NfdRecordCollection, nfdSuggest, NfdSuggestParams } from 'src/api/api-client'
import type { ICustomError } from 'api/customError'

const getSuggestionsByName = async (
  name: string,
  params: NfdSuggestParams,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const { data } = await nfdSuggest(name, params, {
    ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
    ...options
  })

  return data
}

type UseSuggestArgs = {
  name: string
  params: NfdSuggestParams
  options?: UseQueryOptions<NfdRecordCollection, ICustomError>
}

export default function useSuggest({ name, params, options }: UseSuggestArgs) {
  const result = useQuery<NfdRecordCollection, ICustomError>(
    ['suggest', { name, ...params }],
    () => getSuggestionsByName(name, params),
    {
      ...options
    }
  )

  if (result.error) {
    captureException(result.error)
  }

  return result
}
