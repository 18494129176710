import confirm from 'components/confirm'
import { CLIENT_BASE_URL } from 'src/data/constants'
import { useStore } from 'store/state/store'

export default function useTermsOfService() {
  const hasAcceptedTerms = useStore((state) => state.hasAcceptedTerms)
  const setHasAcceptedTerms = useStore((state) => state.setHasAcceptedTerms)

  return async () => {
    const confirmed = hasAcceptedTerms
      ? true
      : await confirm({
          title: `Terms of service`,
          confirmText: `I agree`,
          confirmation: `We will only make you do this once! By purchasing an NFD you agree to the `,
          link: {
            href: `${CLIENT_BASE_URL}/terms`,
            label: `Terms of Service`,
            target: `_blank`
          }
        })

    if (!confirmed) {
      setHasAcceptedTerms(false)
      throw new Error('User did not accept terms')
    }

    setHasAcceptedTerms(true)
  }
}
