import Button from 'components/Button'
import ButtonLink from 'components/ButtonLink'
import DetailsSkeleton from '../DetailsSkeleton'
import useNotFound from './NotFound.hooks'
import { trimExtension } from 'helpers/utilities'

interface NotFoundProps {
  name: string
  activeAddress: string | undefined
}

export default function NotFound({ name, activeAddress }: NotFoundProps) {
  const { isAvailable, isCurated, isLoading } = useNotFound({ name, activeAddress })

  const renderMessage = () => {
    if (isAvailable) {
      return (
        <>
          The requested NFD does not exist&hellip; yet. Click the button below to mint{' '}
          <strong className="font-semibold dark:text-gray-400">{name}</strong>.
        </>
      )
    }

    if (isCurated) {
      return (
        <>
          The requested NFD <strong className="font-semibold">{name}</strong> does not exist&hellip;
          yet. It is a curated name that will be sold by auction at a later date. See{' '}
          <a
            href="https://docs.nf.domains/guides/auctions"
            className="font-medium text-gray-900 hover:text-brand-500 dark:text-brand-500 dark:hover:text-brand-600"
            target="_blank"
            rel="noreferrer"
          >
            documentation
          </a>{' '}
          for more info about auctions.
        </>
      )
    }

    return <>An unknown error occurred. Please contact support by clicking the button below.</>
  }

  if (isLoading) {
    return <DetailsSkeleton />
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-16 md:grid md:place-items-center">
      <div className="mx-auto max-w-5xl">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-brand-600 sm:text-5xl">Error</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6 dark:sm:border-gray-800">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl dark:text-gray-100">
                NFD not found
              </h1>
              <p className="mt-6 sm:mt-4 text-base text-gray-500">{renderMessage()}</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              {isAvailable && (
                <ButtonLink size="lg" variant="primary" href={`/mint?q=${trimExtension(name)}`}>
                  Mint this NFD
                </ButtonLink>
              )}
              <a href="mailto:feedback@txnlab.dev">
                <Button size="lg">Contact support</Button>
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
