import { useMemo } from 'react'
import useDonationList from 'api/hooks/useDonationList'
import useBadges from 'api/hooks/useBadges'
import { getAvatarURL } from 'helpers/avatar'
import type { DonationListItem } from 'components/analytics/Donations/Donations.hooks'

export type DonationBadge = DonationListItem & {
  total: number
}

export default function useBadgesComponent({ name }: { name: string }) {
  const donationListQuery = useDonationList({
    options: { refetchOnWindowFocus: false, staleTime: Infinity }
  })

  const donationList: DonationListItem[] | undefined = useMemo(() => {
    return donationListQuery.data?.map((nfd) => {
      return {
        name: nfd.name,
        fullName: nfd.properties?.userDefined?.name,
        image: getAvatarURL(nfd)
      }
    })
  }, [donationListQuery.data])

  const badgesQuery = useBadges({
    name
  })

  const donations = useMemo(() => {
    if (!badgesQuery.data || !donationList) {
      return []
    }

    return donationList
      .reduce((acc: DonationBadge[], item: DonationListItem) => {
        const { total } = badgesQuery.data?.donations?.find((d) => d.nfdName === item.name) || {}

        if (!total) {
          // total is undefined or zero; skip
          return acc
        }

        return [
          ...acc,
          {
            ...item,
            total: Number(total)
          }
        ]
      }, [] as DonationBadge[])
      .sort((a: DonationBadge, b: DonationBadge) => b.total - a.total)
  }, [badgesQuery.data, donationList])

  return {
    donations,
    isLoading: donationListQuery.isInitialLoading || badgesQuery.isInitialLoading,
    error: donationListQuery.error || badgesQuery.error
  }
}
