export default function DetailsSkeleton() {
  return (
    <div className="animate-pulse">
      <div>
        <div>
          <div className="aspect-[3/1] w-full bg-gray-300 xl:aspect-auto xl:h-[341px] dark:bg-gray-850" />
        </div>
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5 xl:-mt-20">
            <div className="flex">
              <div className="h-24 w-24 bg-gray-300 rounded-full ring-4 ring-white sm:h-32 sm:w-32 xl:h-40 xl:w-40 dark:bg-gray-850 dark:ring-gray-900" />
            </div>
            <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="sm:hidden mt-6 min-w-0 flex-1">
                <div className="h-4 bg-gray-300 rounded-full max-w-sm dark:bg-gray-850" />
              </div>
            </div>
          </div>
          <div className="hidden sm:block mt-12 min-w-0 flex-1">
            <div className="h-6 bg-gray-300 rounded-full max-w-xs dark:bg-gray-850" />
          </div>
        </div>
      </div>

      {/* <div className="mt-12 sm:mt-10">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-xl space-y-3">
            <div className="h-3 bg-gray-300 rounded-full"></div>
            <div className="grid grid-cols-3 gap-4">
              <div className="h-3 bg-gray-300 rounded-full col-span-2"></div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}
