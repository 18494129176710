import { Dialog, Transition } from '@headlessui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useHydrated } from 'react-hydration-provider'
import { HiCheck } from 'react-icons/hi2'
import Header from 'components/DetailView/Header'
import ScrollableTabs from 'components/ScrollableTabs'
import UserThumbnail from 'components/UserThumbnail'
import About from 'components/DetailView/About'
import Assets from 'components/DetailView/Assets'
import Segments from 'components/DetailView/Segments'
import MorePanel from 'components/DetailView/MorePanel'
import MoreTab from 'components/DetailView/MoreTab'
import { queryTypes, useQueryState } from 'hooks/useHydratedQueryState'
import usePush from 'hooks/usePush'
import { classNames } from 'helpers/utilities'
import { isTabEnabled } from 'components/DetailView/DetailView.utils'
import { moreTabViews } from 'components/DetailView/DetailView.constants'
import { DetailProps, Sections } from 'components/DetailView/DetailView.types'

export default function DetailView({ nfd, isOwner, showTip }: DetailProps) {
  const [activeTab, setActiveTab] = useQueryState<Sections>(
    'view',
    queryTypes.stringEnum(Object.values(Sections)).withDefault(Sections.About)
  )

  const [isSticky, setIsSticky] = useState(false)
  const navRef = useRef<HTMLDivElement>(null)
  const userThumbnailRef = useRef<HTMLDivElement>(null)

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const checkSticky = () => {
      if (navRef.current) {
        const rect = navRef.current.getBoundingClientRect()
        setIsSticky(rect.top <= 96)
      }
    }

    window.addEventListener('scroll', checkSticky)

    const closeMenuOnResize = () => {
      if (window.innerWidth >= 1024) {
        setIsMenuOpen(false)
      }
    }

    window.addEventListener('resize', closeMenuOnResize)

    return () => {
      window.removeEventListener('scroll', checkSticky)
      window.removeEventListener('resize', closeMenuOnResize)
    }
  }, [])

  const [isReady, setIsReady] = useState(false)
  const [thumbnailWidth, setThumbnailWidth] = useState(160)

  useEffect(() => {
    if (isReady) {
      if (userThumbnailRef.current) {
        const width = userThumbnailRef.current.offsetWidth
        setThumbnailWidth(width)
      } else {
        console.log('UserThumbnail ref is null')
      }
    }
  }, [isReady])

  const hydrated = useHydrated()
  const { query } = useRouter()
  const push = usePush()

  const shouldRedirect = useCallback(() => {
    if (!hydrated || !query.view) return false

    // Invalid view param
    if (!(Object.values(Sections) as string[]).includes(query.view as string)) {
      return true
    }

    if (!isTabEnabled(nfd, query.view as Sections)) {
      console.log('tab not enabled')
      return true
    }

    return false
  }, [hydrated, nfd, query.view])

  const isLegacyGalleryLink = useCallback(() => {
    if (!hydrated || !query.view) return false

    return query.view === 'gallery'
  }, [hydrated, query.view])

  useEffect(() => {
    if (isLegacyGalleryLink()) {
      push(`/name/${nfd.name}?view=assets${query.show ? `&show=${query.show}` : ''}`)
    } else if (shouldRedirect()) {
      push(`/name/${nfd.name}`)
    }
  }, [isLegacyGalleryLink, nfd.name, push, query.show, shouldRedirect])

  const handleClickThumbnail = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setActiveTab(Sections.About, {
      scroll: true,
      shallow: true
    })
  }

  const handleClickTab = (section: Sections) => {
    setActiveTab(section, {
      scroll: false,
      shallow: true
    })
  }

  const handleMoreTabMobileClick = (section: Sections) => {
    setActiveTab(section, {
      scroll: false,
      shallow: true
    })
    setIsMenuOpen(false)
  }

  const renderTab = (section: Sections, isActive: boolean) => {
    return (
      <div key={section} className="flex pl-8 first:pl-0 last:pr-4 last:sm:pr-6 last:lg:pr-8">
        <Link
          href={`/name/${nfd.name}?view=${section}`}
          className={classNames(
            isActive
              ? 'border-brand-500 text-gray-900 dark:text-gray-100'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-500 dark:hover:text-gray-300 dark:hover:border-gray-700',
            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm capitalize outline-brand-500'
          )}
          aria-current={isActive ? 'page' : undefined}
          scroll={false}
        >
          {section.toLowerCase()}
        </Link>
      </div>
    )
  }

  const renderTabs = () => {
    let moreTabRendered = false

    return Object.values(Sections)
      .map((tab) => {
        if (!isTabEnabled(nfd, tab)) {
          return null
        }

        if (moreTabViews.includes(tab)) {
          if (!moreTabRendered) {
            moreTabRendered = true
            return (
              <MoreTab
                key="more"
                isActive={moreTabViews.includes(activeTab)}
                activeTab={activeTab}
                onTabClick={handleClickTab}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
              />
            )
          } else {
            return null // Skip rendering individual Sales, Offers, and History tabs
          }
        } else {
          const isActive = tab === activeTab
          return renderTab(tab, isActive)
        }
      })
      .filter(Boolean) // Remove null items from the result
  }

  const renderPanel = () => {
    if (!isTabEnabled(nfd, activeTab)) {
      return <About nfd={nfd} isOwner={isOwner} />
    }

    switch (activeTab) {
      case Sections.About:
        return <About nfd={nfd} isOwner={isOwner} />
      case Sections.Assets:
        return <Assets nfd={nfd} isOwner={isOwner} />
      case Sections.Segments:
        return <Segments nfd={nfd} isOwner={isOwner} />
      case Sections.Sales:
      case Sections.Offers:
      case Sections.History:
        return <MorePanel nfd={nfd} activeTab={activeTab} />
      default:
        return null
    }
  }

  const renderSections = () => {
    if (!hydrated) {
      return null
    }

    if (nfd.state === 'available') {
      return null
    }

    return (
      <div className="flex flex-col min-h-[calc(100vh-3.875rem-2px)]">
        <div ref={navRef} className="sticky top-16 pt-[1px] z-[21] bg-white dark:bg-gray-900">
          <div className="border-b border-gray-200 dark:border-gray-800">
            <ScrollableTabs>
              <div
                className={classNames(
                  isSticky ? 'max-w-full' : 'max-w-5xl',
                  'mx-auto px-4 sm:px-6 lg:px-8 transition-[max-width] duration-500 4xl:duration-750'
                )}
              >
                <div className="flex items-center">
                  <button
                    type="button"
                    className={classNames(
                      'hidden md:flex transform transition-all duration-500 4xl:duration-750',
                      isSticky ? 'opacity-1' : 'opacity-0 pointer-events-none'
                    )}
                    style={{ width: isSticky ? (thumbnailWidth !== 0 ? thumbnailWidth : 160) : 0 }}
                    onClick={handleClickThumbnail}
                  >
                    <UserThumbnail nfd={nfd} link={false} />
                  </button>
                  <div
                    aria-hidden="true"
                    className={classNames(
                      'hidden transform transition-all duration-500 4xl:duration-750 w-px h-5 bg-gray-300 md:block dark:bg-gray-700',
                      isSticky ? 'opacity-100 mx-6' : 'opacity-0 mx-0 pointer-events-none'
                    )}
                  />
                  <nav className="flex" aria-label="Tabs">
                    {renderTabs()}
                  </nav>
                </div>
              </div>
            </ScrollableTabs>
          </div>
        </div>
        <div className="flex-1 flex flex-col">{renderPanel()}</div>

        {/* hidden, used to calculate width of UserThumbnail */}
        <UserThumbnail
          forwardedRef={userThumbnailRef}
          nfd={nfd}
          wrapperClassName="hidden fixed -translate-x-96 opacity-0 pointer-events-none md:block"
          onMount={() => setIsReady(true)}
        />
      </div>
    )
  }

  return (
    <>
      <div className="flex-1 flex flex-col">
        <Header nfd={nfd} isOwner={isOwner} showTip={showTip} />
        {renderSections()}
      </div>

      <Transition.Root show={isMenuOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[60]" onClose={setIsMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity dark:bg-black/75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white py-3 px-4 text-left shadow-xl transition-all flex-1 max-w-[16rem] dark:bg-gray-900">
                  {moreTabViews.map((section) => (
                    <button
                      key={section}
                      type="button"
                      className={classNames(
                        'relative block w-full pl-4 pr-9 py-2 text-left text-base capitalize rounded',
                        activeTab === section
                          ? 'font-semibold text-gray-900 bg-black/5 dark:bg-white/5 dark:text-gray-200'
                          : 'font-normal text-gray-700 dark:text-gray-400'
                      )}
                      onClick={() => handleMoreTabMobileClick(section)}
                    >
                      {section}

                      {activeTab === section && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                          <HiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </button>
                  ))}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
