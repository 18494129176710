import { Listbox, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { AiFillBank } from 'react-icons/ai'
import { BiWallet } from 'react-icons/bi'
import { HiCheck, HiChevronUpDown } from 'react-icons/hi2'
import { isVaultsSupported } from 'helpers/versions'
import { truncateAddress } from 'helpers/truncateAddress'
import { classNames } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

export type AccountOption = {
  id: number
  account: string
}

interface AccountFilterProps {
  nfd: NfdRecord
  accountOptions: Array<AccountOption>
  selectedAccounts: Array<AccountOption>
  setSelectedAccounts: (selected: Array<AccountOption>) => void
  setAccounts: (selected: string[] | null) => void
  className?: string
  labelClassName?: string
}

export default function AccountFilter({
  nfd,
  accountOptions,
  selectedAccounts,
  setSelectedAccounts,
  setAccounts,
  className = '',
  labelClassName = ''
}: AccountFilterProps) {
  const vaultAccount = nfd.nfdAccount

  const renderSelected = () => {
    if (!selectedAccounts?.length) {
      return <span className="block truncate">All Accounts</span>
    }

    if (selectedAccounts.length === 1) {
      return (
        <>
          <span className="mr-2 rounded bg-brand-500 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-white">
            {selectedAccounts.length}
          </span>{' '}
          <span className="xs:hidden font-mono font-medium truncate">
            {selectedAccounts[0].account}
          </span>
          <span className="hidden xs:inline font-mono font-medium">
            {truncateAddress(selectedAccounts[0].account, { jsx: true })}
          </span>
        </>
      )
    }

    if (selectedAccounts.length > 1) {
      return (
        <>
          <span className="mr-2 rounded bg-brand-500 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-white">
            {selectedAccounts.length}
          </span>{' '}
          Accounts
        </>
      )
    }
  }

  return (
    <Listbox value={selectedAccounts} onChange={setSelectedAccounts} multiple>
      {({ open }) => (
        <div className={classNames('flex items-center', className)}>
          <Listbox.Label
            className={classNames(
              'text-sm font-medium text-gray-700 dark:text-gray-300',
              labelClassName
            )}
          >
            Accounts
          </Listbox.Label>
          <div className="relative w-full">
            <Listbox.Button className="relative flex items-center w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-500 sm:text-sm sm:leading-6 dark:bg-white/5 dark:text-white dark:ring-white/10 dark:focus:ring-brand-500">
              {renderSelected()}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <HiChevronUpDown
                  className="h-5 w-5 text-gray-400 dark:text-gray-600"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                as="div"
                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white pt-14 pb-1 text-base shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-850 dark:ring-white dark:ring-opacity-5"
              >
                <div className="absolute inset-x-0 top-0 z-[11] bg-white py-2 px-3 dark:bg-gray-850">
                  <span className="isolate flex rounded-md shadow-sm">
                    {vaultAccount && isVaultsSupported(nfd) && (
                      <button
                        type="button"
                        className="relative w-1/2 -ml-px inline-flex items-center justify-center rounded-l-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 disabled:opacity-50 disabled:hover:bg-white dark:bg-white/10 dark:text-white dark:ring-gray-850 dark:hover:bg-white/20 dark:disabled:opacity-25 dark:disabled:hover:bg-white/10"
                        onClick={() => setAccounts([vaultAccount])}
                        aria-hidden="true"
                      >
                        Vault
                      </button>
                    )}

                    <button
                      type="button"
                      className="relative w-1/2 inline-flex items-center justify-center rounded-r-md bg-white px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 disabled:opacity-50 disabled:hover:bg-white dark:bg-white/10 dark:text-white dark:ring-gray-850 dark:hover:bg-white/20 dark:disabled:opacity-25 dark:disabled:hover:bg-white/10"
                      onClick={() => setAccounts(null)}
                      aria-hidden="true"
                      disabled={!selectedAccounts?.length}
                    >
                      All
                    </button>
                  </span>
                </div>

                {accountOptions.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    as="div"
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-brand-500 text-white' : 'text-gray-900 dark:text-gray-400',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center space-x-2">
                          {option.account === vaultAccount ? (
                            <AiFillBank
                              className={classNames(
                                selected ? 'opacity-90' : 'opacity-50',
                                'h-4 w-4'
                              )}
                              aria-hidden="true"
                            />
                          ) : (
                            <BiWallet
                              className={classNames(
                                selected ? 'opacity-90' : 'opacity-50',
                                'h-4 w-4'
                              )}
                              aria-hidden="true"
                            />
                          )}
                          <span
                            className={classNames(
                              selected ? 'font-semibold dark:text-gray-100' : 'font-normal',
                              'block truncate font-mono'
                            )}
                          >
                            {truncateAddress(option.account, { jsx: true })}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-brand-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <HiCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}
