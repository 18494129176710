import { NfdRecord } from 'api/api-client'
import { MoreTabViews, Sections } from './DetailView.types'
import History from './History'
import Offers from './Offers'
import Sales from './Sales'

interface MorePanelProps {
  nfd: NfdRecord
  activeTab: MoreTabViews
}

export default function MorePanel({ nfd, activeTab }: MorePanelProps) {
  switch (activeTab) {
    case Sections.Sales:
      return <Sales nfd={nfd} />
    case Sections.Offers:
      return <Offers nfd={nfd} />
    case Sections.History:
      return <History nfd={nfd} />
    default:
      return null
  }
}
