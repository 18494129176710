import { Disclosure } from '@headlessui/react'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useRouter } from 'next/router'
import * as React from 'react'
import { isMobile } from 'react-device-detect'
import { AiFillBank } from 'react-icons/ai'
import { HiBan, HiChevronUp, HiLink } from 'react-icons/hi'
import { RiTwitterFill } from 'react-icons/ri'
import { NfdRecord } from '@/api/api-client'
import { usePostCancelSale } from '@/api/hooks/usePostCancelSale'
import { usePostSale } from '@/api/hooks/usePostSale'
import useVaultAssets from '@/api/hooks/useVaultAssets'
import Alert from '@/components/Alert'
import AlgoPrice from '@/components/AlgoPrice'
import AlgoSymbol from '@/components/AlgoSymbol'
import AssetMedia from '@/components/AssetMedia'
import Button from '@/components/Button'
import Callout from '@/components/Callout'
import confirm from '@/components/confirm'
import AssetDetails from '@/components/DetailView/Assets/AssetPreview/AssetDetails'
import TokenImage from '@/components/DetailView/Assets/AssetPreview/TokenImage'
import LoadingDots from '@/components/LoadingDots'
import LookupInput from '@/components/LookupInput'
import PriceTag from '@/components/PriceTag'
import TxnSuccess from '@/components/toasts/TxnSuccess'
import UsdPrice from '@/components/UsdPrice'
import UserThumbnail from '@/components/UserThumbnail'
import Tooltip from 'components/Tooltip'
import { ResultTag } from 'components/ResultTag'
import { copyToClipboard } from '@/helpers/copyToClipboard'
import { formatAmount } from 'helpers/format'
import galleryImageLoader from '@/helpers/galleryImageLoader'
import {
  clsxMerge,
  convertAlgosToMicroalgos,
  convertMicroalgosToAlgos,
  getNfdUrl,
  getTwitterShareLink,
  isAssetNft
} from '@/helpers/utilities'
import { getNfdVersion, meetsMinimumVersion } from 'helpers/versions'
import useErrorToast from '@/hooks/useErrorToast'
import { Asset } from '@/types/assets'
import SuccessToast from './SuccessToast'
import ConfirmDialog from './ConfirmDialog'
import Warning from './Warning'
import { useNfdPolling } from 'hooks/useNfdPolling'
import usePush from 'hooks/usePush'
import { useNocacheStore } from 'store/index'
import toast from 'react-hot-toast'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

interface FormProps {
  nfd: NfdRecord
  activeAddress: string | undefined
  prevPathname?: string
  reservedFor?: string
  sellAmount?: string
}

export default function Form({
  nfd,
  activeAddress,
  prevPathname,
  reservedFor,
  sellAmount
}: FormProps) {
  const [price, setPrice] = React.useState<string>(
    sellAmount
      ? convertMicroalgosToAlgos(Number(sellAmount)).toString()
      : nfd.sellAmount
      ? convertMicroalgosToAlgos(nfd.sellAmount).toString()
      : ''
  )
  const [reservedAddress, setReservedAddress] = React.useState<string>(
    reservedFor || nfd.reservedFor || ''
  )

  const isExpired = dayjs(nfd.timeExpires).isBefore(dayjs())
  const expiresSoon = dayjs(nfd.timeExpires).isBefore(dayjs().add(30, 'days'))

  const handleError = useErrorToast()
  const router = useRouter()
  const queryClient = useQueryClient()
  const push = usePush()
  const toastId = React.useId()

  const addNameNocache = useNocacheStore((state) => state.addName)

  const {
    startPolling: startPollingNfdListed,
    status: nfdListedStatus,
    error: nfdListedError
  } = useNfdPolling(nfd.name, (nfd) => nfd.state === 'forSale', 'nfdListed')

  const listForSale = usePostSale({
    onSuccess: (data, params) => {
      console.log('listing for sale success', data)
      queryClient.invalidateQueries({ queryKey: ['name', params.name] })
      toast.loading('Confirming listing status...', { id: toastId })
      startPollingNfdListed()
    },
    onError: (error) => {
      handleError(error, { toastId })
    },
    toasts: {
      success: SuccessToast
    }
  })

  const handleListForSale = () => {
    try {
      if (!activeAddress) {
        throw new Error('No active address')
      }

      const salePrice = convertAlgosToMicroalgos(parseFloat(price))

      console.log('listing for sale', salePrice)

      listForSale.mutate({
        name: nfd.name,
        body: {
          offer: salePrice,
          sender: activeAddress,
          reservedFor: reservedAddress || undefined
        }
      })
    } catch (error) {
      handleError(error, { toastId })
    }
  }

  const {
    startPolling: startPollingNfdUnlisted,
    status: nfdUnlistedStatus,
    error: nfdUnlistedError
  } = useNfdPolling(nfd.name, (nfd) => nfd.state === 'owned', 'nfdUnlisted')

  const cancelSale = usePostCancelSale({
    onSuccess: (data, params) => {
      console.log('cancelling sale success', data)
      queryClient.invalidateQueries({ queryKey: ['name', params.name] })
      startPollingNfdUnlisted()
    },
    onError: (error) => {
      handleError(error, { toastId })
    },
    toasts: {
      success: TxnSuccess
    }
  })

  const handleCancelSale = () => {
    try {
      if (!activeAddress) {
        throw new Error('No active address')
      }

      console.log('cancelling sale')

      cancelSale.mutate({
        name: nfd.name,
        body: {
          sender: activeAddress
        }
      })
    } catch (error) {
      handleError(error, { toastId })
    }
  }

  React.useEffect(() => {
    const handleNfdListed = (event: CustomEvent) => {
      if (event.detail.name === nfd.name) {
        addNameNocache(nfd.name)
        push(`/name/${nfd.name}`, undefined, { shallow: true })
        toast.dismiss(toastId)
      }
    }
    window.addEventListener('nfdListed', handleNfdListed as EventListener)

    return () => {
      window.removeEventListener('nfdListed', handleNfdListed as EventListener)
    }
  }, [addNameNocache, nfd.name, push, toastId])

  React.useEffect(() => {
    if (nfdListedStatus === 'error' && nfdListedError) {
      handleError(nfdListedError, { toastId })
    }
  }, [handleError, nfdListedError, nfdListedStatus, toastId])

  React.useEffect(() => {
    if (nfdUnlistedStatus === 'error' && nfdUnlistedError) {
      handleError(nfdUnlistedError, { toastId })
    }
  }, [handleError, nfdUnlistedError, nfdUnlistedStatus, toastId])

  const handleGoBack = (path: string | undefined) => {
    router.push(path || `/name/${nfd.name}`)
  }

  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    // matches positive integers only
    const regExp = /^[1-9][0-9]*$/gm

    if (value !== '' && value.match(regExp) === null) {
      return
    }

    setPrice(value)
  }

  const handleChangeReserveAddress = (value: string) => {
    setReservedAddress(value)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const confirmed = await confirm({
      title: `Metadata warning`,
      confirmText: `Yes, list for sale`,
      confirmation: ConfirmDialog()
    })

    confirmed && (await handleListForSale())
  }

  const isForSale = nfd.state === 'forSale' && nfd.owner === activeAddress

  const isValid = React.useMemo(() => {
    return (
      parseFloat(price) >= 1 &&
      parseFloat(price) <= 100000000 &&
      (reservedAddress?.match(/^[A-Z2-7]{58}$/)?.length || reservedAddress === '')
    )
  }, [price, reservedAddress])

  const isDirty = React.useMemo(() => {
    const originalPrice = nfd.sellAmount ? convertMicroalgosToAlgos(nfd.sellAmount).toString() : ''
    const originalAddress = nfd.reservedFor || ''

    return price !== originalPrice || reservedAddress !== originalAddress
  }, [nfd.reservedFor, nfd.sellAmount, price, reservedAddress])

  const {
    showVaultInfo,
    assets,
    isLoading: isVaultAssetsLoading,
    error: vaultAssetsError
  } = useVaultAssets(nfd)

  const [isDetailsOpen, setIsDetailsOpen] = React.useState<Record<number, boolean> | null>(null)

  const handleSetIsDetailsOpen = React.useCallback(
    (assetId: number, isOpen: boolean) => {
      setIsDetailsOpen(
        assets
          ? assets.reduce((acc, asset) => {
              if (asset.id === assetId) {
                acc[asset.id] = isOpen
              } else {
                acc[asset.id] = false
              }
              return acc
            }, {} as Record<number, boolean>)
          : null
      )
    },
    [assets]
  )

  React.useEffect(() => {
    if (assets) {
      setIsDetailsOpen(
        assets.reduce((acc, asset) => {
          acc[asset.id] = false
          return acc
        }, {} as Record<number, boolean>)
      )
    }
  }, [assets])

  const vaultAddress = nfd.nfdAccount

  const getVaultAmount = (asset: Asset) => {
    const vaultAmount = asset.amounts.find((amount) => amount.account === vaultAddress)?.amount

    if (!vaultAmount) return null

    return formatAmount(vaultAmount, { decimals: asset.decimals, maxLength: 10 })
  }

  const renderPreview = (asset: Asset) => {
    if (!isAssetNft(asset.totalCreated, asset.decimals) && !asset.imageUrl) {
      return <TokenImage />
    }

    return (
      <AssetMedia
        src={asset.imageUrl}
        alt={asset.id.toString()}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="32px"
        fill
        options={{ showVideoIcon: false, stateBgColor: 'bg-gray-500/5 dark:bg-gray-500/5' }}
        videoJsOptions={{
          preload: isMobile ? 'auto' : 'metadata',
          controls: isMobile,
          fluid: true,
          fill: true
        }}
      />
    )
  }

  const renderAssetDetails = (asset: Asset) => {
    const isNft = isAssetNft(asset.totalCreated, asset.decimals)
    const isNfd = asset.unitName?.toLowerCase() === 'nfd'
    const totalAmount = asset.amounts.find((amount) => amount.account === vaultAddress)?.amount || 0

    return (
      <AssetDetails
        open={isDetailsOpen?.[asset.id] || false}
        setOpen={(open) => handleSetIsDetailsOpen(asset.id, open)}
        nfd={nfd}
        asset={asset}
        isNft={isNft}
        isNfd={isNfd}
        isAccountsOpen={false}
        totalAmount={totalAmount}
        handleClickSetField={() => null}
        handleClickSendAsset={() => null}
        handleClickMoveToVault={() => null}
        handleClickMoveToDepositAccount={() => null}
        handleClickMoveToOwnerAccount={() => null}
        handleClickFilterAccount={() => null}
        canSetField={false}
        canSendAsset={false}
        canMoveToVault={false}
        canMoveToDepositAccount={false}
        canMoveToOwnerAccount={false}
      />
    )
  }

  const renderVaultAssets = () => {
    const showVaultAssets = showVaultInfo && !!assets && assets.length > 0

    if (!showVaultAssets) return null

    if (isVaultAssetsLoading) {
      return (
        <div className="flex items-center justify-center h-[72px]">
          <LoadingDots />
        </div>
      )
    }

    if (vaultAssetsError) {
      return (
        <div>
          <Alert type="error" title={`Error fetching vault assets`} error={vaultAssetsError} />
        </div>
      )
    }

    return (
      <Callout theme="blue">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg px-1 py-2 text-left font-medium">
                <span className="inline-flex items-center">
                  <AiFillBank className="h-5 w-5 mr-2" aria-hidden="true" /> Vault Assets (
                  {assets.length})
                </span>
                <HiChevronUp
                  className={clsxMerge(open ? 'transform rotate-180' : '', 'h-6 w-6')}
                  aria-hidden="true"
                />
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="mt-6 space-y-2.5 max-h-48 overflow-y-auto pr-6">
                  {assets.map((asset) => (
                    <div key={asset.id}>
                      <button
                        type="button"
                        className="group flex items-center w-full"
                        onClick={() => handleSetIsDetailsOpen(asset.id, true)}
                      >
                        <div className="relative flex-shrink-0 w-8 h-8 rounded-full overflow-hidden bg-gray-500/10">
                          {renderPreview(asset)}
                        </div>
                        <div className="ml-3 flex-1 flex items-center justify-between min-w-0 text-sm font-medium text-gray-900 dark:text-gray-200">
                          <p className="truncate font-medium group-hover:text-brand-500">
                            {asset.name}
                          </p>
                          <span className="ml-4 font-mono">{getVaultAmount(asset)}</span>
                        </div>
                      </button>
                      {renderAssetDetails(asset)}
                    </div>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Callout>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h1 className="text-2xl sm:text-3xl font-extrabold tracking-tight text-gray-900 truncate dark:text-gray-100">
          {nfd.name}
        </h1>

        <div className="mt-2 flex items-center gap-x-2">
          {meetsMinimumVersion(nfd, '3') ? (
            <Tooltip
              as="span"
              text={dayjs(nfd.timeExpires).format('lll')}
              className="translate-y-3"
            >
              <p className="text-sm text-gray-700 dark:text-gray-400">
                <ResultTag
                  label={isExpired ? 'Expired' : `Expires ${dayjs(nfd.timeExpires).fromNow()}`}
                  color={expiresSoon ? 'red' : 'gray'}
                  className={clsxMerge(expiresSoon ? 'font-bold' : '')}
                />
              </p>
            </Tooltip>
          ) : (
            <ResultTag label={`v${getNfdVersion(nfd)}`} color="gray" className="mt-1.5" />
          )}
        </div>

        <div className="mt-2 sm:mt-3 space-y-8 sm:space-y-6">
          {isForSale && (
            <div>
              <h2 className="sr-only">Price</h2>
              <div className="flex items-center">
                <PriceTag
                  price={convertAlgosToMicroalgos(Number(price))}
                  className="pl-3 pr-3 text-xl"
                />
                <UsdPrice
                  price={convertAlgosToMicroalgos(Number(price))}
                  className="text-right ml-2"
                />
              </div>
            </div>
          )}

          {nfd.seller && (
            <div>
              <h3 className="text-sm text-gray-500 mb-1">Seller</h3>
              <div className="flex">
                <UserThumbnail
                  address={nfd.seller}
                  className="inline-flex min-w-0 pr-2"
                  fallbackClassName="font-medium bg-orange-50 text-orange-700 px-2 py-0.5 rounded"
                />
              </div>
            </div>
          )}

          {nfd.reservedFor && (
            <div>
              <h3 className="text-sm text-gray-500 mb-1">Reserved for</h3>
              <div className="flex">
                <UserThumbnail address={nfd.reservedFor} className="inline-flex min-w-0 pr-2" />
              </div>
            </div>
          )}

          {renderVaultAssets()}

          <div className="border-t border-gray-200 pt-6 dark:border-gray-750/75">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
              {isForSale ? 'Manage Sale' : 'Sell NFD'}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {isForSale
                ? 'Update price and/or reserve address, or cancel the sale'
                : 'Include a reserve address to sell/transfer to a specific account'}
            </p>
          </div>

          {/* Warn user that selling clears all metadata, locks root if unlocked (small screens only) */}
          <Warning nfd={nfd} className="md:hidden" />

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
              Sale price (required)
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3.5 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 dark:border-none dark:bg-gray-750 dark:text-gray-400 dark:highlight">
                <AlgoSymbol className="mt-0.5" />
              </span>
              <input
                type="text"
                value={price}
                onChange={handleChangePrice}
                name="nfd-sale-price"
                id="nfd-sale-price"
                className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-brand-500 focus:border-brand-500 sm:text-sm border-gray-300 dark:bg-gray-750/60 dark:border-transparent dark:text-gray-100 dark:focus:border-brand-500 dark:placeholder-gray-500 dark:caret-gray-400"
                autoComplete="false"
                aria-describedby="sale-price-description"
              />
            </div>
            <p className="mt-2 text-sm text-gray-500" id="sale-price-description">
              A minimum sale price of <AlgoPrice price={1000000} /> is required
            </p>
          </div>

          <div>
            <label
              htmlFor="nfd-sale-reserved-for"
              className="block text-sm font-medium text-gray-700 dark:text-gray-400"
            >
              Reserve for (optional)
            </label>
            <div className="mt-1">
              <LookupInput
                value={reservedAddress}
                onChange={handleChangeReserveAddress}
                exclude={activeAddress}
              />
            </div>
            <p className="mt-2 text-sm text-gray-500" id="sale-reserved-description">
              Sell this NFD to a specific account
            </p>
          </div>
        </div>

        {isForSale ? (
          <div className="mt-8 sm:mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <Button
              type="submit"
              disabled={
                !activeAddress ||
                !isDirty ||
                !isValid ||
                listForSale.isLoading ||
                cancelSale.isLoading
              }
              variant="gradient"
              className="w-full py-3 px-8 sm:col-start-2"
              size="lg"
            >
              Update listing
            </Button>
            <Button
              onClick={handleCancelSale}
              disabled={!activeAddress || listForSale.isLoading || cancelSale.isLoading}
              className="mt-3 w-full py-3 px-8 sm:mt-0 sm:col-start-1 focus:ring-red-500"
              size="lg"
            >
              <HiBan className="-ml-1 mr-3 h-5 w-5 text-red-500" aria-hidden="true" />
              Cancel sale
            </Button>
          </div>
        ) : (
          <div className="mt-8 sm:mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <Button
              type="submit"
              disabled={!activeAddress || !isDirty || !isValid || listForSale.isLoading}
              variant="gradient"
              className="w-full py-3 px-8 sm:col-start-2"
              size="lg"
            >
              List for sale
            </Button>
            <Button
              onClick={() => handleGoBack(prevPathname)}
              disabled={listForSale.isLoading}
              className="mt-3 w-full py-3 px-8 sm:mt-0 sm:col-start-1"
              size="lg"
            >
              Go back
            </Button>
          </div>
        )}
      </form>

      {isForSale && (
        <section aria-labelledby="details-heading" className="mt-12">
          <h2 id="details-heading" className="sr-only">
            Additional details
          </h2>

          <div className="border-t divide-y divide-gray-200 dark:border-gray-750/75 dark:divide-gray-750/75">
            <div className="py-6">
              <div className="flex items-center space-x-3">
                <Button
                  className="group"
                  data-clipboard-text={getNfdUrl(nfd.name)}
                  onClick={copyToClipboard}
                >
                  <HiLink
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Copy link
                </Button>
                <a
                  className="inline-flex items-center justify-center shadow-sm font-medium px-4 py-2 text-sm rounded-md border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  href={getTwitterShareLink(nfd.name, true)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiTwitterFill
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Share to Twitter
                </a>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
